import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { Button } from 'react-bootstrap';
import Notifier from 'components/Message/Notifier';
import validator from 'utils/validator';
import importIcon from 'assets/images/import.png';

const UploadBtn = ({ t, accept, multiple, onDrop, isUpload }) => {
  const handleOnDrop = useCallback(
    (files) => {
      if (!validator.isValidFile(accept, files[0])) {
        Notifier.error(t('_unsupported_extension_'));
      } else if (!validator.isValidFileSize(files[0])) {
        Notifier.error(t('_unsupported_file_size_'));
      } else {
        onDrop(files);
      }
    },
    [accept, onDrop, t],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleOnDrop });
  return (
    <div {...getRootProps()} className="mr-2">
      <input {...getInputProps()} multiple={multiple} />
      {isUpload ? (
        <Button variant="light" block type="button">
          {t('_upload_')}
        </Button>
      ) : (
        <Button variant="secondary-outline" block type="button" className="nav-icons">
          <img alt="" src={importIcon} />
          {t('_import_')}
        </Button>
      )}
    </div>
  );
};

UploadBtn.defaultProps = {
  multiple: false,
  isUpload: true,
};

UploadBtn.propTypes = {
  t: PropTypes.func.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  isUpload: PropTypes.bool,
};

export default UploadBtn;
