import React from 'react';
import PropTypes from 'prop-types';

const TitleComponent = ({ prefix, suffix }) => (
  <>
    <span className="text-uppercase">«{prefix}» </span>
    {suffix}
  </>
);
TitleComponent.defaultProps = {
  prefix: '',
  suffix: <div />,
};
TitleComponent.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.element,
};

export default TitleComponent;
