import security from 'common/security/security';
import { ACCOUNT_OPEN_SESSIONS } from 'components/Account/accountActionsTypes';
import { LOGGED_IN } from './loginActionTypes';

export const initialState = { ...security.getUser(), activeSessions: 0 };

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...state,
        ...action.user,
      };
    case ACCOUNT_OPEN_SESSIONS:
      return {
        ...state,
        activeSessions: action.activeSessions,
      };
    default:
      return state;
  }
}
