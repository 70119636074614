import React from 'react';
import PropTypes from 'prop-types';

const COLORS = {
  0: '#E30613',
  1: '#FFED00',
  2: '#3AAA35',
  '-1': 'transparent',
};

const AssesmentRow = ({ data, length }) => (
  <div className="time-line-view-body-row-content-row">
    {data.map((item) => (
      <div
        style={{
          width: `${(((item.end - item.start + 1) / length) * 100).toFixed(2)}%`,
          backgroundColor: COLORS[item.value],
          position: 'relative',
        }}
        className="filled"
        key={`time-line-view-body-row-content-number-assisment-${item.start}-${item.end}`}
      >
        {item.value !== -1 && <div className="time-line-view-body-row-content-row-triangle" />}
      </div>
    ))}
  </div>
);

AssesmentRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  length: PropTypes.number.isRequired,
};
export default AssesmentRow;
