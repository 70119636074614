import React from 'react';
import PropTypes from 'prop-types';
import Position from 'components/TimeLine/Position';
import Energy from 'components/TimeLine/Energy';
import Defects from 'components/TimeLine/Defects';
import Lift from 'components/TimeLine/Lift';

const TimeLineTableSystem = ({ t, data, formattedData }) => (
  <>
    <Position t={t} data={data} formattedData={formattedData} />
    <Energy t={t} data={data} formattedData={formattedData} />
    <Lift t={t} data={data} formattedData={formattedData} />
    <Defects t={t} data={data} formattedData={formattedData} />
  </>
);

TimeLineTableSystem.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  formattedData: PropTypes.shape({}).isRequired,
};
export default TimeLineTableSystem;
