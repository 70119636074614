import { STATUS } from 'config/constants';
import React, { useMemo } from 'react';
import { Jumbotron } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const totalsWereChanged = (current, previous) =>
  !current.find(
    ({ clientId, total, gameState }) =>
      !previous.find(
        (team) =>
          team.clientId === clientId && team.total === total && team.gameState === gameState,
      ),
  );
export const nexusWasChanged = (current, previous) => current === previous;

const Totals = () => {
  const teams = useSelector(
    ({ teams }) => teams.map(({ clientId, total, gameState }) => ({ clientId, total, gameState })),
    totalsWereChanged,
  );
  const nexus = useSelector(({ nexus }) => nexus && nexus.total, nexusWasChanged);
  return useMemo(
    () => (
      <>
        {teams.map((team) => (
          <Jumbotron
            className="observe-simulation-total justify-content-center align-items-center"
            key={team.clientId}
          >
            {team.hasOwnProperty('total') &&
            (team.gameState !== STATUS.ABORT || team.gameState !== null) &&
            'undefined' !== typeof team.total
              ? team.total
              : '-'}
          </Jumbotron>
        ))}
        {typeof nexus === 'number' && (
          <Jumbotron className="observe-simulation-total justify-content-center align-items-center">
            {nexus}
          </Jumbotron>
        )}
        {/* <Jumbotron
        className="observe-simulation-total justify-content-center align-items-center"
      >0%</Jumbotron> */}
      </>
    ),
    [nexus, teams],
  );
};
export default Totals;
