import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SortableTable = ({ columns, data, additionalClass, rowClickHandler, excludeHeader }) => {
  const { t } = useTranslation();
  const [scrollClassName, setScrollClassName] = useState('');
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );
  const tbodyRef = useRef();
  const trRef = useRef();
  const getUserAgent = () =>
    navigator.userAgent.match('iPad') ||
    navigator.userAgent.match('Android') ||
    navigator.userAgent.match('iPhone')
      ? 'tablet'
      : '';

  const calculateSize = useCallback(() => {
    let scrollClassName = '';
    if (tbodyRef.current && trRef.current) {
      if (data.length * trRef.current.clientHeight > tbodyRef.current.clientHeight) {
        scrollClassName = 'with-scroll';
      }
      setScrollClassName(scrollClassName);
    }
  }, [tbodyRef, trRef, data]);
  useEffect(() => {
    calculateSize();
    window.addEventListener('resize', calculateSize);
    return () => {
      window.removeEventListener('resize', calculateSize);
    };
  }, [calculateSize]);
  return (
    <Table
      bordered
      cellSpacing="0"
      width="100%"
      className={`sortable-table ${additionalClass} ${scrollClassName} ${getUserAgent()}`}
    >
      <thead className={`${excludeHeader ? 'exclude-header' : ''}`}>
        <tr>
          {headerGroups[0].headers.map((header) => (
            <th
              key={`thead-header-${header.id}`}
              className={`cell-size-${header.size} ${header.center ? 'centered' : ''}`}
              {...(header.sortable ? header.getHeaderProps(header.getSortByToggleProps()) : {})}
              title={t(header.render('Header'))}
            >
              {header.render('Header')}
              {header.isSorted ? (
                <>
                  {' '}
                  <FontAwesomeIcon icon={header.isSortedDesc ? faSortDown : faSortUp} />
                </>
              ) : (
                ''
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody ref={tbodyRef}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              ref={trRef}
              key={row.id}
              {...row.getRowProps()}
              onClick={() => {
                rowClickHandler(row.original);
              }}
            >
              {row.cells.map((cell, index) => {
                const header = headerGroups[0].headers[index];
                return (
                  <td
                    key={`tbody-column-${cell.value}`}
                    className={`cell-size-${header.size} ${header.center ? 'centered' : ''}`}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
SortableTable.defaultProps = {
  additionalClass: '',
  rowClickHandler: () => {},
  excludeHeader: false,
};
SortableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  additionalClass: PropTypes.string,
  rowClickHandler: PropTypes.func,
  excludeHeader: PropTypes.bool,
};
export default SortableTable;
