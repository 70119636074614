import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import globeIcon from 'assets/images/globe.png';
import { NEXUS_CLIENT_ID } from 'config/constants';

const TabHeader = ({ t, clientId }) =>
  clientId == NEXUS_CLIENT_ID ? (
    <img alt="" src={globeIcon} height="90%" />
  ) : (
    <>
      <FontAwesomeIcon icon={faEye} className="eye-icon" />
      <span className="team-indicator">{`${t('_room_')} ${clientId}`}</span>
    </>
  );
TabHeader.propTypes = {
  clientId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};
export default TabHeader;
