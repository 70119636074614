import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Figure } from 'react-bootstrap';

const Actions = ({ t, teamIndex }) => {
  const actions = useSelector(({ teams }) => teams[teamIndex].actions);
  return (
    <div className="observe-simulation-actions">
      <Figure>
        <div className="title">
          <strong>{t('_actions_')}</strong>
        </div>
        <div>
          {actions.map((action) => (
            <div key={action.id} className={`observe-simulation-actions-${action.status}`}>
              {t(action.title)}
            </div>
          ))}
        </div>
      </Figure>
    </div>
  );
};

Actions.propTypes = {
  teamIndex: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};
export default Actions;
