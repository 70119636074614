import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/TimeLine/Components/SectionHeader';
import SectionRow from 'components/TimeLine/Components/SectionRow';
import ChartAreaRow from 'components/TimeLine/Components/ChartAreaRow';

const Discoveries = ({ t, data }) => (
  <div className="time-line-view-body">
    <SectionHeader t={t} title="_discoveries_" />
    <SectionRow t={t} title="_surface_actual_" quadrupleLine isChart>
      <ChartAreaRow data={data.discoveries.surface} color="#b2b2b2" maxValue={100} height={112} />
    </SectionRow>
    <SectionRow t={t} title="_resources_probability_" quadrupleLine isChart>
      <ChartAreaRow
        data={data.discoveries.probability}
        color="#b2b2b2"
        maxValue={100}
        height={112}
      />
    </SectionRow>
  </div>
);

Discoveries.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({
    discoveries: PropTypes.shape({
      surface: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.number,
          y: PropTypes.number,
        }),
      ),
      probability: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.number,
          y: PropTypes.number,
        }),
      ),
    }),
  }).isRequired,
};
export default Discoveries;
