/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { scenariosPT } from 'components/Scenario/PropTypes';
import SortableTable from 'components/SortableTable/SortableTable';
import { list } from 'components/Scenario/scenarioAction';
import { useNavbar } from 'common/components';

export const correctRows = (t) => [
  {
    Header: t('_name_'),
    sortable: true,
    size: 6,
    accessor: (scenario) => t(scenario.name),
  },
  {
    Header: t('_edit_'),
    sortable: false,
    center: true,
    size: 1,
    accessor: (scenario) => (
      <Link to={`/scenario/edit/${scenario._id}`}>
        <Button variant="outline-secondary" size="sm">
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Link>
    ),
  },
];
export const List = ({ scenarios }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(list());
  }, [dispatch]);
  return (
    <Container>
      {useNavbar('_scenario_')}
      <div className="scenario-page mt-2 mb-2">
        <Nav id="scenario-table" className="navbar navbar-light bg-light">
          <Nav.Item className="navbar-brand">{t('_available_scenarios_')}</Nav.Item>
          <Nav.Item className="nav nav-pills" as="ul">
            <Nav.Item as="li">
              <Link to="/scenario/new">
                <Button variant="success">
                  <FontAwesomeIcon icon={faPlus} />
                  {` ${t('_create_new_')}`}
                </Button>
              </Link>
            </Nav.Item>
          </Nav.Item>
        </Nav>
        <div data-spy="scroll" data-target="#scenario-table" data-offset="0">
          <SortableTable
            columns={correctRows(t)}
            data={scenarios}
            additionalClass="scenario-page-table"
          />
        </div>
      </div>
    </Container>
  );
};

List.propTypes = {
  scenarios: scenariosPT.isRequired,
};

const mapStateToProps = (state) => ({ scenarios: state.scenarios });

export default connect(mapStateToProps, null)(List);
