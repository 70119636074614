import React, { useCallback, useState, useEffect } from 'react';
import i18n from 'i18next';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import validator from 'utils/validator';
import ButtonBar from 'components/ButtonBar/ButtonBar';
import { useNavbar } from 'common/components';
import { update } from 'components/Account/accountActions';
import { goBack } from 'utils/common';
import { getSettingsData } from 'components/User/userActions';

export const Account = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const { user } = store.getState();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [verification, setVerification] = useState('');
  const [language, setLanguage] = useState(user.language);
  const [languages, setLanguages] = useState([]);

  const handleAccountUpdate = useCallback(
    (event) => {
      event.preventDefault();
      if (validator.isAccountFormValid(password, verification)) {
        const form = { language };
        if (password.length) {
          form.password = password;
          form.verification = verification;
        }
        dispatch(
          update(form, () => {
            /* istanbul ignore next */
            const currentLanguage = languages.find(
              (ln) => ln._id == language || ln.key == language,
            );
            /* istanbul ignore next */
            currentLanguage && i18n.changeLanguage(currentLanguage.key.substr(0, 2));
            goBack(history);
          }),
        );
      }
    },
    [password, verification, language, dispatch, languages, history],
  );
  useEffect(() => {
    getSettingsData(({ languages }) => {
      setLanguages(
        languages.filter((language) => user.availableLanguages.indexOf(language._id) !== -1),
      );
    });
  }, [user.availableLanguages]);
  return (
    <Container>
      {useNavbar('_change_your_account_settings_')}
      <Form className="account-form mt-5" method="post" onSubmit={handleAccountUpdate}>
        <Form.Group as={Row} controlId="forOrganization">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            {t('_your_organization_')}
          </Form.Label>
          <Col xs={6}>
            <Form.Label column>{user.company}</Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="forUsername">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            {t('_username_')}
          </Form.Label>
          <Col xs={6}>
            <Form.Label column>{user.username}</Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="password">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            {t('_login_password_')}
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              value={password}
              placeholder={t('_enter_your_password_')}
              type="password"
              name="password"
              {...(password.length ? validator.validate('password', password) : {})}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {t('_password_strength_is_low_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="verification">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            {t('_confirm_password_')}
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              value={verification}
              placeholder={t('_repeat_your_password_')}
              type="password"
              name="verification"
              {...validator.validate('match', { verification, value: password })}
              onChange={(e) => setVerification(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {t('_passwords_dont_match_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formLanguage">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            {t('_language_')}
          </Form.Label>
          <Col xs={3}>
            <Form.Control
              value={language}
              as="select"
              required
              name="language"
              placeholder={t('_language_')}
              onChange={(e) => setLanguage(e.target.value)}
            >
              {languages.map((currentLanguage) => (
                <option key={currentLanguage._id} value={currentLanguage._id}>
                  {t(currentLanguage.name)}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <ButtonBar
          isFormFilled={validator.isAccountFormValid(password, verification)}
          showHistory={false}
          saveActionType="submit"
        />
      </Form>
    </Container>
  );
};

export default Account;
