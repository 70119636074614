import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { TEAMS } from 'config/constants';
import { scoresPT } from 'components/Mission/lib/PropTypes';

const ScoreTable = ({ name, date, data, rows, fullTeams }) => {
  const { t } = useTranslation();
  const getItem = (id) => data.find((item) => item.clientId == id);
  return (
    <Table bordered>
      <thead>
        <tr>
          <th>
            {name}
            <br />
            {date ? moment(date).format('YYYY-MM-DD HH:mm') : ''}
          </th>
          {TEAMS.map((team) => {
            let item = data.find((current) => current.clientId == team.clientId);
            if (!item) {
              item = fullTeams.find((current) => current.clientId == team.clientId) || {
                displayClientId: team.displayClientId || team.clientId,
                clientId: team.clientId,
              };
            }
            return (
              <th key={`header-tab-${item.displayClientId || item.clientId}`}>
                <span className={`team-number team-number-${item.clientId}`}>
                  {item.displayClientId || item.clientId}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.label}>
            <td className={row.color}>{t(row.label)}</td>
            {TEAMS.map(({ clientId }) => {
              const item = getItem(clientId);
              return (
                <td
                  key={`${row.label}-${clientId}`}
                  className={row.key == 'total' ? row.color : ''}
                >
                  {item && typeof item[row.key] !== 'undefined' ? item[row.key] : '-'}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
ScoreTable.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  data: scoresPT.isRequired,
  fullTeams: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};
export default ScoreTable;
