import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { matchPT, locationPT } from 'routes/PropTypes';
import Server from 'components/Server/Server';
import { ROUTES } from 'config/constants';

const ServerRoute = ({ match, location }) => (
  <Switch>
    <Route path={match.path} exact component={Server} />
    <Redirect to={{ pathname: ROUTES.SERVER, state: { from: location } }} />;
  </Switch>
);

ServerRoute.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default ServerRoute;
