import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PrintLogo from 'components/Scores/PrintLogo';
import PrintTitle from 'components/Scores/PrintTitle';
import Events from 'components/TimeLine/Events';
import Position from 'components/TimeLine/Position';
import Energy from 'components/TimeLine/Energy';
import Defects from 'components/TimeLine/Defects';
import Lift from 'components/TimeLine/Lift';
import InfosJobs from 'components/TimeLine/InfosJobs';
import TimeLineHeader from 'components/TimeLine/TimeLineHeader';

const PrintableTimeLine = ({ name, data, formattedData, user, fullTeams, duration }) => {
  const { t } = useTranslation();
  return (
    <>
      {fullTeams.map((team) => {
        const teamData = data[team.clientId];
        if (!teamData) {
          return null;
        }
        const teamFormattedData = formattedData[team.clientId];
        return (
          <div key={`print-table-team-${team.clientId}`}>
            <div className="pagebreak" />
            <PrintTitle
              t={t}
              user={user}
              name={name}
              title="_system_status_"
              clientId={team.clientId}
              displayClientId={team.displayClientId}
            />
            <div className="time-line-view d-none d-print-block">
              <TimeLineHeader t={t} data={teamData} duration={duration} />
              <Events t={t} data={teamData} formattedData={teamFormattedData} />
              <Position t={t} data={teamData} formattedData={teamFormattedData} />
              <Energy t={t} data={teamData} formattedData={teamFormattedData} />
              <Lift t={t} data={teamData} formattedData={teamFormattedData} />
              <Defects t={t} data={teamData} formattedData={teamFormattedData} />
            </div>
            <div className="pagebreak" />
            <PrintTitle
              t={t}
              user={user}
              name={name}
              title="_infos_jobs_"
              clientId={team.clientId}
              displayClientId={team.displayClientId}
            />
            <TimeLineHeader t={t} data={teamData} clientId={team.clientId} duration={duration} />
            <div className="time-line-view d-none d-print-block">
              <Events t={t} data={teamData} formattedData={teamFormattedData} />
              <InfosJobs
                t={t}
                title="_infos_"
                data={teamFormattedData.infoJob.infos}
                extracted={teamFormattedData.infoJob.extractedInfos}
                length={teamFormattedData.length}
              />
              <InfosJobs
                t={t}
                title="_jobs_"
                data={teamFormattedData.infoJob.jobs}
                extracted={teamFormattedData.infoJob.extractedJobs}
                length={teamFormattedData.length}
              />
            </div>
            <PrintLogo t={t} />
          </div>
        );
      })}
    </>
  );
};
PrintableTimeLine.defaultProps = {
  data: {},
  formattedData: {},
  duration: 0,
};
PrintableTimeLine.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  formattedData: PropTypes.shape({}),
  user: PropTypes.shape({
    company: PropTypes.string,
  }).isRequired,
  fullTeams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  duration: PropTypes.number,
};
export default PrintableTimeLine;
