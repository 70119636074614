/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Overlay, Popover, ListGroup } from 'react-bootstrap';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { SESSION_KEY_TYPE } from 'config/constants';

const AvailableClients = ({
  showPopover,
  onClientClick,
  handleClose,
  target,
  teams,
  t,
  placement,
}) => {
  const popoverRef = useRef(null);
  const waitingRoomClients = teams
    .find((x) => x.roomType === SESSION_KEY_TYPE.WAITING_ROOM)
    .clients.sort((elem1, elem2) => (elem1.name.toLowerCase() > elem2.name.toLowerCase() ? 1 : -1));
  const otherPositionClients = teams
    .filter((x) => x.roomType !== SESSION_KEY_TYPE.WAITING_ROOM)
    .flatMap((item) => item.clients)
    .sort((elem1, elem2) => (elem1.name.toLowerCase() > elem2.name.toLowerCase() ? 1 : -1));
  useEffect(() => {
    const handleClick = ({ target }) => {
      if (showPopover && popoverRef.current && !popoverRef.current.contains(target)) {
        handleClose();
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [handleClose, showPopover]);
  return (
    <Overlay show={showPopover} target={target} placement={placement} containerPadding={20}>
      <Popover id="popover-positioned-right" className="team-popover team-popover-select">
        <Popover.Content ref={popoverRef}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="popover-close-icons float-right"
            onClick={handleClose}
          />
          <strong>{t('_form_wating_lounge_')}</strong>
          <ListGroup className="mb-3">
            {waitingRoomClients.map((item) => (
              <ListGroup.Item
                key={item.id}
                className="list-group-item-overlay"
                onClick={() => onClientClick(item)}
              >
                {item.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <strong>{t('_form_other_positions_')}</strong>
          <ListGroup>
            {otherPositionClients.map(
              (client) =>
                client && (
                  <ListGroup.Item
                    className="list-group-item-overlay"
                    key={client.id}
                    onClick={() => onClientClick(client)}
                  >
                    {client.name}
                  </ListGroup.Item>
                ),
            )}
          </ListGroup>
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};
AvailableClients.defaultProps = {
  placement: 'right',
};

AvailableClients.propTypes = {
  showPopover: PropTypes.bool.isRequired,
  onClientClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  target: PropTypes.shape({}).isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  placement: PropTypes.string,
};

export default AvailableClients;
