import React from 'react';
import PropTypes from 'prop-types';

const COLORS = {
  yellow: '#FFED00',
  red: '#E30613',
};

const getColor = ({ yellow, red }) => {
  let color = 'transparent';
  if (yellow) {
    color = COLORS.yellow;
  }
  if (red) {
    color = COLORS.red;
  }
  return color;
};

const AlarmRow = ({ data, length }) => (
  <div className="time-line-view-body-row-content-row">
    {data.map((item) => (
      <div
        style={{
          width: `${(((item.end - item.start + 1) / length) * 100).toFixed(2)}%`,
          backgroundColor: getColor(item),
        }}
        className="filled"
        key={`time-line-view-body-row-content-number-alarm-${item.start}-${item.end}`}
      />
    ))}
  </div>
);

AlarmRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  length: PropTypes.number.isRequired,
};
export default AlarmRow;
