import {
  INIT_SCENARIOS,
  UPDATE_SCENARIO,
  DELETE_SCENARIO,
  ADD_SCENARIO,
} from 'components/Scenario/scenarioActionTypes';

export const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case INIT_SCENARIOS:
      return action.data;
    case UPDATE_SCENARIO:
      return state.map((scenario) => {
        if (scenario._id == action.data._id) {
          scenario = { ...scenario, ...action.data };
        }
        return scenario;
      });
    case DELETE_SCENARIO:
      return state.filter((scenario) => scenario._id !== action.scenarioId);
    case ADD_SCENARIO:
      return [...state, action.scenario];
    default:
      return state;
  }
}
