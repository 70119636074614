/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import {
  INFO_MAX_LENGTH,
  GAME_DECKS,
  MISSION_TYPES,
  INITIAL_FILTER_ID,
  SESSION_TYPE,
  VIDEO_TAB_ID,
  SESSION_KEY_TYPE,
} from 'config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, ListGroup } from 'react-bootstrap';
import { toTime } from 'utils/common';
import SortableTable from 'components/SortableTable/SortableTable';
import MissionFooter from 'components/Mission/lib/MissionFooter';
import AssignTeams from 'components/Mission/AssignTeams';
import Disconnect from 'components/Mission/lib/Disconnect';
import { list, video } from 'components/Mission/missionActions';
import CustomCollapse from 'components/Collapse/CustomCollapse';
import { getFoldersWithMissions, getFolderName } from 'components/Mission/missionsByFolder';
import { userPT } from 'components/User/PropTypes';

export const teamStatusWasChanged = (current, previous) =>
  !current.find(
    ({ clientId, roles, isReady }) =>
      !previous.find(
        (team) =>
          team.clientId === clientId &&
          team.isReady === isReady &&
          team.roles[GAME_DECKS.MAIN.id] === roles[GAME_DECKS.MAIN.id] &&
          team.roles[GAME_DECKS.SUPPORT.id] === roles[GAME_DECKS.SUPPORT.id] &&
          team.roles[GAME_DECKS.COMMANDER.id] === roles[GAME_DECKS.COMMANDER.id],
      ),
  );

export const correctRows = (t, isPlayActive, playCallback) => [
  {
    Header: t('_name_'),
    sortable: true,
    size: 5,
    accessor: (mission) => mission.name,
  },
  {
    Header: t('_type_'),
    sortable: true,
    size: 2,
    accessor: (mission) => t(mission.type),
  },
  {
    Header: t('_duration_'),
    sortable: true,
    size: 2,
    accessor: (mission) => `${toTime(mission.duration)} ${t('_min_')}.`,
  },
  {
    Header: t('_units_'),
    sortable: true,
    center: true,
    size: 1,
    accessor: (mission) => mission.units,
  },
  {
    Header: t('_info_'),
    sortable: false,
    center: true,
    size: 1,
    accessor: (mission) =>
      mission.description ? (
        <>
          <FontAwesomeIcon
            icon={faInfoCircle}
            data-tip={`${mission.description.substring(0, INFO_MAX_LENGTH)}${
              mission.description.length > INFO_MAX_LENGTH ? ' ...' : ''
            }`}
            data-for="tip"
          />
          <ReactTooltip id="tip" place="top" effect="solid" className="tooltip" multiline />
        </>
      ) : (
        <FontAwesomeIcon icon={faInfoCircle} />
      ),
  },
  {
    Header: t('_play_'),
    sortable: false,
    center: true,
    size: 1,
    accessor: (mission) => (
      <Button
        variant="outline-secondary"
        size="sm"
        className="play-session"
        disabled={!isPlayActive}
        onClick={() => playCallback(mission)}
      >
        <FontAwesomeIcon icon={faPlayCircle} />
      </Button>
    ),
  },
];
export const List = ({
  session,
  historyExist,
  missions,
  disconnectTeams,
  playCallback,
  fullTeams,
  sendMessage,
  user,
}) => {
  const { t } = useTranslation();
  const teams = useSelector(
    ({ teams }) =>
      teams.map(({ clientId, roles, isReady, clients, roomType }) => ({
        clientId,
        roles,
        isReady,
        clients,
        roomType,
      })),
    teamStatusWasChanged,
  );
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showAssignTeams, setShowAssignTeams] = useState(false);
  const { language } = session;
  const onAssignTeamsClick = () => {
    setShowAssignTeams(true);
  };
  const onAssignTeamsSubmit = () => {
    setShowAssignTeams(false);
  };

  useEffect(() => {
    if (language) {
      batch(() => {
        dispatch(list());
        dispatch(video(language));
      });
    }
  }, [dispatch, language]);

  const isReady = !!teams.filter((team) => team.isReady).length;
  const [filterId, setFilterId] = useState(INITIAL_FILTER_ID);
  const foldersWithMissions = getFoldersWithMissions(missions, filterId);
  const filteredMissions = missions.filter((mission) => {
    const missionFolder = getFolderName(mission.name);
    return !foldersWithMissions[missionFolder];
  });
  const waitingLounge = fullTeams.find(
    (team) => team.roomType === SESSION_KEY_TYPE.WAITING_ROOM,
  ) || { clients: [] };

  return (
    <>
      {showAssignTeams && session && session.sessionType == SESSION_TYPE.DYNAMIC ? (
        <AssignTeams
          t={t}
          session={session}
          teams={fullTeams}
          onAssignTeamsSubmit={onAssignTeamsSubmit}
          disconnectTeams={disconnectTeams}
          sendMessage={sendMessage}
        />
      ) : (
        <div className="mission-page mt-3 mb-3">
          <ListGroup horizontal="sm" className="p-1 mission-list-group">
            {MISSION_TYPES.mission.map((filter) => (
              <ListGroup.Item
                key={filter.name}
                onClick={() => setFilterId(filter.id)}
                className={`styled-list-group-item ${
                  filterId == filter.id ? 'list-group-item-dark' : ''
                }`}
              >
                {t(filter.name)}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <div data-spy="scroll" data-target="#mission-table" data-offset="0">
            <SortableTable
              columns={correctRows(t, isReady, playCallback)}
              data={filteredMissions.filter(
                (item) => MISSION_TYPES.getFilterIdByType(item.type) === filterId || filterId === 0,
              )}
              additionalClass="mission-page-table"
              foldersWithMissions={foldersWithMissions}
              excludeHeader={
                !filteredMissions.filter(
                  (item) =>
                    MISSION_TYPES.getFilterIdByType(item.type) === filterId || filterId === 0,
                ).length &&
                (filterId == VIDEO_TAB_ID || !Object.keys(foldersWithMissions).length)
              }
            />
          </div>
          {!!Object.keys(foldersWithMissions).length && (
            <CustomCollapse
              correctRows={correctRows}
              missions={missions}
              isReady={isReady}
              playCallback={playCallback}
              foldersWithMissions={foldersWithMissions}
              filteredMissions={filteredMissions}
            />
          )}
        </div>
      )}

      <Disconnect
        show={show}
        disconnectText="_disconnect_all_teams_"
        onHide={() => setShow(false)}
        onDisconnectClick={() => {
          setShow(false);
          disconnectTeams();
        }}
        t={t}
      />
      {!showAssignTeams ? (
        <MissionFooter
          session={session}
          historyExist={historyExist}
          t={t}
          user={user}
          waiting={waitingLounge.clients.length}
          isDisconnectActive={
            fullTeams.filter((team) => team.clients && team.clients.length).length > 0
          }
          disconnect={() => setShow(true)}
          onAssignTeamsClick={onAssignTeamsClick}
        />
      ) : null}
    </>
  );
};

List.propTypes = {
  missions: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  session: PropTypes.shape({
    language: PropTypes.string,
    sessionType: PropTypes.number,
  }).isRequired,
  historyExist: PropTypes.bool.isRequired,
  disconnectTeams: PropTypes.func.isRequired,
  playCallback: PropTypes.func.isRequired,
  fullTeams: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  sendMessage: PropTypes.func.isRequired,
  user: userPT.isRequired,
};

export default List;
