import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import validator from 'utils/validator';
import security from 'common/security/security';
import { ROLES } from 'config/constants';
import { goBack } from 'utils/common';
import DeleteComponent from 'common/DeleteComponent/DeleteComponent';
import { useNavbar } from 'common/components';
import {
  getScenario,
  uploadFile,
  updateData,
  create,
  remove,
  checkNameExistance,
} from 'components/Scenario/scenarioAction';
import UploadBtn from 'common/UploadBtn/UploadBtn';
import Notifier from 'components/Message/Notifier';

const Scenario = () => {
  let { scenarioId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState('');
  const [description, setInfo] = useState('');
  const [file, setFile] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scenario = {
    name,
    file,
    description: description.trim(),
  };

  const changeFile = useCallback(
    (files) => {
      const formData = new FormData();
      formData.append('files[0]', files[0], files[0].name);
      Notifier.loading(true);
      uploadFile(scenarioId, formData, (data) => {
        if (security.isAuthorized() && security.getUser().role == ROLES.admin.role) {
          setFile(data.file);
          Notifier.success(t('_scenario_successfully_updated_'));
          Notifier.loading(false);
        }
      });
    },
    [t, scenarioId],
  );
  const isScenarioFormValid = validator.isScenarioFormValid(scenario) && file.length > 0;

  const handleCreate = useCallback(
    (event) => {
      event.preventDefault();
      if (isScenarioFormValid) {
        checkNameExistance({ name, scenarioId }, () => {
          dispatch(
            !scenarioId
              ? create(scenario, () => goBack(history))
              : updateData(scenarioId, scenario, () => goBack(history)),
          );
        });
      }
    },
    [isScenarioFormValid, dispatch, scenarioId, name, scenario, history],
  );
  const handleDeleteScenario = useCallback(() => {
    dispatch(remove(scenarioId, () => goBack(history)));
  }, [dispatch, history, scenarioId]);
  useEffect(() => {
    if (scenarioId) {
      dispatch(
        getScenario(scenarioId, (scenario) => {
          setName(scenario.name);
          setInfo(scenario.description);
          setFile(scenario.file);
        }),
      );
    }
    return () => Notifier.loading(false);
  }, [dispatch, scenarioId]);
  const navBarText = !scenarioId ? '_create_update_scenario_' : '_edit_delete_scenario_';
  return (
    <Container>
      {useNavbar(navBarText)}
      <Form method="POST" className="mt-5" onSubmit={handleCreate}>
        <Form.Group as={Row} controlId="formName">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_name_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              placeholder={t('_enter_scenario_name_')}
              name="name"
              value={name}
              {...(name.length ? validator.validate('name', name) : {})}
              onChange={(e) => setName(e.target.value.trimLeft())}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t('_scenario_name_is_short_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formInfo" className="my-2">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_info_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              value={description}
              as="textarea"
              rows="2"
              placeholder={t('_enter_scenario_info_')}
              type="text"
              name="info"
              onChange={(e) => setInfo(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formFile" className="mt-5">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_scenario_file_')}</strong>
          </Form.Label>
          <Col xs={2}>
            <UploadBtn t={t} accept={['xls']} onDrop={changeFile} />
          </Col>
        </Form.Group>
        <Row>
          <Col>
            {scenarioId ? (
              <Button
                variant="danger"
                type="button"
                className="col-3"
                id="delete-button"
                onClick={handleShow}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
                {`  ${t('_delete_')}`}
              </Button>
            ) : null}
          </Col>
          <DeleteComponent
            handleClose={handleClose}
            show={show}
            handleDelete={handleDeleteScenario}
            title="_delete_user_message_"
            keepText="_keep_scenario_"
            deleteText="_delete_scenario_"
          />
          <Col className="text-right">
            <Button variant="success" type="submit" disabled={!isScenarioFormValid}>
              <FontAwesomeIcon icon={faCheck} />
              {` ${t('_save_changes_')}`}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Scenario;
