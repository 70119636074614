import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { MESSAGE } from 'config/constants';
import MessageBody from 'components/Message/MessageBody';
import { hideMessage } from 'components/Message/messageActions';

let messageTimeout;
const messageHideTimeout = 2500; //ms

export const Message = ({ message = {} }) => {
  const dispatch = useDispatch();
  const { show } = message;
  const onDismiss = useCallback(() => dispatch(hideMessage()), [dispatch]);
  useEffect(() => {
    if (show) {
      clearTimeout(messageTimeout);
      messageTimeout = setTimeout(
        () => dispatch(hideMessage()),
        message.timeout || messageHideTimeout,
      );
    }
  }, [dispatch, message.timeout, show]);
  return show ? <MessageBody {...message} onDismiss={onDismiss} /> : null;
};

Message.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    variant: PropTypes.oneOf([MESSAGE.ERROR, MESSAGE.SUCCESS, MESSAGE.WARNING, MESSAGE.INFO])
      .isRequired,
    showCloseButton: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    timeout: PropTypes.number,
    translate: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({ message: state.message });

export default connect(mapStateToProps, null)(Message);
