import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import UnitContent from 'components/Unit/UnitContent';
import { serverLogin, transferUnits, getAmount } from 'components/Unit/unitActions';
import { refreshToken } from 'components/Login/loginActions';
import { resetHistory } from 'utils/common';
import UnitLogin from 'components/Unit/UnitLogin';
import TransferUnits from 'components/Unit/TransferUnits';
import Loading from 'assets/images/loading.gif';

const Units = () => {
  const { t } = useTranslation();
  const store = useStore();
  const { user } = store.getState();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentUnits, setCurrentUnits] = useState(0);
  const [masterUnits, setMasterUnits] = useState(0);
  const history = useHistory();
  const getAmountCallback = useCallback(
    (step) => {
      dispatch(
        refreshToken(() => {
          getAmount((amount) => {
            setIsLoading(false);
            setCurrentUnits(amount.currentUnits);
            setMasterUnits(amount.masterUnits);
            setCurrentStep(step);
          });
        }),
      );
    },
    [dispatch],
  );
  const loginHandler = useCallback(
    (masterServer, password) => {
      setIsLoading(true);
      serverLogin(
        {
          masterServer,
          username: user.username,
          password,
        },
        () => getAmountCallback(2),
        () => setIsLoading(false),
      );
    },
    [getAmountCallback, user.username],
  );
  const transferHandler = useCallback(
    (myCurrentUnits, myMasterUnits) => {
      setIsLoading(true);
      transferUnits(
        {
          change: myCurrentUnits - currentUnits,
          currentUnits: myCurrentUnits,
          masterUnits: myMasterUnits,
        },
        () => getAmountCallback(3),
        () => setIsLoading(false),
      );
    },
    [currentUnits, getAmountCallback],
  );
  const resetHistoryCallback = useCallback(() => {
    resetHistory(history);
  }, [history]);
  return (
    <UnitContent t={t} currentStep={currentStep}>
      <>
        {currentStep === 1 ? (
          <UnitLogin t={t} username={user.username} submitHandler={loginHandler} />
        ) : null}
        {currentStep === 2 ? (
          <TransferUnits
            t={t}
            currentUnits={parseFloat(currentUnits)}
            masterUnits={parseFloat(masterUnits)}
            submitHandler={transferHandler}
            isBalance={false}
          />
        ) : null}
        {currentStep === 3 ? (
          <TransferUnits
            t={t}
            currentUnits={parseFloat(currentUnits)}
            masterUnits={parseFloat(masterUnits)}
            submitHandler={resetHistoryCallback}
            isBalance
          />
        ) : null}
        {isLoading ? (
          <div className="loading d-flex justify-content-center align-items-center">
            <Image src={Loading} />
          </div>
        ) : null}
      </>
    </UnitContent>
  );
};

export default Units;
