import PropTypes from 'prop-types';

export const videoPT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  duration: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  __v: PropTypes.number.isRequired,
  _id: PropTypes.string.isRequired,
});

export const videosPT = PropTypes.arrayOf(videoPT);

export default videosPT;
