import i18n from 'i18next';
import { reduxStore } from 'config/reduxStore';
import { MESSAGE, REPORT_TIMEOUT } from 'config/constants';
import { showMessage, hideMessage } from 'components/Message/messageActions';
import { toggleLoading } from 'components/Loading/loadingActions';

export default class Notifier {
  static warning(message, title = '') {
    reduxStore.store.dispatch(showMessage(MESSAGE.WARNING, i18n.t(message), title, false));
  }
  static success(message, title = '') {
    reduxStore.store.dispatch(showMessage(MESSAGE.SUCCESS, i18n.t(message), title, false));
  }
  static error(message, title = '', serverError) {
    reduxStore.store.dispatch(showMessage(MESSAGE.ERROR, i18n.t(message), title, !!serverError));
  }
  static info(message, title = '') {
    reduxStore.store.dispatch(showMessage(MESSAGE.INFO, i18n.t(message), title, false));
  }
  static loading(show = false) {
    reduxStore.store.dispatch(toggleLoading(show));
  }
  static hideMessage() {
    reduxStore.store.dispatch(hideMessage());
  }
  static importReport(data) {
    let message = '';
    const created = (data.create && data.create.count) || 0;
    const updated = (data.update && data.update.count) || 0;
    const error = (data.error && data.error.count) || 0;
    message += `${i18n.t('_successfully_created_', {
      count: `<span class='badge badge-pill badge-success'>${created}</span>`,
      // info: (created ? '<b>(' + data.create.names.join(', ') + ')</b>': null),
    })}<br />`;
    message += `${i18n.t('_successfully_updated_', {
      count: `<span class='badge badge-pill badge-success'>${updated}</span>`,
      // info: (updated ? '<b>(' + data.update.names.join(', ') + ')</b>': null),
    })}<br />`;
    message += i18n.t('_not_imported_', {
      count: `<span class='badge badge-pill badge-danger'>${error}</span>`,
      info: error ? `<b>( ${data.error.names.join(', ')} )</b>` : null,
    });
    reduxStore.store.dispatch(
      showMessage(
        data.error ? MESSAGE.ERROR : MESSAGE.INFO,
        message,
        '',
        false,
        REPORT_TIMEOUT,
        false,
      ),
    );
  }
}
