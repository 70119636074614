import React from 'react';
import PropTypes from 'prop-types';
import { Figure } from 'react-bootstrap';
import { GAME_DECKS } from 'config/constants';

const DefectiveSystem = ({ t, defectiveSystem }) => (
  <div className="observe-simulation-defective-system">
    <Figure>
      <div className="title">
        <strong>{t('_defective_system_')}</strong>
      </div>
      <div>
        <div
          className={`observe-simulation-defective-system-${Number(
            defectiveSystem[GAME_DECKS.MAIN.id],
          )}`}
        >
          {t(GAME_DECKS.MAIN.name)}
        </div>
        <div
          className={`observe-simulation-defective-system-${Number(
            defectiveSystem[GAME_DECKS.SUPPORT.id],
          )}`}
        >
          {t(GAME_DECKS.SUPPORT.name)}
        </div>
        <div
          className={`observe-simulation-defective-system-${Number(
            defectiveSystem[GAME_DECKS.COMMANDER.id],
          )}`}
        >
          {t(GAME_DECKS.COMMANDER.name)}
        </div>
      </div>
    </Figure>
  </div>
);

DefectiveSystem.propTypes = {
  defectiveSystem: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};
export default DefectiveSystem;
