import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toTime } from 'utils/common';

const RemainingTime = ({ duration }) => {
  const spentTime = useSelector(({ teams }) => {
    let time = null;
    teams.forEach((team) => {
      if (time < team.time) {
        time = team.time;
      }
    });
    return time;
  });
  const time =
    typeof duration !== 'undefined' && spentTime !== null
      ? duration - spentTime <= 0
        ? toTime(0)
        : `-${toTime(duration - spentTime)}`
      : '';
  return time ? (
    <>
      <span className="timer-time">{time}</span>
    </>
  ) : null;
};

RemainingTime.defaultProps = {
  duration: undefined,
};

RemainingTime.propTypes = {
  duration: PropTypes.number,
};

export default RemainingTime;
