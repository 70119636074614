import React from 'react';
import PropTypes from 'prop-types';

const TimeLineHeader = ({ t, data, duration }) => {
  const keys = Object.keys(data);
  const step = (duration || keys[keys.length - 1]) / 600;
  return (
    <div className="print-table-header d-none d-print-block">
      <div>
        <span>{t('_mission_time_')}</span>
        <span>0</span>
      </div>
      {Array(10)
        .fill(1)
        .map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`print-table-header-${index}`}>
            {Math.round((item + index) * step * 100) / 100}
          </div>
        ))}
    </div>
  );
};

TimeLineHeader.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  duration: PropTypes.number.isRequired,
};
export default TimeLineHeader;
