import React from 'react';
import PropTypes from 'prop-types';
import { Figure } from 'react-bootstrap';

const Actions = ({ t, actions }) => (
  <div className="observe-simulation-actions">
    <Figure>
      <div className="title">
        <strong>{t('_actions_')}</strong>
      </div>
      <div>
        {actions.map((action) => (
          <div key={action.id} className={`observe-simulation-actions-${action.status}`}>
            {t(action.title)}
          </div>
        ))}
      </div>
    </Figure>
  </div>
);

Actions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
};
export default Actions;
