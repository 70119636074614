import axios from 'axios';
import { API_URL } from 'config/constants';
import {
  INIT_ADMIN_MISSION_LIST,
  UPDATE_ADMIN_MISSION,
  ADD_ADMIN_MISSION,
  DELETE_ADMIN_MISSION,
} from 'components/AdminMission/adminMissionActionTypes';

export const list = () => (dispatch) => {
  axios.get(`${API_URL}mission`).then(({ data }) => {
    dispatch({
      type: INIT_ADMIN_MISSION_LIST,
      data,
    });
  });
};

export const getMission = (missionId, missionCallback) => (dispatch) => {
  axios.get(`${API_URL}mission/${missionId}`).then(({ data }) => {
    missionCallback(data);
    dispatch({
      type: UPDATE_ADMIN_MISSION,
      data,
    });
  });
};

export const createMission = (state, createCallback) => (dispatch) => {
  axios.post(`${API_URL}mission`, state).then(({ data }) => {
    createCallback(data);
    dispatch({
      type: ADD_ADMIN_MISSION,
      data,
    });
  });
};

export const updateMission = (missionId, state, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}mission/${missionId}`, state).then(({ data }) => {
    updateCallback(data);
    dispatch({
      type: UPDATE_ADMIN_MISSION,
      data,
    });
  });
};

export const deleteMission = (missionId, deleteCallback) => (dispatch) => {
  axios.delete(`${API_URL}mission/${missionId}`).then(() => {
    deleteCallback();
    dispatch({
      type: DELETE_ADMIN_MISSION,
      missionId,
    });
  });
};

export const importMissions = (missions, uploadCallback) => {
  axios.put(`${API_URL}mission/import`, missions).then(({ data }) => uploadCallback(data));
};

export const checkNameExistance = ({ name, missionId }, callback) => {
  axios.get(`${API_URL}mission/exists/${name}${missionId ? `/${missionId}` : ''}`).then(callback);
};

export default {
  list,
  getMission,
  createMission,
  updateMission,
  deleteMission,
  importMissions,
  checkNameExistance,
};
