import {
  INIT_ADMIN_MISSION_LIST,
  UPDATE_ADMIN_MISSION,
  ADD_ADMIN_MISSION,
  DELETE_ADMIN_MISSION,
} from 'components/AdminMission/adminMissionActionTypes';

export const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case INIT_ADMIN_MISSION_LIST:
      return action.data;
    case UPDATE_ADMIN_MISSION:
      return state.map((mission) => {
        if (mission._id == action.data._id) {
          mission = { ...mission, ...action.data };
        }
        return mission;
      });
    case ADD_ADMIN_MISSION:
      return [...state, action.data];
    case DELETE_ADMIN_MISSION:
      return state.filter((mission) => mission._id !== action.missionId);
    default:
      return state;
  }
}
