import React from 'react';
import PropTypes from 'prop-types';
import { Figure, Col } from 'react-bootstrap';
import { fillZero } from 'utils/common';

const SolarReceptor = ({ t, solarReceptor }) => {
  const getDisabledIndex = (index) => {
    const disabled = Math.ceil((100 - solarReceptor.position) / 25);
    let myIndex = false;
    switch (index) {
      case 0:
        if (4 <= disabled) {
          myIndex = true;
        }
        break;
      case 1:
        if (3 <= disabled) {
          myIndex = true;
        }
        break;
      case 2:
        if (2 <= disabled) {
          myIndex = true;
        }
        break;
      case 3:
        if (1 <= disabled) {
          myIndex = true;
        }
    }
    return myIndex;
  };
  const getSolarPosition = () => (Math.ceil(((100 - solarReceptor.position) / 100) * 4) / 4) * 100;
  return (
    <div className="observe-simulation-solar">
      <Figure>
        <div>
          <strong>{t('_solar_receptor_')}</strong>
        </div>
        <div>
          <Col xs={7} className="observe-simulation-solar-board">
            {solarReceptor.fields.map((row, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`solar-row-${index}`}
                className="observe-simulation-solar-row"
                disabled={getDisabledIndex(index)}
              >
                {row.map((cell) => (
                  <div
                    className="observe-simulation-solar-row-field"
                    key={`solar-field-${cell.id}`}
                  >
                    <div className="cell">
                      {cell && cell.accu ? fillZero(cell.accu.energy / 10, 2) : '-'}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </Col>
          <Col xs={{ span: 4, offset: 1 }} className="observe-simulation-solar-panel">
            <div style={{ height: `${getSolarPosition()}%` }} />
          </Col>
        </div>
      </Figure>
    </div>
  );
};

SolarReceptor.propTypes = {
  solarReceptor: PropTypes.shape({
    position: PropTypes.number.isRequired,
    fields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};
export default SolarReceptor;
