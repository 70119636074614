import axios from 'axios';
import { API_URL } from 'config/constants';
import { INIT_RESOURCE_LIST, UPDATE_RESOURCE } from 'components/Resource/resourceActionTypes';

export const list = () => (dispatch) => {
  axios.get(`${API_URL}resource`).then(({ data }) => {
    dispatch({
      type: INIT_RESOURCE_LIST,
      data,
    });
  });
};

export const getResource = (resourceId, resourceCallback) => (dispatch) => {
  axios.get(`${API_URL}resource/${resourceId}`).then(({ data }) => {
    resourceCallback(data);
    dispatch({
      type: UPDATE_RESOURCE,
      data,
    });
  });
};

export const updateScript = (resourceId, resource, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}resource/${resourceId}`, resource).then(({ data }) => {
    updateCallback(data);
    dispatch({
      type: UPDATE_RESOURCE,
      data,
    });
  });
};

export const uploadFile = (resourceId, resource, uploadCallback) => {
  axios.put(`${API_URL}resource/${resourceId}/messages`, resource).then(uploadCallback);
};

export default { list, getResource, updateScript };
