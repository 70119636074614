/* eslint-disable react/display-name */
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toTime } from 'utils/common';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from 'config/constants';
import { adminMissionsPT } from 'components/AdminMission/PropTypes';
import SortableTable from 'components/SortableTable/SortableTable';
import { list, importMissions } from 'components/AdminMission/adminMissionActions';
import { useNavbar } from 'common/components';
import downloader from 'utils/downloader';
import UploadBtn from 'common/UploadBtn/UploadBtn';
import Notifier from 'components/Message/Notifier';
import exportIcon from 'assets/images/export.png';

const MISSIONS_ARCHIVE_NAME = 'missions.zip';

export const correctRows = (t, groups) => [
  {
    Header: t('_name_'),
    sortable: true,
    size: 6,
    accessor: (adminMission) => adminMission.name,
  },
  {
    Header: t('_group_'),
    sortable: true,
    size: 1,
    accessor: ({ group }) => {
      group = groups.find((item) => item._id == group);
      return group ? parseInt(group.name) : '';
    },
  },
  {
    Header: t('_duration_'),
    sortable: true,
    size: 3,
    accessor: (adminMission) => `${toTime(adminMission.duration)} min․`,
  },
  {
    Header: t('_units_'),
    sortable: true,
    size: 1,
    center: true,
    accessor: (adminMission) => adminMission.units,
  },
  {
    Header: t('_edit_'),
    sortable: false,
    size: 1,
    center: true,
    accessor: (adminMission) => (
      <Link to={`/mission/edit/${adminMission._id}`}>
        <Button variant="outline-secondary" size="sm">
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Link>
    ),
  },
];
export const List = ({ adminMissions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const groups = useSelector(({ settings }) => settings.groups);
  useEffect(() => {
    dispatch(list());
  }, [dispatch]);
  const exportMissions = useCallback(() => {
    downloader.downloadFromUrl(`${API_URL}mission/export`, MISSIONS_ARCHIVE_NAME);
  }, []);

  const changeFile = useCallback(
    (files) => {
      const formData = new FormData();
      formData.append('missions', files[0], files[0].name);
      importMissions(formData, (data) => {
        dispatch(list());
        Notifier.importReport(data);
      });
    },
    [dispatch],
  );

  return (
    <Container>
      {useNavbar('_missions_')}
      <div className="admin-mission-page mt-2 mb-2">
        <Nav id="admin-mission-table" className="navbar navbar-light bg-light">
          <Nav.Item className="navbar-brand">{t('_available_missions_')}</Nav.Item>
          <Nav.Item className="nav nav-pills" as="ul">
            <Nav.Item as="li">
              <UploadBtn t={t} onDrop={changeFile} accept={['zip']} isUpload={false} />
            </Nav.Item>
            <Nav.Item as="li">
              <Button variant="secondary-outline" className="nav-icons" onClick={exportMissions}>
                <img alt="" src={exportIcon} />
                {` ${t('_export_')}`}
              </Button>
            </Nav.Item>
            <Nav.Item as="li">
              <Link to="/mission/new">
                <Button variant="success">
                  <FontAwesomeIcon icon={faPlus} />
                  {` ${t('_create_new_')}`}
                </Button>
              </Link>
            </Nav.Item>
          </Nav.Item>
        </Nav>
        <div data-spy="scroll" data-target="#admin-mission-table" data-offset="0">
          <SortableTable
            columns={correctRows(t, groups)}
            data={adminMissions}
            additionalClass="admin-mission-page-table"
          />
        </div>
      </div>
    </Container>
  );
};

List.propTypes = {
  adminMissions: adminMissionsPT.isRequired,
};

const mapStateToProps = (state) => ({
  adminMissions: state.adminMissions,
  settings: state.settings,
});

export default connect(mapStateToProps, null)(List);
