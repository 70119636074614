import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { useNavbar } from 'common/components';
import {
  getMission,
  createMission,
  updateMission,
  deleteMission,
  checkNameExistance,
} from 'components/AdminMission/adminMissionActions';
import validator from 'utils/validator';
import CoffeScript from 'common/CoffeeScript/CoffeeScript';
import ButtonBar from 'components/ButtonBar/ButtonBar';
import DeleteComponent from 'common/DeleteComponent/DeleteComponent';
import { goBack, excludeLetters } from 'utils/common';
import Notifier from 'components/Message/Notifier';

const META_TEG = '@META';
const Editor = React.memo(CoffeScript);

export const fetchMetaData = (script) => {
  const meta = {};
  const rows = script.split('\n');
  rows.forEach((row) => {
    const command = row.split(META_TEG);
    if (command.length === 2) {
      const commandParts = command[1].split(',');
      const metaName = commandParts[0].trim();
      const metaValue = commandParts[1].replace(/"/g, '').trim();
      meta[metaName] = metaValue;
    }
  });
  return meta;
};

const AdminMission = () => {
  const { missionId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const groups = useSelector(({ settings }) => settings.groups);
  const [name, setName] = useState('');
  const [units, setUnits] = useState(0);
  const [description, setDesription] = useState('');
  const [script, setScript] = useState('');
  const [show, setShow] = useState(false);
  const [group, setGroup] = useState(null);
  const modalOpen = () => setShow(true);
  const modalClose = () => setShow(false);

  const getGroup = useCallback(
    (id) => groups.find((group) => Number(group.name) === Number(id)) || {},
    [groups],
  );

  const readMeta = useCallback(
    (e) => {
      e.preventDefault();
      const meta = fetchMetaData(script);
      /* istanbul ignore else */
      if (meta.name) {
        setName(meta.name);
      }
      /* istanbul ignore else */
      if (meta.units) {
        setUnits(meta.units);
      }
      /* istanbul ignore else */
      if (meta.info) {
        setDesription(meta.info);
      }
      /* istanbul ignore else */
      if (meta.group) {
        setGroup(getGroup(meta.group)._id);
      }
    },
    [getGroup, script],
  );
  const isValidMission = validator.isValidMission({
    name,
    units,
    description,
    script,
  });
  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (isValidMission) {
        checkNameExistance({ name, missionId }, () => {
          const form = { name, units, description: description.trim(), script, mode: 'sec', group };
          dispatch(
            missionId
              ? updateMission(missionId, form, () => {
                  Notifier.success('_mission_successfully_updated_');
                  goBack(history);
                })
              : createMission(form, () => {
                  Notifier.success('_mission_successfully_created_');
                  goBack(history);
                }),
          );
        });
      }
    },
    [description, dispatch, history, isValidMission, missionId, name, script, units, group],
  );
  const handleDeleteMission = useCallback(() => {
    modalClose();
    dispatch(
      deleteMission(missionId, () => {
        Notifier.success('_mission_successfully_removed_');
        goBack(history);
      }),
    );
  }, [dispatch, history, missionId]);
  useEffect(() => {
    if (!missionId && groups[0]) {
      setGroup(groups[0]._id);
    }
  }, [groups, missionId]);
  useEffect(() => {
    if (missionId) {
      dispatch(
        getMission(missionId, (mission) => {
          setName(mission.name);
          setUnits(mission.units);
          setDesription(mission.description);
          setScript(mission.script);
          setGroup(mission.group);
        }),
      );
    }
  }, [dispatch, missionId]);
  return (
    <Container>
      {useNavbar(t(missionId ? '_edit_delete_mission_' : '_create_mission_'))}
      <Form className="admin-mission-page-form mt-5" method="post" onSubmit={submitHandler}>
        <Form.Group as={Row} controlId="formName">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_name_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              value={name}
              pattern=".{2,}"
              placeholder={t('_enter_mission_name_')}
              required
              maxLength={47}
              minLength={2}
              name="name"
              onChange={(e) => setName(e.target.value.trimLeft())}
              {...(name.length ? validator.validate('missionName', name) : {})}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formUnits">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_units_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              value={units}
              placeholder={t('_enter_mission_units_')}
              required
              name="units"
              type="number"
              step="0.5"
              onKeyDown={excludeLetters}
              onChange={(e) => setUnits(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formDescription">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_info_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              value={description}
              placeholder={t('_enter_mission_info_')}
              as="textarea"
              name="description"
              onChange={(e) => setDesription(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formGroup">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_group_')}</strong>
          </Form.Label>
          <Col xs={1}>
            <Form.Control
              className="select-style"
              as="select"
              name="group"
              onChange={(e) => {
                setGroup(e.target.value);
              }}
              {...(group ? { value: group } : {})}
            >
              {groups.map((item) => (
                <option value={item._id} key={item._id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_script_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Editor script={script} changeScript={setScript} />
          </Col>
        </Form.Group>
        <ButtonBar
          showReadMeta
          showHistory={false}
          isFormFilled={isValidMission}
          readMeta={readMeta}
          saveActionType="submit"
          missionId={missionId}
          missionDelete={missionId ? modalOpen : null}
        />
      </Form>
      <DeleteComponent
        handleClose={modalClose}
        show={show}
        handleDelete={handleDeleteMission}
        title="_delete_mission_msg_"
        keepText="_keep_mission_"
        deleteText="_delete_mission_"
      />
    </Container>
  );
};

export default AdminMission;
