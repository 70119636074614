import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18next';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import validator from 'utils/validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavbar } from 'common/components';
import { update } from 'components/Account/accountActions';
import { goBack } from 'utils/common';
import { getSettingsData } from 'components/User/userActions';

export const AdminAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const { user } = store.getState();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [verification, setVerification] = useState('');
  const [language, setLanguage] = useState(user.language);
  const [languages, setLanguages] = useState([]);

  const account = {
    language,
  };
  const isAccountFormValid = validator.isAccountFormValid(password, verification);
  if (password.length) {
    account.password = password;
    account.verification = verification;
  }
  const handleAdminAccountUpdate = useCallback(
    (event) => {
      event.preventDefault();
      /* istanbul ignore else */
      if (isAccountFormValid) {
        dispatch(
          update(account, () => {
            /* istanbul ignore next */
            const currentLanguage = languages.find((ln) => ln._id == language);
            /* istanbul ignore next */
            currentLanguage && i18n.changeLanguage(currentLanguage.key.substr(0, 2));
            goBack(history);
          }),
        );
      }
    },
    [isAccountFormValid, dispatch, account, languages, history, language],
  );
  useEffect(() => {
    getSettingsData((settingsData) => {
      setLanguages(settingsData.languages);
    });
  }, []);
  return (
    <Container>
      {useNavbar('_change_your_account_settings_')}
      <Form className="account-form mt-5" method="post" onSubmit={handleAdminAccountUpdate}>
        <Form.Group as={Row} controlId="password">
          <Form.Label column xs={{ span: 3, offset: 1 }} md={{ span: 2, offset: 2 }}>
            <strong>{t('_login_password_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              value={password}
              placeholder={t('_enter_your_password_')}
              type="password"
              name="password"
              {...(password.length ? validator.validate('password', password) : {})}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {t('_password_strength_is_low_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="verification">
          <Form.Label column xs={{ span: 3, offset: 1 }} md={{ span: 2, offset: 2 }}>
            <strong>{t('_confirm_password_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              value={verification}
              placeholder={t('_repeat_your_password_')}
              type="password"
              name="verification"
              {...validator.validate('match', { verification, value: password })}
              onChange={(e) => setVerification(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {t('_passwords_dont_match_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formLanguage">
          <Form.Label column xs={{ span: 3, offset: 1 }} md={{ span: 2, offset: 2 }}>
            <strong>{t('_language_')}</strong>
          </Form.Label>
          <Col xs={3}>
            <Form.Control
              value={language}
              as="select"
              required
              name="language"
              placeholder={t('_language_')}
              onChange={(e) => setLanguage(e.target.value)}
            >
              {languages.map((language) => (
                <option key={language.key} value={language._id}>
                  {t(language.name)}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column xs={{ span: 10, offset: 2 }}>
            <hr />
          </Form.Label>
        </Form.Group>
        <Col className="text-right">
          <Button variant="success" type="submit" disabled={!isAccountFormValid}>
            <FontAwesomeIcon icon={faCheck} />
            {` ${t('_save_changes_')}`}
          </Button>
        </Col>
      </Form>
    </Container>
  );
};

export default AdminAccount;
