import { useState, useEffect } from 'react';
import { BREAKPOINTS } from 'config/constants';

export const getBreakPoint = (windowWidth) => {
  if (windowWidth) {
    if (windowWidth < 576) {
      return BREAKPOINTS.XS;
    } else if (windowWidth <= 768) {
      return BREAKPOINTS.SM;
    } else if (windowWidth <= 992) {
      return BREAKPOINTS.MD;
    } else if (windowWidth < 1200) {
      return BREAKPOINTS.LG;
    } else {
      return BREAKPOINTS.XL;
    }
  } else {
    return undefined;
  }
};

function useBreakpoints() {
  const [windowSize, setWindowSize] = useState(getBreakPoint(window.innerWidth));
  function setSize() {
    setWindowSize(getBreakPoint(window.innerWidth));
  }

  useEffect(() => {
    window.addEventListener('resize', setSize);
    return () => window.removeEventListener('resize', setSize);
  }, [setWindowSize]);
  return windowSize;
}

export default useBreakpoints;
