import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Image } from 'react-bootstrap';
import NineCubesLogoLarge from 'assets/images/interlab-logo-large.png';

const PrintableLogo = ({ t }) => (
  <Row className="d-none d-print-block printable-interlab-logo mt-3">
    <Col>
      <span>{t('_boost_your_performance_')}</span>
      <Image src={NineCubesLogoLarge} />
      <span className="owner-name">www.InterLAB.ch</span>
    </Col>
  </Row>
);
PrintableLogo.propTypes = {
  t: PropTypes.func.isRequired,
};
export default PrintableLogo;
