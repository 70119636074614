import React from 'react';
import PropTypes from 'prop-types';
import { backupPT } from 'components/Mission/lib/Lift/PropTypes';
import { fillZero } from 'utils/common';

const Backup = ({ t, backups, isMain }) => (
  <div className={`observe-simulation-lift-backup ${isMain ? '' : 'mt-2'}`}>
    <div className="title">
      {isMain ? 'M ' : 'S '} {t('_reserve_')}
    </div>
    <div className="d-flex justify-content-between">
      {backups.map((backup, i) => (
        <div key={`backup-${i + 1}`} className={`cell ${backup >= 995 && isMain ? 'green' : ''}`}>
          {backup == null ? '-' : fillZero(backup / 10)}
        </div>
      ))}
    </div>
  </div>
);

Backup.propTypes = {
  backups: backupPT.isRequired,
  t: PropTypes.func.isRequired,
  isMain: PropTypes.bool.isRequired,
};
export default Backup;
