import React, { useState, useEffect } from 'react';
import { getMaster } from 'components/Unit/unitActions';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const UnitLogin = ({ t, username, submitHandler }) => {
  const [masterServer, setMasterServer] = useState('');
  const [password, setPassword] = useState('');
  useEffect(() => getMaster((url) => url && setMasterServer(url)), []);
  return (
    <Form
      method="post"
      className="mt-5"
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        submitHandler(masterServer, password);
      }}
    >
      <Form.Group as={Row} controlId="forMasterServer">
        <Form.Label column xs={{ span: 4, offset: 0 }} md={{ span: 3, offset: 1 }}>
          {t('_master_server_address_')}
        </Form.Label>
        <Col xs={6}>
          <Form.Control
            value={masterServer}
            autoComplete="off"
            placeholder={t('_enter_main_server_address_')}
            name="masterServer"
            onChange={(e) => setMasterServer(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {t('_master_server_address_is_required_')}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="forOrganization">
        <Form.Label column xs={{ span: 4, offset: 0 }} md={{ span: 3, offset: 1 }}>
          {t('_username_')}
        </Form.Label>
        <Form.Label column>{username}</Form.Label>
      </Form.Group>
      <Form.Group as={Row} controlId="password" className="mb-5">
        <Form.Label column xs={{ span: 4, offset: 0 }} md={{ span: 3, offset: 1 }}>
          {t('_password_of_master_server_')}
        </Form.Label>
        <Col xs={6}>
          <Form.Control
            value={password}
            autoComplete="new-password"
            placeholder={t('_enter_your_password_')}
            type="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {t('_password_strength_is_low_')}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="my-5">
        <Col xs={{ span: 3, offset: 7 }} className="d-flex justify-content-end">
          <Button
            variant="success"
            block
            className="d-flex justify-content-around align-items-center login-button"
            type="submit"
            disabled={!masterServer || !password}
          >
            <span>{`${t('_log_in_')}`}</span>
            <FontAwesomeIcon icon={faPlay} />
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};
UnitLogin.propTypes = {
  t: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};
export default UnitLogin;
