import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import { toTime } from 'utils/common';

const Indicators = ({ t, indicators }) => (
  <div className="observe-simulation-indicators">
    <ListGroup horizontal="sm" className="my-2">
      <ListGroup.Item>{t('_clock_')}</ListGroup.Item>
      <ListGroup.Item>{toTime(indicators.clock)}</ListGroup.Item>
    </ListGroup>
    <ListGroup horizontal="sm" className="my-2">
      <ListGroup.Item>{t('_speed_')}</ListGroup.Item>
      <ListGroup.Item>{indicators.speed}</ListGroup.Item>
    </ListGroup>
    <ListGroup horizontal="sm" className="my-2">
      <ListGroup.Item>{t('_position_')}</ListGroup.Item>
      <ListGroup.Item>{Math.round(indicators.position / 10) * 10}</ListGroup.Item>
    </ListGroup>
  </div>
);

Indicators.propTypes = {
  t: PropTypes.func.isRequired,
  indicators: PropTypes.shape({
    clock: PropTypes.number.isRequired,
    speed: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
  }).isRequired,
};
export default Indicators;
