import React from 'react';
import StatusBar from 'components/StatusBar/StatusBar';
import NavbarComponent from 'components/NavbarComponent/NavbarComponent';
import Message from 'components/Message/Message';
import Loading from 'components/Loading/Loading';
import AuthorizedFooter from 'components/AuthorizedFooter/AuthorizedFooter';

export const useNavbar = (
  title,
  isHome = false,
  teamStatusBar = false,
  session,
  fullTeams = [],
  translate = true,
  currentMission = {},
) => (
  <>
    <StatusBar />
    <NavbarComponent
      title={title}
      isHome={isHome}
      teamStatusBar={teamStatusBar}
      session={session}
      fullTeams={fullTeams}
      translate={translate}
      currentMission={currentMission}
    />
    <Message />
    <Loading />
  </>
);

export const useFooter = () => <AuthorizedFooter />;

export default { useNavbar, useFooter };
