import axios from 'axios';
import jwtHelper from 'common/jwt/jwtHelper';
import Notifier from 'components/Message/Notifier';
import security from 'common/security/security';
import { ERRORS } from './constants';

export const errorInterceptor = (error) => {
  if (error.response && error.response.data && error.response.data.messages) {
    error.response.data.messages.forEach((message) => {
      Notifier[message.severity](message.text);
    });
  } else if (error.response && error.response.data) {
    Notifier.error('_server_disconnected_');
  } else if (error.message === ERRORS.network_error.error) {
    Notifier.error(ERRORS.network_error.message, ERRORS.network_error.title, true);
  } else if (error.message === ERRORS.network_error.status504) {
    Notifier.error(ERRORS.network_error.message, ERRORS.network_error.title, true);
  }
  if (error.response && error.response.status == ERRORS.network_error.status401) {
    security.logout();
    window.location.replace('/');
  }
  return Promise.reject(error);
};
axios.interceptors.response.use(null, jwtHelper.getErrorResponseInterceptor());
axios.interceptors.response.use(null, errorInterceptor);
axios.interceptors.request.use(jwtHelper.getRequestInterceptor());

export default { errorInterceptor };
