import React from 'react';
import PropTypes from 'prop-types';

const collectTime = (data) => {
  let fill = {};
  let last = false;
  let start = 0;
  let end = 0;
  Object.keys(data).forEach((time) => {
    const abort = data[time].abort;
    if (!abort) {
      return;
    }
    end = time;
    fill[start] = {
      start: Number(start),
      end: Number(end),
      isOn: last,
    };
    if (abort.isOn != last) {
      start = time;
    }
    last = abort.isOn;
  });
  return Object.values(fill);
};

const OrangeScreen = ({ data }) => {
  const fill = collectTime(data);
  const ln = Object.keys(data).length;
  return (
    <div className="time-line-view-body-orange">
      {fill.map((item) => (
        <div
          key={`time-line-view-body-orange-${item.start}-${item.end}`}
          style={{ width: `${((item.end - item.start) / ln) * 100}%` }}
          className={`${item.isOn ? 'filled' : ''}`}
        />
      ))}
    </div>
  );
};
OrangeScreen.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
export default OrangeScreen;
