/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { resourcesPT } from 'components/Resource/PropTypes';
import SortableTable from 'components/SortableTable/SortableTable';
import { list } from 'components/Resource/resourceActions';
import { useNavbar } from 'common/components';

export const correctRows = (t) => [
  {
    Header: t('_name_'),
    sortable: true,
    size: 7,
    accessor: (resource) => resource.name,
  },
  {
    Header: t('_info_'),
    sortable: true,
    size: 3,
    accessor: (resource) => resource.info,
  },
  {
    Header: t('_status_'),
    sortable: false,
    size: 1,
    center: true,
    accessor: (resource) => (
      <p className={`circle-point ${!resource.exist ? 'inactive' : ''}`} title={t('_status_')} />
    ),
  },
  {
    Header: t('_edit_'),
    sortable: false,
    size: 1,
    center: true,
    accessor: (resource) => (
      <Link to={`/resource/edit/${resource._id}`}>
        <Button variant="outline-secondary" size="sm">
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Link>
    ),
  },
];
export const List = ({ resources }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(list());
  }, [dispatch]);
  return (
    <Container>
      {useNavbar('_resources_')}
      <div className="resource-page mt-2 mb-2">
        <Nav id="resource-table" className="navbar navbar-light bg-light">
          <Nav.Item className="navbar-brand">{t('_editable_resources_')}</Nav.Item>
        </Nav>
        <div data-spy="scroll" data-target="#resource-table" data-offset="0">
          <SortableTable
            columns={correctRows(t)}
            data={resources}
            additionalClass="resource-page-table"
          />
        </div>
      </div>
    </Container>
  );
};

List.propTypes = {
  resources: resourcesPT.isRequired,
};

const mapStateToProps = (state) => ({ resources: state.resources });

export default connect(mapStateToProps, null)(List);
