import React from 'react';
import PropTypes from 'prop-types';
import { Jumbotron } from 'react-bootstrap';
import { TEAMS } from 'config/constants';

const Totals = ({ currentTime, teams }) => {
  let nexusTotal = undefined;
  if (teams.nexus) {
    nexusTotal = teams.nexus[currentTime].eventData.total || 0;
  }
  return (
    <>
      {TEAMS.map((item) => {
        let total = '-';
        if (
          teams[item.clientId] &&
          teams[item.clientId][currentTime] &&
          teams[item.clientId][currentTime].eventData
        ) {
          total = teams[item.clientId][currentTime].eventData.total || 0;
        }
        return (
          <Jumbotron
            className="observe-simulation-total justify-content-center align-items-center"
            key={item.clientId}
          >
            {total}
          </Jumbotron>
        );
      })}
      {nexusTotal !== undefined && (
        <Jumbotron className="observe-simulation-total justify-content-center align-items-center">
          {nexusTotal}
        </Jumbotron>
      )}
    </>
  );
};
Totals.propTypes = {
  currentTime: PropTypes.number.isRequired,
  teams: PropTypes.shape({
    nexus: PropTypes.shape({}),
  }).isRequired,
};
export default Totals;
