import React, { useRef, useEffect, useCallback } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import triangle from 'assets/icons/triangle_bordered.png';
import circle from 'assets/icons/circle_bordered.png';
import square from 'assets/icons/square_bordered.png';
import { toTime } from 'utils/common';
import { TAGS, TRAINER_STEP, TRAINER_STEP_COUNT, TRAINER_STEP_WIDTH } from 'config/constants';

const TimeLine = ({ currentTime, team, changeTime }) => {
  const buttonRef = useRef(null);
  const progressBar = useRef(null);
  const times = Object.keys(team);
  const duration = times[times.length - 1];
  const getImage = (id) => {
    let image = null;
    switch (id) {
      case TAGS.TRIANGLE:
        image = triangle;
        break;
      case TAGS.CIRCLE:
        image = circle;
        break;
      case TAGS.SQUARE:
        image = square;
        break;
    }
    return image;
  };

  const eventData = team[currentTime] && team[currentTime].eventData;
  let progress = Math.ceil((currentTime * 100) / duration);
  const mouseUp = useCallback(
    (e) => {
      /* istanbul ignore else */
      if (progressBar.current && progressBar.current.contains(e.target)) {
        const { width, x } = progressBar.current.getBoundingClientRect();
        const position = ((e.clientX - x) / width) * 100;
        let time = Math.round((duration * position) / 100);
        time = time % TRAINER_STEP ? time + 1 : time;
        changeTime(parseInt((time - currentTime) / TRAINER_STEP));
      }
    },
    [changeTime, currentTime, duration],
  );
  useEffect(() => {
    window.addEventListener('mouseup', mouseUp);
    return () => {
      window.removeEventListener('mouseup', mouseUp);
    };
  }, [mouseUp]);
  return eventData ? (
    <div className="trainer-screen-time-line my-2">
      <div>
        <span className="timer-time">{toTime(currentTime)}</span>
      </div>
      <div ref={progressBar} className="trainer-screen-time-line-bar">
        <div
          className="trainer-screen-time-line-progress"
          style={{ width: `calc(${progress}% - 10px)` }}
        />
        <div className="trainer-screen-time-line-tools" style={{ width: `calc(100% - 10px)` }}>
          {eventData.tags.map(({ eventTime, time, eventData }) => (
            <div
              key={time}
              style={{
                left: `${((eventTime / duration) * 100).toFixed(2)}%`,
              }}
            >
              <img alt="" src={getImage(eventData.tagId)} />
            </div>
          ))}
        </div>
        <div className="trainer-screen-time-line-btn" style={{ left: `calc(${progress}% - 10px)` }}>
          <Button ref={buttonRef}>
            <FontAwesomeIcon icon={faBars} />
          </Button>
        </div>
      </div>
      <div>
        <ButtonGroup>
          <Button
            onClick={() => {
              let time = TRAINER_STEP_WIDTH;
              const delta = currentTime % TRAINER_STEP_COUNT;
              if (delta) {
                time = delta / TRAINER_STEP;
              }
              changeTime(-time);
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
          <Button
            onClick={() => {
              let time = TRAINER_STEP_WIDTH;
              const delta = currentTime % TRAINER_STEP_COUNT;
              if (delta) {
                time = (TRAINER_STEP_COUNT - delta) / TRAINER_STEP;
              }
              changeTime(time);
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  ) : null;
};
TimeLine.propTypes = {
  currentTime: PropTypes.number.isRequired,
  team: PropTypes.shape({}).isRequired,
  changeTime: PropTypes.func.isRequired,
};
export default TimeLine;
