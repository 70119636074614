import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import List from 'components/Client/List';
import Edit from 'components/Client/Edit';
import { matchPT, locationPT } from 'routes/PropTypes';
import { ROUTES } from 'config/constants';

const ClientRoute = ({ match, location }) => (
  <Switch>
    <Route path={match.path} exact component={List} />
    <Route path={`${match.path}/edit/:clientId`} exact component={Edit} />
    <Redirect to={{ pathname: ROUTES.CLIENT, state: { from: location } }} />;
  </Switch>
);

ClientRoute.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default ClientRoute;
