import React from 'react';
import PropTypes from 'prop-types';
import StackedAreaChart from 'components/TimeLine/Components/StackedAreaChart';

const ChartAreaRow = ({ data, color, maxValue, inverted, height }) => (
  <StackedAreaChart color={color} max={maxValue} inverted={inverted} height={height} data={data} />
);

ChartAreaRow.defaultProps = {
  data: {},
  color: '#3AAA35',
  inverted: false,
  height: 28,
};

ChartAreaRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  color: PropTypes.string,
  maxValue: PropTypes.number.isRequired,
  inverted: PropTypes.bool,
  height: PropTypes.number,
};
export default ChartAreaRow;
