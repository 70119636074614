import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import ScoreTable from 'components/Scores/ScoreTable';
import ScoreTabTools from 'components/Scores/ScoreTabTools';
import { scoresPT } from 'components/Mission/lib/PropTypes';

const ScoreTabs = ({ t, date, name, scores, fullTeams }) => {
  const [activeTab, setActiveTab] = useState('overall_performance');
  const [{ displayClientId: clientIdStart = 1 } = {}] = fullTeams;
  return (
    <>
      <ScoreTabTools
        t={t}
        activeTab={activeTab}
        changeTab={setActiveTab}
        clientIdStart={clientIdStart}
      />
      <Tabs className="d-print-none scores-page-tabs" activeKey={activeTab}>
        <Tab
          className="d-print-none"
          eventKey="overall_performance"
          title={t('_overall_performance_')}
        >
          <ScoreTable
            name={name}
            date={date}
            data={scores}
            fullTeams={fullTeams}
            rows={[
              {
                label: '_total_',
                key: 'total',
                color: 'blue',
              },
              {
                label: '_distance_',
                key: 'distance',
                color: 'green',
              },
              {
                label: '_jobs_',
                key: 'job',
                color: 'green',
              },
              {
                label: '_alarm_',
                key: 'alarm',
                color: 'red',
              },
              {
                label: '_lift_fault_',
                key: 'liftFault',
                color: 'red',
              },
              {
                label: '_disregards_',
                key: 'disregard',
                color: 'red',
              },
            ]}
          />
        </Tab>
        <Tab className="d-print-none" eventKey="human_error" title={t('_human_error_')}>
          <ScoreTable
            name={name}
            date={date}
            data={scores}
            fullTeams={fullTeams}
            rows={[
              {
                label: '_slips_m_',
                key: 'slipsM',
                color: 'yellow',
              },
              {
                label: '_slips_s_',
                key: 'slipsS',
                color: 'yellow',
              },
              {
                label: '_lapses_',
                key: 'lapses',
                color: 'yellow',
              },
              {
                label: '_mistakes_',
                key: 'mistakes',
                color: 'yellow',
              },
              {
                label: '_violations_m_',
                key: 'violationsM',
                color: 'yellow',
              },
              {
                label: '_violations_s_',
                key: 'violationsS',
                color: 'yellow',
              },
            ]}
          />
        </Tab>
      </Tabs>
    </>
  );
};

ScoreTabs.propTypes = {
  t: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  scores: scoresPT.isRequired,
  fullTeams: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};
export default ScoreTabs;
