import PropTypes from 'prop-types';
import { GAME_DECKS } from 'config/constants';

export const infoPT = PropTypes.shape({
  isRead: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSelectedFirst: PropTypes.bool,
  message: PropTypes.shape({
    isSolved: PropTypes.bool,
  }),
  position: PropTypes.number,
});

export const infoRowPT = PropTypes.arrayOf(infoPT);

export const deckJobInfoPT = PropTypes.shape({
  infos: infoRowPT.isRequired,
  jobs: infoRowPT.isRequired,
});

export const infoListPT = PropTypes.shape({
  [GAME_DECKS.MAIN.id]: deckJobInfoPT,
  [GAME_DECKS.SUPPORT.id]: deckJobInfoPT,
});

export default infoListPT;
