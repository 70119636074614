import axios from 'axios';
import { API_URL } from 'config/constants';
import {
  INIT_SCENARIOS,
  UPDATE_SCENARIO,
  DELETE_SCENARIO,
  ADD_SCENARIO,
} from 'components/Scenario/scenarioActionTypes';

export const list = () => (dispatch) => {
  axios.get(`${API_URL}scenario`).then(({ data }) => {
    dispatch({
      type: INIT_SCENARIOS,
      data,
    });
  });
};

export const getScenario = (scenarioId, getCallback) => (dispatch) => {
  axios.get(`${API_URL}scenario/${scenarioId}`).then(({ data }) => {
    getCallback(data);
    dispatch({
      type: UPDATE_SCENARIO,
      data,
    });
  });
};

export const uploadFile = (scenarioId, scenario, uploadCallback) => {
  const url = scenarioId ? `${API_URL}scenario/${scenarioId}/file` : `${API_URL}scenario/file`;
  const method = scenarioId ? 'put' : 'post';
  axios[method](url, scenario).then(({ data }) => uploadCallback(data));
};

export const updateData = (scenarioId, scenario, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}scenario/${scenarioId}`, scenario).then(({ data }) => {
    updateCallback(data);
    dispatch({
      type: UPDATE_SCENARIO,
      data,
    });
  });
};

export const create = (scenario, createCallback) => (dispatch) => {
  axios.post(`${API_URL}scenario`, scenario).then(({ data }) => {
    dispatch({
      type: ADD_SCENARIO,
      scenario: data,
    });
    createCallback();
  });
};

export const remove = (scenarioId, deleteCallback) => (dispatch) => {
  axios.delete(`${API_URL}scenario/${scenarioId}`).then(() => {
    dispatch({
      type: DELETE_SCENARIO,
      scenarioId,
    });
    deleteCallback();
  });
};

export const checkNameExistance = ({ name, scenarioId }, callback) => {
  axios
    .get(`${API_URL}scenario/exists/${name}${scenarioId ? `/${scenarioId}` : ''}`)
    .then(callback);
};

export default { list, getScenario, uploadFile, updateData, create, remove, checkNameExistance };
