import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Figure } from 'react-bootstrap';
import globeSmall from 'assets/icons/globe-small.png';
import resourcesIcon from 'assets/icons/resources.png';

const Resources = ({ resources }) => {
  const { resource, surface } = useMemo(() => resources, [resources]);
  return (
    <div className="observe-simulation-nexus-resources">
      <Figure>
        <div>
          <div>
            <img alt="" src={globeSmall} height="90%" />
          </div>
          <div>
            <img alt="" src={resourcesIcon} width="100%" />
          </div>
        </div>
        <div>
          <div className="observe-simulation-nexus-resources-board">
            <div>
              <div style={{ height: `${surface}%` }} />
            </div>
            <div>
              <div style={{ height: `${resource}%` }} />
            </div>
          </div>
        </div>
      </Figure>
    </div>
  );
};

Resources.defaultProps = {
  resources: {},
};

Resources.propTypes = {
  resources: PropTypes.shape({}),
};

export default Resources;
