import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import JobInfoList from 'components/Mission/lib/JobInfo/JobInfoList';
import GameInfo from 'components/Mission/lib/GameInfo';
import SolarReceptor from 'components/Mission/lib/SolarReceptor';
import Alarm from 'components/Mission/lib/Alarm';
import EnergyGenerator from 'components/Mission/lib/EnergyGenerator';
import Lift from 'components/Mission/lib/Lift/Lift';
import Actions from 'components/Mission/lib/Actions/Actions';
import External from 'components/Mission/lib/External/External';
import DefectiveSystem from 'components/Mission/lib/DefectiveSystem/DefectiveSystem';

const TabContent = ({ t, teamIndex }) => {
  const initialized = useSelector(({ teams }) => teams[teamIndex].initialized);
  return initialized ? (
    <Row>
      <Col xs={6}>
        <Row>
          <Col>
            <JobInfoList teamIndex={teamIndex} />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <GameInfo t={t} teamIndex={teamIndex} />
          </Col>
          <Col>
            <Alarm t={t} teamIndex={teamIndex} />
          </Col>
        </Row>
      </Col>
      <Col xs={2}>
        <Row>
          <Col>
            <EnergyGenerator teamIndex={teamIndex} />
          </Col>
        </Row>
        <Row>
          <Col xs={10}>
            <SolarReceptor t={t} teamIndex={teamIndex} />
          </Col>
        </Row>
      </Col>
      <Col xs={2}>
        <Lift t={t} teamIndex={teamIndex} />
      </Col>
      <Col xs={2}>
        <Row>
          <Actions teamIndex={teamIndex} t={t} />
        </Row>
        <Row>
          <External teamIndex={teamIndex} t={t} />
        </Row>
        <Row>
          <DefectiveSystem teamIndex={teamIndex} t={t} />
        </Row>
      </Col>
    </Row>
  ) : null;
};
TabContent.propTypes = {
  teamIndex: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};
export default TabContent;
