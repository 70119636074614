import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Form } from 'react-bootstrap';
import { useNavbar } from 'common/components';
import { list, getResource, updateScript, uploadFile } from 'components/Resource/resourceActions';
import ButtonBar from 'components/ButtonBar/ButtonBar';
import EditScript from 'components/Resource/lib/EditScript';
import UploadFile from 'common/UploadFile/UploadFile';
import Notifier from 'components/Message/Notifier';
import { goBack } from 'utils/common';
import validator from 'utils/validator';
import { initDynamicResources } from 'config/translation';

const Edit = () => {
  const { resourceId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [resource, setResource] = useState(null);
  const changeInfo = useCallback((info) => setResource({ ...resource, info }), [resource]);
  const changeScript = useCallback((script) => setResource({ ...resource, script }), [resource]);
  const changeFile = useCallback(
    (files) => {
      const formData = new FormData();
      formData.append('file', resource.file);
      formData.append('name', resource.name);
      formData.append('info', resource.info);
      formData.append('script', resource.script);
      formData.append('files[0]', files[0], files[0].name);
      uploadFile(resource._id, formData, () =>
        Notifier.success(t('_resource_successfully_updated_')),
      );
    },
    [resource, t],
  );
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const form = {
        file: resource.file,
        name: resource.name,
        info: resource.info.trim(),
        script: resource.script,
      };
      dispatch(
        updateScript(resource._id, form, () => {
          goBack(history);
          initDynamicResources();
        }),
      );
    },
    [dispatch, history, resource],
  );
  useEffect(() => {
    dispatch(list());
    dispatch(getResource(resourceId, (data) => setResource(data)));
  }, [dispatch, resourceId]);
  return (
    <Container className="resource-page-edit">
      {useNavbar(t('_edit_delete_resource_'))}
      <Form method="post" onSubmit={handleSubmit}>
        {resource && resource.isFile ? (
          <>
            <UploadFile
              data={resource}
              t={t}
              changeInfo={changeInfo}
              onDrop={changeFile}
              accept={['xls']}
              infoText="_enter_resource_info_"
            />
            <ButtonBar
              isFormFilled={resource && resource.isFile}
              saveActionType="submit"
              showHistory={false}
            />
          </>
        ) : null}
        {resource && !resource.isFile ? (
          <>
            <EditScript
              resource={resource}
              t={t}
              changeInfo={changeInfo}
              changeScript={changeScript}
            />
            <ButtonBar
              isFormFilled={resource && !resource.isFile && validator.coffeeScript(resource.script)}
              saveActionType="submit"
              showHistory={false}
            />
          </>
        ) : null}
      </Form>
    </Container>
  );
};

export default Edit;
