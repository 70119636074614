import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/TimeLine/Components/SectionHeader';
import SectionRow from 'components/TimeLine/Components/SectionRow';
import InfoJobRow from 'components/TimeLine/Components/InfoJobRow';

const LOG = {
  id: 2,
  fill: '#FFED00',
  noFill: 'transparent',
};

const InfosJobs = ({ t, data, title, extracted, length }) => {
  const keys = Object.keys(data);
  return (
    keys.length > 0 && (
      <div className="time-line-view-body">
        <SectionHeader t={t} title={title} />
        {keys.map((rowName) => {
          const row = data[rowName];
          return (
            <SectionRow
              t={t}
              title={row.title}
              key={rowName}
              color={row.log == LOG.id ? LOG.fill : LOG.noFill}
            >
              <InfoJobRow data={extracted} rowName={rowName} rowLength={length - 1} />
            </SectionRow>
          );
        })}
      </div>
    )
  );
};

InfosJobs.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  extracted: PropTypes.shape({}).isRequired,
  length: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};
export default InfosJobs;
