import { TOGGLE_LOADING } from 'components/Loading/loadingActionTypes';

export const toggleLoading = (show) => (dispatch) => {
  dispatch({
    type: TOGGLE_LOADING,
    show,
  });
};

export default { toggleLoading };
