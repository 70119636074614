import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import TabContent from 'components/Mission/lib/TabContent';
import TabHeader from 'components/Mission/lib/TabHeader';
import { GAME_DECKS, NEXUS_CLIENT_ID } from 'config/constants';
import NexusTab from './NexusTab';

export const teamStatusWasChanged = (current, previous) =>
  !current.find(
    ({ clientId, isGameIsStarted, isReady, isFull, roles }) =>
      !previous.find(
        (team) =>
          team.clientId === clientId &&
          team.isGameIsStarted === isGameIsStarted &&
          team.isReady === isReady &&
          team.isFull === isFull &&
          team.roles[GAME_DECKS.COMMANDER.id] === roles[GAME_DECKS.COMMANDER.id],
      ),
  );

const Teams = ({ t, switchGame, isNexus }) => {
  const teams = useSelector(
    ({ teams }) =>
      teams.map(
        ({ clientId, displayClientId, isGameIsStarted, isReady, isFull, roles, key, time }) => ({
          clientId,
          displayClientId,
          isGameIsStarted,
          isReady,
          isFull,
          roles,
          key,
          time,
        }),
      ),
    teamStatusWasChanged,
  );
  const [key, setKey] = useState(1);
  const [isReady, setReady] = useState(false);
  const [teamCount, setTeamCount] = useState(0);
  /// TODO: review the logic of useEffect
  useEffect(() => {
    const count = teams.filter((team) => team.isGameIsStarted).length;
    if ((!isReady || teamCount != count) && count > 0) {
      let choosen = false;
      teams.forEach((team) => {
        if (team.isGameIsStarted && team.clientId && !choosen) {
          setKey(team.clientId);
          switchGame(team.clientId);
          setTeamCount(count);
          setReady(true);
          choosen = true;
        }
      });
    }
  }, [isReady, switchGame, teamCount, teams]);
  const getFullTeams = useCallback(() => {
    let diff = 0;
    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      diff = team.displayClientId - team.clientId;
      break;
    }
    const count = isNexus ? 5 : 4;
    return Array(count)
      .fill(0)
      .map((item, index) => {
        let clientId = index + 1;
        let displayClientId = index + diff + 1;
        const team = teams.find((item) => item.clientId == index + 1) || {
          clientId: index + 1,
        };
        if (index == 4) {
          clientId = NEXUS_CLIENT_ID;
          displayClientId = NEXUS_CLIENT_ID;
          team.clientId = NEXUS_CLIENT_ID;
          team.displayClientId = NEXUS_CLIENT_ID;
        }
        return {
          ...team,
          displayClientId,
          clientId,
        };
      });
  }, [isNexus, teams]);

  const fullTeams = getFullTeams();
  const connectedTeams = fullTeams
    .filter((team) => team.isGameIsStarted)
    .map((team) => team.clientId);
  return (
    <Row className="mt-4">
      <Col className="observe-simulation">
        <Tabs
          activeKey={key}
          className="observe-simulation-teams"
          onSelect={(id) => {
            setKey(id);
            switchGame(id);
          }}
        >
          {fullTeams.map((team, teamIndex) => (
            <Tab
              key={team.clientId}
              eventKey={team.clientId}
              disabled={!team.isGameIsStarted && team.clientId != NEXUS_CLIENT_ID}
              tabClassName="observe-simulation-teams-item"
              title={<TabHeader t={t} clientId={team.displayClientId || team.clientId} />}
            >
              {(team.isGameIsStarted || (!team.isGameIsStarted && team.isReady)) &&
                key == team.clientId && <TabContent t={t} teamIndex={teamIndex} />}
              {team.clientId == NEXUS_CLIENT_ID && (
                <NexusTab t={t} connectedTeams={connectedTeams} teamIndex={teamIndex} />
              )}
            </Tab>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
};
Teams.propTypes = {
  t: PropTypes.func.isRequired,
  isNexus: PropTypes.bool.isRequired,
  switchGame: PropTypes.func.isRequired,
};
export default Teams;
