import React from 'react';
import PropTypes from 'prop-types';
import InfosJobs from 'components/TimeLine/InfosJobs';

const TimeLineTableInfo = ({ t, formattedData }) => (
  <>
    <InfosJobs
      t={t}
      title="_infos_"
      data={formattedData.infoJob.infos}
      extracted={formattedData.infoJob.extractedInfos}
      length={formattedData.length}
    />
    <InfosJobs
      t={t}
      title="_jobs_"
      data={formattedData.infoJob.jobs}
      extracted={formattedData.infoJob.extractedJobs}
      length={formattedData.length}
    />
  </>
);

TimeLineTableInfo.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  formattedData: PropTypes.shape({
    length: PropTypes.number,
    infoJob: PropTypes.shape({
      infos: PropTypes.shape({}),
      extractedInfos: PropTypes.shape({}),
      jobs: PropTypes.shape({}),
      extractedJobs: PropTypes.shape({}),
    }),
  }).isRequired,
};
export default TimeLineTableInfo;
