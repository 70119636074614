import React from 'react';
import PropTypes from 'prop-types';
import { Figure } from 'react-bootstrap';

const External = ({ t, external }) => (
  <div className="observe-simulation-external">
    <Figure>
      <div className="title">
        <strong>{t('_external_')}</strong>
      </div>
      <div>
        <div className={`observe-simulation-external-${Number(external.asteroids)}`}>
          {t('_asteroids_')}
        </div>
        <div className={`observe-simulation-external-${Number(external.traffic)}`}>
          {t('_highway_')}
        </div>
        <div className={`observe-simulation-external-${Number(external.fog)}`}>
          {t('_plasma_fog_')}
        </div>
        <div className={`observe-simulation-external-${Number(external.gravity)}`}>
          {t('_high_gravity_')}
        </div>
        <div className={`observe-simulation-external-${Number(external.radiation)}`}>
          {t('_gamma_radiation_')}
        </div>
        <div className={`observe-simulation-external-${Number(external.unknownDanger)}`}>
          {t('_unknown_danger_')}
        </div>
      </div>
    </Figure>
  </div>
);

External.propTypes = {
  external: PropTypes.shape({
    asteroids: PropTypes.bool.isRequired,
    traffic: PropTypes.bool.isRequired,
    fog: PropTypes.bool.isRequired,
    gravity: PropTypes.bool.isRequired,
    radiation: PropTypes.bool.isRequired,
    unknownDanger: PropTypes.bool.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};
export default External;
