import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Jumbotron, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { RESULT_SWITCH } from 'config/constants';

export const getNext = (allowed, active) => {
  const next = RESULT_SWITCH.next(active);
  if (allowed.includes(next.id)) {
    return next;
  }
  return getNext(allowed, next.id);
};
export const getPrev = (allowed, active) => {
  const prev = RESULT_SWITCH.prev(active);
  if (allowed.includes(prev.id)) {
    return prev;
  }
  return getPrev(allowed, prev.id);
};

const PageSwitch = ({ t, active, switchScreen, allowed }) => {
  const activeScreen = RESULT_SWITCH.getById(active);
  const next = getNext(allowed, active);
  const prev = getPrev(allowed, active);
  return (
    activeScreen && (
      <Row className="result-page-switch">
        <Col xs={8} md={6}>
          <Jumbotron>
            <div>
              <Button block onClick={() => switchScreen(prev.id)}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
            </div>
            <div>{t(activeScreen.title)}</div>
            <div>
              <Button block onClick={() => switchScreen(next.id)}>
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
          </Jumbotron>
        </Col>
      </Row>
    )
  );
};
PageSwitch.propTypes = {
  t: PropTypes.func.isRequired,
  allowed: PropTypes.arrayOf(PropTypes.number).isRequired,
  switchScreen: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
};
export default PageSwitch;
