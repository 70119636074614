import axios from 'axios';
import { API_URL } from 'config/constants';

export const serverLogin = (state, serverLoginCallback, errorCallback) => {
  axios
    .post(`${API_URL}units/login`, state)
    .then(({ data }) => serverLoginCallback(data))
    .catch(errorCallback);
};

export const getAmount = (amountCallback) => {
  axios.get(`${API_URL}units/amount`).then(({ data }) => amountCallback(data));
};

export const transferUnits = (state, transferCallback, errorCallback) => {
  axios
    .post(`${API_URL}units/transfer`, state)
    .then(({ data }) => transferCallback(data))
    .catch(errorCallback);
};

export const getUnitsAvailable = (callback) => {
  axios.get(`${API_URL}units`).then(({ data }) => callback(data.availble));
};

export const getMaster = (callback) => {
  axios.get(`${API_URL}units/master`).then(({ data }) => callback(data.url));
};

export default { serverLogin, getAmount, transferUnits, getUnitsAvailable, getMaster };
