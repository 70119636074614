import React from 'react';
import PropTypes from 'prop-types';

const AbsoluteBooleanRow = ({ data, color, length }) => (
  <div className="time-line-view-body-row-content-row time-line-view-body-row-content-row-absolute">
    {data.map((item) => (
      <div
        style={{
          width: `${(((item.end - item.start + 1) / length) * 100).toFixed(2)}%`,
          backgroundColor: color,
        }}
        className={item.filled ? 'filled' : ''}
        key={`time-line-view-body-row-content-absolute-${item.start}-${item.end}`}
      />
    ))}
  </div>
);

AbsoluteBooleanRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  length: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
export default AbsoluteBooleanRow;
