import PropTypes from 'prop-types';
import infoListPT from 'components/Mission/lib/JobInfo/PropTypes';
import liftInfoPT from 'components/Mission/lib/Lift/PropTypes';

export const stepPT = PropTypes.shape({
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
});

export const stepsPT = PropTypes.arrayOf(stepPT);

export const indicatorsPT = PropTypes.shape({
  clock: PropTypes.number.isRequired,
  speed: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
});

export const energyPT = PropTypes.shape({
  energy: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
});

export const generatorPT = PropTypes.shape({
  fields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  dials: PropTypes.arrayOf(energyPT.isRequired).isRequired,
});

export const rolesPT = PropTypes.shape({
  0: PropTypes.bool.isRequired,
  1: PropTypes.bool.isRequired,
  2: PropTypes.bool.isRequired,
});

export const teamPT = PropTypes.shape({
  clientId: PropTypes.number.isRequired,
  infoJob: infoListPT,
  indicators: indicatorsPT,
  solarReceptor: PropTypes.number,
  generator: generatorPT,
  alarm: PropTypes.bool,
  lift: liftInfoPT,
  isReady: PropTypes.bool,
  isFull: PropTypes.bool,
  isGameIsStarted: PropTypes.bool,
  key: PropTypes.string,
  roles: rolesPT,
});

export const teamsPT = PropTypes.arrayOf(teamPT.isRequired);

export const scorePT = PropTypes.shape({
  alarm: PropTypes.number,
  clientId: PropTypes.number.isRequired,
  disregard: PropTypes.number,
  distance: PropTypes.number,
  job: PropTypes.number,
  lapses: PropTypes.number,
  liftFault: PropTypes.number,
  mistakes: PropTypes.number,
  slipsM: PropTypes.number,
  slipsS: PropTypes.number,
  total: PropTypes.number.isRequired,
  violationsM: PropTypes.number,
  violationsS: PropTypes.number,
  _id: PropTypes.string.isRequired,
});

export const scoresPT = PropTypes.arrayOf(scorePT.isRequired);

export const resultPT = PropTypes.shape({
  date: PropTypes.string.isRequired,
  missionId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  scores: scoresPT.isRequired,
});

export const videoTeamsPT = PropTypes.shape({
  videoRooms: PropTypes.arrayOf(PropTypes.string.isRequired),
  roles: PropTypes.arrayOf(PropTypes.number.isRequired),
});

export default teamsPT;
