import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ListGroup } from 'react-bootstrap';
import { toTime } from 'utils/common';

const GameInfo = ({ t, teamIndex }) => {
  const indicators = useSelector(({ teams }) => teams[teamIndex].indicators);
  return (
    <div className="observe-simulation-indicators">
      <ListGroup horizontal className="my-2">
        <ListGroup.Item>{t('_clock_')}</ListGroup.Item>
        <ListGroup.Item>{toTime(indicators.clock)}</ListGroup.Item>
      </ListGroup>
      <ListGroup horizontal className="my-2">
        <ListGroup.Item>{t('_speed_')}</ListGroup.Item>
        <ListGroup.Item>{indicators.speed}</ListGroup.Item>
      </ListGroup>
      <ListGroup horizontal className="my-2">
        <ListGroup.Item>{t('_position_')}</ListGroup.Item>
        <ListGroup.Item>{Math.round(indicators.position / 10) * 10}</ListGroup.Item>
      </ListGroup>
    </div>
  );
};

GameInfo.propTypes = {
  t: PropTypes.func.isRequired,
  teamIndex: PropTypes.number.isRequired,
};
export default GameInfo;
