import React from 'react';
import PropTypes from 'prop-types';

const LONG_COMPANY = 45;

const PrintTitle = ({ name, user, t, title, clientId, displayClientId }) => {
  let companyClassName = '';
  const company = t(user.company);
  if (company.length > LONG_COMPANY) {
    companyClassName = `print-title-sm`;
  }
  return (
    <div style={{ position: 'relative' }}>
      <div className={`d-none d-print-block print-title ${companyClassName}`}>
        <span>{t('_skills_lab_')}</span> | <span>{company}</span>
      </div>
      <div className="d-none d-print-block print-title-small">
        {t('_mission_')}: {name}
      </div>
      <div className="d-none d-print-block print-title-page">{t(title)}</div>
      {clientId && displayClientId && (
        <div className="d-none d-print-block team-print-block">
          <span>{t('_team_')}</span>
          <div className={`team-print-number-large team-print-number-large-${clientId}`}>
            <div>{displayClientId}</div>
          </div>
        </div>
      )}
    </div>
  );
};
PrintTitle.defaultProps = {
  clientId: null,
  displayClientId: null,
};
PrintTitle.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.shape({
    company: PropTypes.string,
  }).isRequired,
  clientId: PropTypes.number,
  displayClientId: PropTypes.number,
};
export default PrintTitle;
