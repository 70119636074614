import axios from 'axios';
import { API_URL } from 'config/constants';
import {
  INIT_VIDEOS,
  UPDATE_VIDEO,
  DELETE_VIDEO,
  ADD_VIDEO,
} from 'components/Video/videoActionTypes';

export const list = () => (dispatch) => {
  axios.get(`${API_URL}video`).then(({ data }) => {
    dispatch({
      type: INIT_VIDEOS,
      data,
    });
  });
};

export const getVideo = (videoId, getCallback) => (dispatch) => {
  axios.get(`${API_URL}video/${videoId}`).then(({ data }) => {
    getCallback(data);
    dispatch({
      type: UPDATE_VIDEO,
      data,
    });
  });
};

export const uploadFile = (videoId, video, uploadCallback) => {
  const url = videoId ? `${API_URL}video/${videoId}/file` : `${API_URL}video/file`;
  const method = videoId ? 'put' : 'post';
  axios[method](url, video).then(({ data }) => uploadCallback(data));
};

export const updateData = (videoId, video, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}video/${videoId}`, video).then(({ data }) => {
    updateCallback(data);
    dispatch({
      type: UPDATE_VIDEO,
      data,
    });
  });
};

export const create = (video, createCallback) => (dispatch) => {
  axios.post(`${API_URL}video`, video).then(({ data }) => {
    dispatch({
      type: ADD_VIDEO,
      video: data,
    });
    createCallback();
  });
};

export const remove = (videoId, deleteCallback) => (dispatch) => {
  axios.delete(`${API_URL}video/${videoId}`).then(() => {
    dispatch({
      type: DELETE_VIDEO,
      videoId,
    });
    deleteCallback();
  });
};

export default { list, getVideo, uploadFile, updateData, create, remove };
