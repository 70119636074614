import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Badge } from 'react-bootstrap';
import TeamStatus from 'components/Mission/lib/TeamStatus';
import { GAME_DECKS, SESSION_KEY_TYPE, STATUS } from 'config/constants';
import teamsPT from './PropTypes';

export const teamStatusWasChanged = (current, previous) =>
  !current.find(
    ({ clientId, roles, clients, gameState }) =>
      !previous.find(
        (team) =>
          team.clientId === clientId &&
          team.gameState === gameState &&
          JSON.stringify(team.clients) === JSON.stringify(clients) &&
          team.roles[GAME_DECKS.MAIN.id] === roles[GAME_DECKS.MAIN.id] &&
          team.roles[GAME_DECKS.SUPPORT.id] === roles[GAME_DECKS.SUPPORT.id] &&
          team.roles[GAME_DECKS.COMMANDER.id] === roles[GAME_DECKS.COMMANDER.id],
      ),
  );

const getActives = (missionContol) => {
  const actives = {
    1: '',
    2: '',
    3: '',
    className: '',
  };
  if (missionContol) {
    const isGameExist = STATUS.isGameExist(missionContol.gameState);
    Object.keys(actives).forEach((id) => {
      const exists = missionContol.clients.find(({ position }) => position == id - 1);
      if (exists) {
        if (exists.connected) {
          actives[id] = 'active';
          actives.className = actives.className || 'team-block-active';
        } else {
          actives.className = 'team-block-error';
        }
      }
    });
    // istanbul ignore else
    if (!isGameExist) {
      actives.className = '';
    }
  }
  return actives;
};

const TeamStatusbar = ({ isDynamicSession, fullTeams }) => {
  const teams = useSelector(
    ({ teams }) =>
      teams.map(({ clientId, roles, clients, roomType, key, isReady, isFull, gameState }) => ({
        clientId,
        roles,
        clients,
        roomType,
        key,
        isReady,
        isFull,
        gameState,
      })),
    teamStatusWasChanged,
  );
  let missionContol = null;
  if (isDynamicSession) {
    missionContol = fullTeams.find((team) => team.roomType == SESSION_KEY_TYPE.MISSION_CONTROL);
  }
  const actives = getActives(missionContol);
  return (
    <div className="team-status-bar d-print-none col-7">
      <Row className="align-items-center teams">
        <Col className={`team-block ${actives.className}`}>
          <Row className="team-status team-mc">
            <Badge pill className={`circle ${actives[1]}`}>
              1
            </Badge>
            <Badge pill className={`circle ${actives[2]}`}>
              2
            </Badge>
            <Badge pill className={`circle ${actives[3]}`}>
              3
            </Badge>
          </Row>
        </Col>
        {teams.map((team) => {
          const isGameExist = STATUS.isGameExist(team.gameState);
          const activeClass = isGameExist ? 'team-block-active' : '';
          const errorClass =
            team.clients && !!team.clients.filter((client) => !client.connected).length
              ? 'team-block-error'
              : '';
          return (
            <Col key={team.clientId} className={`team-block ${activeClass} ${errorClass}`}>
              <TeamStatus
                clientId={team.clientId}
                roles={team.roles}
                clients={team.clients || []}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

TeamStatusbar.propTypes = {
  isDynamicSession: PropTypes.bool.isRequired,
  fullTeams: teamsPT.isRequired,
};

export default TeamStatusbar;
