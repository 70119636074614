import axios from 'axios';
import { API_URL } from 'config/constants';
// eslint-disable-next-line import/no-cycle
import security from 'common/security/security';
import { INIT_SESSION_LIST } from 'components/Session/sessionActionTypes';
import { INIT_MISSION_LIST, INIT_TEAMS } from 'components/Mission/missionActionTypes';
import { LOGGED_IN } from 'components/Login/loginActionTypes';

export const login = (state, callback, errorCallback) => (dispatch) => {
  axios
    .post(`${API_URL}auth/login`, state)
    .then(({ data }) => {
      security.login(data.token);
      dispatch({
        type: LOGGED_IN,
        user: data.user,
      });
      callback(data.user);
    })
    .catch(errorCallback);
};

export const logout = (afterAction) => (dispatch) => {
  security.logout();
  dispatch({
    type: LOGGED_IN,
    user: {},
  });
  dispatch({
    type: INIT_SESSION_LIST,
    data: [],
  });
  dispatch({
    type: INIT_MISSION_LIST,
    data: [],
  });
  dispatch({
    type: INIT_TEAMS,
  });
  if (afterAction instanceof Function) {
    afterAction();
  }
};

export const refreshToken = (refreshCallback) => (dispatch) => {
  axios
    .get(`${API_URL}auth/refresh`)
    .then(({ data }) => {
      security.login(data.token);
      dispatch({
        type: LOGGED_IN,
        user: data.user,
      });
      refreshCallback();
    })
    .catch(() => {
      security.logout();
      dispatch({
        type: LOGGED_IN,
        user: {},
      });
      window.location.replace('/');
    });
};

export default {
  login,
  logout,
  refreshToken,
};
