import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/TimeLine/Components/SectionHeader';
import SectionRow from 'components/TimeLine/Components/SectionRow';
import LiftRow from 'components/TimeLine/Components/LiftRow';

const Lift = ({ t, formattedData }) => {
  const maxLength = formattedData.length;
  return (
    <div className="time-line-view-body">
      <SectionHeader t={t} title="_lift_" />
      {Object.keys(formattedData.liftData).map((key) => (
        <SectionRow
          t={t}
          title={key}
          key={`lift-row-${key}`}
          data={formattedData.errorPoints.lift[key]}
        >
          <LiftRow data={formattedData.liftData[key]} maxLength={maxLength} />
        </SectionRow>
      ))}
    </div>
  );
};

Lift.propTypes = {
  t: PropTypes.func.isRequired,
  formattedData: PropTypes.shape({
    length: PropTypes.number,
    liftData: PropTypes.shape({}),
    errorPoints: PropTypes.shape({
      lift: PropTypes.shape({}),
    }),
  }).isRequired,
};
export default Lift;
