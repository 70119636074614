import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PrintLogo from 'components/Scores/PrintLogo';
import PrintTitle from 'components/Scores/PrintTitle';
import TimeLineHeader from 'components/TimeLine/TimeLineHeader';
import Achievements from 'components/TimeLine/Achievements';
import Discoveries from 'components/TimeLine/Discoveries';
import MissionControl from 'components/TimeLine/MissionControl';
import NexusEvents from 'components/TimeLine/NexusEvents';
import OrangeScreen from 'components/TimeLine/OrangeScreen';

const PrintableNexusTimeLine = ({ name, data, user, duration }) => {
  const { t } = useTranslation();
  const stepCount = 20;
  const keys = Object.keys(data).filter((key) => !isNaN(Number(key)));
  duration = duration || keys[keys.length - 1] * 1;
  return (
    <>
      <div>
        <PrintTitle t={t} user={user} name={name} title={t('_discover_nexus_').toUpperCase()} />
        <div className="time-line-view d-none d-print-block">
          <TimeLineHeader t={t} data={data} duration={duration} />
          <Achievements
            data={data}
            t={t}
            step={Math.floor(duration / stepCount)}
            stepCount={stepCount}
          />
          <Discoveries data={data} t={t} />
          <MissionControl
            data={data}
            t={t}
            step={Math.floor(duration / stepCount)}
            stepCount={stepCount}
          />
          <NexusEvents data={data} t={t} duration={duration} />
          <OrangeScreen data={data} />
        </div>
        <PrintLogo t={t} />
        <div className="pagebreak" />
      </div>
    </>
  );
};
PrintableNexusTimeLine.defaultProps = {
  data: {},
  duration: 0,
};
PrintableNexusTimeLine.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  user: PropTypes.shape({
    company: PropTypes.string,
  }).isRequired,
  duration: PropTypes.number,
};
export default PrintableNexusTimeLine;
