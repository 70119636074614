import { TOGGLE_LOADING } from 'components/Loading/loadingActionTypes';

export const initialState = {
  show: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        show: action.show,
      };
    default:
      return state;
  }
}
