import React from 'react';
import { Image, Row, Col, Container } from 'react-bootstrap';
import NineCubesLogo from 'assets/images/logo-ninecubes.png';

export const AuthorizedFooter = () => (
  <Container>
    <Row className="mt-4 auth-footer">
      <Col className="text-right">
        <Image src={NineCubesLogo} className="auth-footer-logo" />
      </Col>
    </Row>
  </Container>
);
export default AuthorizedFooter;
