import PropTypes from 'prop-types';

export const scenarioPT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  __v: PropTypes.number.isRequired,
  _id: PropTypes.string.isRequired,
});

export const scenariosPT = PropTypes.arrayOf(scenarioPT);

export default scenariosPT;
