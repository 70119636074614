import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const colors = {
  0: 'transparent',
  1: '#A4FAA0',
  2: '#51F048',
  3: '#47D141',
  4: '#3AAA35',
};

const NumberRow = ({
  data,
  groupKey,
  cellKey,
  step,
  stepCount,
  colored,
  isCumulative,
  allowNegative,
}) => {
  const getCellValue = useCallback(
    (stepIndex) => {
      const item = data[stepIndex][groupKey];
      return typeof item[cellKey] === 'number' ? item[cellKey] : item[cellKey].value;
    },
    [cellKey, data, groupKey],
  );
  return (
    <>
      {Array(stepCount)
        .fill(step)
        .map(function (i, index) {
          let value = getCellValue((1 + index) * step);
          if (!isCumulative) {
            value -= getCellValue(index * step);
          }
          if (!allowNegative && value < 0) {
            value = 0;
          }
          return (
            <div
              className="time-line-view-body-row-content-number"
              style={{ backgroundColor: colors[colored ? value : 0] }}
              // eslint-disable-next-line react/no-array-index-key
              key={`time-line-view-body-row-content-number-${groupKey}-${cellKey}-${i}-${index}`}
            >
              {value}
            </div>
          );
        })}
    </>
  );
};

NumberRow.defaultProps = {
  colored: false,
  isCumulative: true,
  allowNegative: true,
};

NumberRow.propTypes = {
  data: PropTypes.shape({}).isRequired,
  step: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
  cellKey: PropTypes.string.isRequired,
  groupKey: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  isCumulative: PropTypes.bool,
  allowNegative: PropTypes.bool,
};
export default NumberRow;
