import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import TimeLineTable from 'components/Nexus/TimeLineTable';

const NexusTimeLine = ({ timeLine, duration }) => {
  const { t } = useTranslation();
  return (
    <Container className="d-print-none">
      <TimeLineTable data={timeLine} t={t} duration={duration} />
    </Container>
  );
};

NexusTimeLine.defaultProps = {
  timeLine: {},
  duration: 0,
};

NexusTimeLine.propTypes = {
  timeLine: PropTypes.shape({}),
  duration: PropTypes.number,
};
export default NexusTimeLine;
