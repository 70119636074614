/* eslint-disable react/display-name */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { COLORS, SESSION_KEY_TYPE, GAME_DECKS } from 'config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, Button, Row, Col } from 'react-bootstrap';
import AssignTeamsFooter from 'components/Mission/lib/AssignTeamsFooter';
import Disconnect from 'components/Mission/lib/Disconnect';
import AvailableClients from 'components/Mission/AvailableClients';
import { moveToRoom, disconnectWaitingRoom } from 'components/Mission/missionActions';
import { sessionPT } from 'components/Mission/PropTypes';
import classNames from 'classnames';
import AvailablePositions from 'components/Mission/lib/AvailablePositions/AvailablePositions';

const AssignTeams = ({ t, session, teams, onAssignTeamsSubmit, sendMessage }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showPopoverSend, setShowPopoverSend] = useState(null);
  const [activeTeam, setActiveTeam] = useState({ teamIndex: -1, roleIndex: -1 });
  const [target, setTarget] = useState(null);
  const [targetSend, setTargetSend] = useState(null);
  const [show, setShow] = useState(false);
  const [placement, setPlacement] = useState('right');

  teams = teams.sort((prev, next) => prev.clientId - next.clientId);
  const missionControl = teams.find((team) => team.roomType == SESSION_KEY_TYPE.MISSION_CONTROL);
  const rooms = teams.filter((team) => team.roomType === SESSION_KEY_TYPE.ROOM);
  const waitingLounge = teams.find((team) => team.roomType === SESSION_KEY_TYPE.WAITING_ROOM);
  const handleClick = (event, teamIndex, roleIndex, deckIndex) => {
    let shouldShow = event.currentTarget === target ? !showPopover : true;
    setShowPopover(shouldShow);
    setTarget(event.currentTarget);
    setActiveTeam({ teamIndex, roleIndex, deckIndex });
    setShowPopoverSend(null);
  };

  const handleClickSend = (event, clientId) => {
    let shouldShow = event.currentTarget === targetSend ? !showPopoverSend : true;
    setShowPopoverSend(shouldShow && clientId);
    setTargetSend(event.currentTarget);
    setShowPopover(false);
  };

  const handleClose = () => {
    setShowPopover(false);
    setTarget(null);
    setActiveTeam({ teamIndex: -1, roleIndex: -1, deckIndex: -1 });
  };

  const handleCloseSend = () => {
    setShowPopoverSend(null);
  };

  const onClientClick = (client) => {
    moveToRoom(
      session._id,
      client.id,
      activeTeam.teamIndex,
      activeTeam.deckIndex,
      activeTeam.roleIndex,
    );
    handleCloseSend();
    handleClose();
  };
  const getArray = (length) => Array.from(Array(length), (_, index) => index);
  const sendClientToRoom = (id, room, role, position) => {
    moveToRoom(session._id, id, room, role, position);
    handleCloseSend();
    handleClose();
    setPlacement('right');
  };
  return (
    <>
      <Container className="assign-team-container">
        <Row className="teams-list">
          <Col sm={9} xs={12}>
            <Row>
              <Col>
                <Row className="team-max-width">
                  <Col xs={2} />
                  {teams.map((team) =>
                    team.roomType == SESSION_KEY_TYPE.ROOM ? (
                      <Col className="pr-0" key={`team-head-${team.clientId}`}>
                        <div
                          className="teams-members"
                          style={{ backgroundColor: COLORS[`team_${team.clientId}`] }}
                        >
                          <span>
                            {t('_team_')} {team.displayClientId || team.clientId}
                          </span>
                        </div>
                      </Col>
                    ) : null,
                  )}
                </Row>
              </Col>
            </Row>
            {Object.values(GAME_DECKS).map((deck) => (
              <Row className="border-collapse" key={`cockpit-${deck.id}`}>
                <Col className="open-teams custom-border">
                  <Row className="team-max-width">
                    <Col xs={2} className="deck-name">
                      <div>{t(deck.name)}</div>
                      <div>
                        <span>1</span>
                        <span>2</span>
                      </div>
                    </Col>
                    {teams.map((team) =>
                      team.roomType == SESSION_KEY_TYPE.ROOM ? (
                        <Col className="pr-0 wrapper12" key={`team-body-${team.clientId}`}>
                          {getArray(2).map((rowKey, rowIndex) => {
                            const clients = team.clients.filter((client) => {
                              if (client && client.role == deck.id) {
                                return client;
                              }
                            });
                            const client = clients.find((client) => client.position == rowIndex);
                            return (
                              <Row
                                className="border-collapse"
                                key={`team-body-${team.clientId}-${rowKey}`}
                              >
                                <Col>
                                  <div
                                    className={classNames('jumbotron-column', 'custom-border', {
                                      'bg-white': client,
                                    })}
                                  >
                                    {client ? (
                                      <>
                                        <span title={client.name} className="client-name">
                                          {client.name}
                                        </span>
                                        <Button
                                          variant="dark"
                                          className="get-button ml-auto"
                                          onClick={(event) => {
                                            setPlacement('right');
                                            handleClickSend(event, client.id);
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faArrowRight} />
                                        </Button>
                                        <AvailablePositions
                                          rooms={rooms}
                                          waitingLounge={waitingLounge}
                                          missionControl={missionControl}
                                          showPopoverSend={showPopoverSend}
                                          targetSend={targetSend}
                                          handleCloseSend={handleCloseSend}
                                          sendClientToRoom={sendClientToRoom}
                                          id={client.id}
                                          t={t}
                                          placement={placement}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          variant="outline-dark"
                                          className="get-button ml-auto"
                                          onClick={(event) => {
                                            setPlacement('right');
                                            handleClick(event, team.key, rowIndex, deck.id);
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faArrowLeft} />
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </Col>
                      ) : null,
                    )}
                  </Row>
                </Col>
              </Row>
            ))}
          </Col>
          <Col sm={2}>
            <Row className="border-collapse">
              <Col sm={2} />
              <Col className="pr-0 mission-control-members">
                <span>{` ${t('_mission_control_')}`}</span>
              </Col>
            </Row>
            {getArray(3).map((deckKey, deckIndex) => {
              const client =
                missionControl &&
                missionControl.clients.find((client) => client.position == deckIndex);
              return (
                <Row className="open-teams border-collapse custom-border" key={deckKey}>
                  <Col sm={2} className="mission-control-row-number border-right">
                    {deckIndex + 1}
                  </Col>
                  <Col
                    className={classNames('jumbotron-column', 'custom-border', {
                      'bg-white': client,
                    })}
                  >
                    {client ? (
                      <>
                        <span title={client.name} className="client-name">
                          {client.name}
                        </span>
                        <Button
                          variant="dark"
                          className="get-button ml-auto"
                          onClick={(event) => {
                            setPlacement('left');
                            handleClickSend(event, client.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                        <AvailablePositions
                          rooms={rooms}
                          waitingLounge={waitingLounge}
                          missionControl={missionControl}
                          showPopoverSend={showPopoverSend}
                          targetSend={targetSend}
                          handleCloseSend={handleCloseSend}
                          sendClientToRoom={sendClientToRoom}
                          id={client.id}
                          t={t}
                          placement={placement}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          variant="outline-dark"
                          className="get-button ml-auto"
                          onClick={(event) => {
                            setPlacement('left');
                            handleClick(event, missionControl.key, deckIndex, 3);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </Container>
      {showPopover && (
        <AvailableClients
          onClientClick={onClientClick}
          showPopover={showPopover}
          handleClose={handleClose}
          target={target}
          teams={teams}
          placement={placement}
          t={t}
        />
      )}
      <Disconnect
        show={show}
        onHide={() => setShow(false)}
        disconnectText="_disconnect_"
        onDisconnectClick={() => {
          setShow(false);
          disconnectWaitingRoom(session._id);
        }}
        t={t}
      />
      <AssignTeamsFooter
        t={t}
        sessionId={session._id}
        teams={teams}
        onAssignTeamsSubmit={onAssignTeamsSubmit}
        sendMessage={sendMessage}
        isDisconnectActive={
          teams.filter(
            (team) => team.roomType == SESSION_KEY_TYPE.WAITING_ROOM && team.clients.length > 0,
          ).length > 0
        }
        disconnect={() => setShow(true)}
      />
    </>
  );
};

AssignTeams.propTypes = {
  t: PropTypes.func.isRequired,
  session: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
    }),
    sessionPT,
  ]).isRequired,
  teams: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  onAssignTeamsSubmit: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
};

export default AssignTeams;
