import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/TimeLine/Components/SectionHeader';
import SectionRow from 'components/TimeLine/Components/SectionRow';
import triangle from 'assets/icons/triangle_bordered.png';
import circle from 'assets/icons/circle_bordered.png';
import square from 'assets/icons/square_bordered.png';
import { TAGS } from 'config/constants';
import LogEventRow from 'components/TimeLine/Components/LogEventRow';

const LOG = {
  fill: '#FFED00',
  noFill: 'transparent',
};

const NexusEvents = ({ t, data, duration }) => {
  const tags = data[duration].events.tags;
  const logEvents = data[duration].events.logEvents;
  const eventData = data.eventData;
  const getImage = (id) => {
    let image = null;
    switch (id) {
      case TAGS.TRIANGLE:
        image = triangle;
        break;
      case TAGS.CIRCLE:
        image = circle;
        break;
      case TAGS.SQUARE:
        image = square;
        break;
    }
    return image;
  };
  return (
    <div className="time-line-view-body">
      <SectionHeader t={t} title="_events_" />
      <SectionRow t={t} title="_observation_tags_">
        {tags.map((tag) => (
          <div
            className="time-line-view-body-row-content-tag"
            key={`tag-${tag.eventTime}-tag.eventData.tagId`}
            style={{
              left: `calc(${(tag.eventTime / duration) * 100}% - 10px)`,
            }}
          >
            <img alt="" src={getImage(tag.eventData.tagId)} />
          </div>
        ))}
      </SectionRow>
      {logEvents.map(({ name, important }) => (
        <SectionRow t={t} title={name} key={name} color={important ? LOG.fill : LOG.noFill}>
          <LogEventRow data={eventData[name]} duration={duration} />
        </SectionRow>
      ))}
    </div>
  );
};

NexusEvents.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({
    eventData: PropTypes.shape({}),
  }).isRequired,
  duration: PropTypes.number.isRequired,
};
export default NexusEvents;
