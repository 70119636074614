import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import List from 'components/Resource/List';
import Edit from 'components/Resource/Edit';
import { matchPT, locationPT } from 'routes/PropTypes';
import { ROUTES } from 'config/constants';

const ResourceRoute = ({ match, location }) => (
  <Switch>
    <Route path={match.path} exact component={List} />
    <Route path={`${match.path}/edit/:resourceId`} exact component={Edit} />
    <Redirect to={{ pathname: ROUTES.RESOURCES, state: { from: location } }} />;
  </Switch>
);

ResourceRoute.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default ResourceRoute;
