/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { clientsPT } from 'components/Client/PropTypes';
import SortableTable from 'components/SortableTable/SortableTable';
import { list } from 'components/Client/clientAction';
import { useNavbar } from 'common/components';

export const correctRows = (t) => [
  {
    Header: t('_name_'),
    sortable: true,
    size: 6,
    accessor: (client) => t(client.name),
  },
  {
    Header: t('_info_'),
    sortable: true,
    size: 4,
    accessor: (client) => client.info,
  },
  {
    Header: t('_status_'),
    sortable: false,
    size: 1,
    center: true,
    accessor: (client) => (
      <p className={`circle-point ${!client.exist ? 'inactive' : ''}`} title={t('_status_')} />
    ),
  },
  {
    Header: t('_edit_'),
    sortable: false,
    size: 1,
    center: true,
    accessor: (client) => (
      <Link to={`/client/edit/${client._id}`}>
        <Button variant="outline-secondary" size="sm">
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Link>
    ),
  },
];
export const List = ({ clients }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(list());
  }, [dispatch]);
  return (
    <Container>
      {useNavbar('_clients_')}
      <div className="client-page mt-2 mb-2">
        <Nav id="client-table" className="navbar navbar-light bg-light">
          <Nav.Item className="navbar-brand">{t('_available_client_for_download_')}</Nav.Item>
        </Nav>
        <div data-spy="scroll" data-target="#client-table" data-offset="0">
          <SortableTable
            columns={correctRows(t)}
            data={clients}
            additionalClass="client-page-table"
          />
        </div>
      </div>
    </Container>
  );
};

List.propTypes = {
  clients: clientsPT.isRequired,
};

const mapStateToProps = (state) => ({ clients: state.clients });

export default connect(mapStateToProps, null)(List);
