import axios from 'axios';
import { API_URL } from 'config/constants';
import {
  INIT_USER_LIST,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
} from 'components/User/userActionTypes';

export const list = () => (dispatch) => {
  axios.get(`${API_URL}users`).then(({ data }) => {
    dispatch({
      type: INIT_USER_LIST,
      data: data.sort((prev, next) => parseInt(prev.id) - parseInt(next.id)),
    });
  });
};

export const get = (userId, getCallback) => {
  axios.get(`${API_URL}users/${userId}`).then(({ data }) => getCallback(data));
};

export const create = (state, createCallback) => (dispatch) => {
  axios.post(`${API_URL}users`, state).then(({ data }) => {
    dispatch({
      type: ADD_USER,
      user: data,
    });
    createCallback();
  });
};
export const update = (userId, state, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}users/${userId}`, state).then(({ data }) => {
    dispatch({
      type: UPDATE_USER,
      user: data,
    });
    updateCallback();
  });
};
export const remove = (userId, deleteCallback) => (dispatch) => {
  axios.delete(`${API_URL}users/${userId}`).then(() => {
    dispatch({
      type: DELETE_USER,
      userId,
    });
    deleteCallback();
  });
};

export const exportData = (callback) => {
  axios.get(`${API_URL}users/export`).then(({ data }) => callback(data));
};

export const checkUsernameExistance = (data, callback) => {
  axios
    .get(`${API_URL}users/exists/${data.username}${data.userId ? `/${data.userId}` : ''}`)
    .then(callback);
};

export const updateKey = (data, callback, errCallback) => {
  axios
    .post(`${API_URL}users/${data.userId}/key/${data.key}`, data)
    .then(({ data }) => callback(data))
    .catch(errCallback);
};

export const serverInfo = (callback) => {
  axios.get(`${API_URL}info/`).then(({ data }) => callback(data));
};

export const getSettingsData = (callback) => {
  axios.get(`${API_URL}settings/data/`).then(({ data }) => callback(data));
};

export const getLanguagesData = (callback) => {
  axios.get(`${API_URL}languages/`).then(({ data }) => callback(data));
};

export const importUsers = (users, uploadCallback) => {
  axios.put(`${API_URL}users/import`, users).then(({ data }) => uploadCallback(data));
};

export default {
  list,
  get,
  create,
  update,
  remove,
  exportData,
  checkUsernameExistance,
  updateKey,
  serverInfo,
  getSettingsData,
  importUsers,
};
