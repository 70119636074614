import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Unit from 'components/Unit/Unit';
import { matchPT, locationPT } from 'routes/PropTypes';
import { ROUTES } from 'config/constants';

const UnitComponent = ({ match, location }) => (
  <Switch>
    <Route path={match.path} exact component={Unit} />
    <Redirect to={{ pathname: ROUTES.UNITS, state: { from: location } }} />;
  </Switch>
);

UnitComponent.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default UnitComponent;
