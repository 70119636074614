import axios from 'axios';
import { API_URL } from 'config/constants';
import { INIT_SETTINGS } from 'components/Settings/settingsActionTypes';

export const update = (state, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}settings`, state).then(({ data }) => {
    dispatch({
      type: INIT_SETTINGS,
      server: data,
    });
    updateCallback();
  });
};

export const getSettingsData = (callback) => {
  axios.get(`${API_URL}settings/data/`).then(({ data }) => callback(data));
};

export const deleteAll = (url, deleteCallback) => {
  axios.delete(`${API_URL}settings/${url}`).then(deleteCallback);
};

export const changeTimeZone = (time, callback) => {
  axios.post(`${API_URL}settings/time-zone/`, { time }).then(({ data }) => callback(data));
};

export default { update, getSettingsData, deleteAll, changeTimeZone };
