import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import List from 'components/AdminMission/List';
import AdminMission from 'components/AdminMission/AdminMission';
import { matchPT, locationPT } from 'routes/PropTypes';
import { ROUTES } from 'config/constants';

const AdminMissionRoute = ({ match, location }) => (
  <Switch>
    <Route path={`${match.path}/new`} exact component={AdminMission} />
    <Route path={`${match.path}/edit/:missionId`} exact component={AdminMission} />
    <Route path={match.path} exact component={List} />
    <Redirect to={{ pathname: ROUTES.ADMIN_MISSION, state: { from: location } }} />;
  </Switch>
);

AdminMissionRoute.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default AdminMissionRoute;
