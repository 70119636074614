import { diff } from 'deep-object-diff';
import { NUMBER_EXCLUDES } from 'config/constants';
import i18n from 'i18next';

export const toTime = (value) => {
  let seconds = value || 0,
    hours = Math.floor(seconds / 3600),
    minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  if (hours) {
    hours += ':';
  } else {
    hours = '';
  }
  return `${hours + minutes}:${seconds}`;
};

export const timeToSeconds = (value) => {
  if ('number' === typeof value) {
    return value;
  }
  let duration = 0,
    indexes = [1, 60, 3600],
    data = value.split(':');
  for (let i = data.length - 1, j = 0; i >= 0; i--, j++) {
    duration = duration + data[i] * indexes[j];
  }
  if (data.length === 1) {
    duration = data[0] * 60;
  }
  return duration;
};

export const resetHistory = (history) => {
  history.length = 0;
  history.replace('/');
};

export const goBack = (history) => {
  if (history.length > 1) {
    return history.goBack();
  }
  resetHistory(history);
};

export const areObjectsDifferent = (oldObject, newObject) => {
  const difference = diff(oldObject, newObject);
  return Object.keys(difference).length > 0;
};

export const excludeLetters = (e) => {
  const keyIndex = NUMBER_EXCLUDES.indexOf(e.keyCode);
  if (keyIndex !== -1) {
    e.preventDefault();
  }
  return keyIndex === -1;
};
export const orderDataByKey = (data, key = 'name') =>
  data.sort((a, b) => a[key].localeCompare(b[key]));

export const translateAndOrder = (data, key = 'name') =>
  data.sort((a, b) => i18n.t(a[key]).localeCompare(i18n.t(b[key])));

export const fillZero = (number, length = 2) => {
  const n = Math.abs(number);
  const zeros = Math.max(0, length - Math.floor(n).toString().length);
  let zeroString = Math.pow(10, zeros).toString().substr(1);
  if (number < 0) {
    zeroString = `-${zeroString}`;
  }
  return zeroString + n;
};

export const roundNumber = (number) => Math.round(number / 10) * 10;

export default {
  toTime,
  resetHistory,
  timeToSeconds,
  areObjectsDifferent,
  goBack,
  excludeLetters,
  orderDataByKey,
  fillZero,
  translateAndOrder,
  roundNumber,
};
