import axios from 'axios';
import { API_URL } from 'config/constants';
import { CLIENT_LIST, UPDATE_CLIENT } from 'components/Client/clientActionTypes';
import { translateAndOrder } from 'utils/common';

export const list = () => (dispatch) => {
  axios.get(`${API_URL}client`).then(({ data }) => {
    dispatch({
      type: CLIENT_LIST,
      data: translateAndOrder(data),
    });
  });
};

export const getClient = (clientId, getCallback) => (dispatch) => {
  axios.get(`${API_URL}client/${clientId}`).then(({ data }) => {
    getCallback(data);
    dispatch({
      type: UPDATE_CLIENT,
      data,
    });
  });
};

export const uploadFile = (clientId, client, uploadCallback) => {
  axios.put(`${API_URL}client/${clientId}/file`, client).then(({ data }) => {
    uploadCallback(data);
  });
};

export const updateData = (clientId, client, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}client/${clientId}`, client).then(({ data }) => {
    updateCallback(data);
    dispatch({
      type: UPDATE_CLIENT,
      data,
    });
  });
};

export const deleteClient = (clientId, deleteCallback) => (dispatch) => {
  axios.delete(`${API_URL}client/${clientId}`).then(({ data }) => {
    deleteCallback(data);
    dispatch({
      type: UPDATE_CLIENT,
      data,
    });
  });
};

export default { list, getClient, uploadFile, updateData, deleteClient };
