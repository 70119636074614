import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import Video from 'components/Mission/lib/Video';
import Disconnect from 'components/Mission/lib/Disconnect';
import Totals from 'components/Mission/lib/Totals';
import Shape from 'components/Mission/lib/Shape/Shape';
import Teams from 'components/Mission/lib/Teams';
import { missionPT } from 'components/Mission/PropTypes';
import TimeLine from 'components/Mission/lib/TimeLine/TimeLine';
import { NEXUS_CLIENT_ID } from 'config/constants';

const ObserveSimulation = ({
  t,
  isVideo,
  videoTeams,
  currentMission,
  abortSession,
  switchGame,
  currentGame,
  addTag,
}) => {
  const [show, setShow] = useState(false);
  const [disconnectId, setDisconnectId] = useState(0);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);
  return (
    <>
      {isVideo ? (
        <Video t={t} videoTeams={videoTeams} />
      ) : (
        <Teams
          t={t}
          isNexus={currentMission.missionType == NEXUS_CLIENT_ID}
          switchGame={switchGame}
          disconnectTeam={(id) => {
            setShowDisconnect(true);
            setDisconnectId(id);
          }}
        />
      )}
      {!isVideo && (
        <Row className="mt-1">
          <Col xs={9}>
            <Totals />
          </Col>
          <Col xs={3} className="d-flex justify-content-end">
            <Shape addTag={(tag) => addTag(currentGame, tag)} currentGame={currentGame} />
          </Col>
        </Row>
      )}
      <Row className="my-2">
        <Col>
          <TimeLine duration={currentMission.duration || 0} currentGame={currentGame} />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col xs={9}>
          <Button variant="danger" className="col-3" onClick={handleOpen}>
            <FontAwesomeIcon icon={faTimesCircle} />
            {` ${t('_abort_')}`}
          </Button>
        </Col>
      </Row>
      <Disconnect
        show={showDisconnect}
        disconnectText="_abort_team_"
        onHide={() => setShowDisconnect(false)}
        onDisconnectClick={() => {
          setShowDisconnect(false);
          abortSession(disconnectId);
        }}
        t={t}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> {t('_delete_video_msg_')} </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="danger"
            className="col-6"
            onClick={() => {
              handleClose();
              abortSession();
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
            {` ${t('_abort_mission_')}`}
          </Button>
          <Button variant="success" className="col-4" onClick={handleClose}>
            {`${t('_continue_')} `}
            <FontAwesomeIcon icon={faPlayCircle} />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ObserveSimulation.propTypes = {
  isVideo: PropTypes.bool.isRequired,
  videoTeams: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  currentMission: PropTypes.oneOfType([PropTypes.shape({}), missionPT]).isRequired,
  abortSession: PropTypes.func.isRequired,
  switchGame: PropTypes.func.isRequired,
  currentGame: PropTypes.oneOf(PropTypes.number, NEXUS_CLIENT_ID).isRequired,
  addTag: PropTypes.func.isRequired,
};
export default ObserveSimulation;
