import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Form,
  Row,
  Col,
  ListGroup,
  InputGroup,
  Button,
  ButtonGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlug, faUndo, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import validator from 'utils/validator';
import { useNavbar } from 'common/components';
import { MESSAGE_STATE, SESSION_KEYS } from 'config/constants';
import {
  update,
  getSettingsData,
  deleteAll,
  changeTimeZone,
} from 'components/Server/serverActions';
import { goBack } from 'utils/common';
import { INIT_USER_LIST } from 'components/User/userActionTypes';
import { INIT_VIDEOS } from 'components/Video/videoActionTypes';
import { INIT_ADMIN_MISSION_LIST } from 'components/AdminMission/adminMissionActionTypes';
import Notifier from 'components/Message/Notifier';
import DeleteComponent from 'common/DeleteComponent/DeleteComponent';
import { shutDown, restart } from 'common/deviceApi/deviceApi';
import { serverInfo } from 'components/User/userActions';

export const Server = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [key, setKey] = useState('');
  const [log, setԼog] = useState(null);
  const [message, setMessage] = useState({ state: MESSAGE_STATE.OFF });
  const [serverTime, setServerTime] = useState(null);
  const [diskSize, setDiskSize] = useState(0);
  const [showUser, setShowUser] = useState(false);
  const [showMission, setShowMission] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showRestart, setShowRestart] = useState(false);
  const [showPower, setShowPower] = useState(false);
  const [, setServer] = useState(null);

  const server = {
    message,
    key,
    log,
  };

  const changeMessage = useCallback(
    ({ name, value }) => setMessage({ ...message, [name]: value }),
    [message],
  );

  const isServerFormValid = validator.isServerFormValid(server);

  const handleCreate = useCallback(
    (event) => {
      event.preventDefault();
      if (isServerFormValid) {
        dispatch(update(server, () => {}));
        goBack(history);
      }
    },
    [isServerFormValid, dispatch, server, history],
  );

  const deleteAllLicensees = useCallback(() => {
    deleteAll('licensees', () => {
      dispatch({
        type: INIT_USER_LIST,
        data: [],
      });
      setShowUser(false);
      Notifier.success('_licensee_list_deleted_successfully_');
    });
  }, [dispatch]);

  const deleteAllMissions = useCallback(() => {
    deleteAll('missions', () => {
      dispatch({
        type: INIT_ADMIN_MISSION_LIST,
        data: [],
      });
      setShowMission(false);
      Notifier.success('_mission_list_deleted_successfully_');
    });
  }, [dispatch]);

  const deleteAllVideos = useCallback(() => {
    deleteAll('videos', () => {
      dispatch({
        type: INIT_VIDEOS,
        data: [],
      });
      setShowVideo(false);
      Notifier.success('_video_list_deleted_successfully_');
    });
  }, [dispatch]);

  const changeTime = useCallback(() => {
    const date = new Date();
    changeTimeZone(date.toString(), ({ serverTime }) => {
      setServerTime(serverTime);
      Notifier.success('_server_datetime_has_configured_successfully_');
    });
  }, []);

  const restartMachine = useCallback(() => {
    restart(
      () => {
        setShowRestart(false);
      },
      () => {
        setShowRestart(false);
      },
    );
  }, []);
  const powerOffMachine = useCallback(() => {
    shutDown(
      () => {
        setShowPower(false);
      },
      () => {
        setShowPower(false);
      },
    );
  }, []);

  useEffect(() => {
    getSettingsData((settingsData) => {
      settingsData.message && setMessage(settingsData.message);
      setԼog(settingsData.log);
      setKey(settingsData.key);
      setServerTime(settingsData.serverTime);
      setDiskSize(settingsData.diskSize);
    });
  }, []);
  useEffect(() => {
    serverInfo(setServer);
  }, []);

  const isLocalServer = useMemo(() => server && server.mode == '_local_server_', [server]);
  const navBarText = 'SERVER CONFIGURATION';
  return (
    <Container>
      {useNavbar(navBarText)}
      <Form method="POST" onSubmit={handleCreate} className="mt-5">
        <Form.Group as={Row} controlId="formLoginMessage">
          <Form.Label column xs={{ span: 3, offset: 1 }}>
            <strong>{t('_global_login_message_')}</strong>
          </Form.Label>
          <Col md={8} xs={6}>
            <Row>
              <Col md={7} xs={12}>
                <Form.Group as={Row} controlId="formLoginMessageOptions">
                  <Col md={12} sm={6} xs={10} className="justify-content-end">
                    <ListGroup>
                      <ListGroup.Item className="user-list-group-item">
                        <InputGroup.Prepend>
                          <InputGroup.Radio
                            name="loginMessageGroup"
                            checked={message.state == MESSAGE_STATE.OFF}
                            onChange={() =>
                              changeMessage({ name: 'state', value: MESSAGE_STATE.OFF })
                            }
                          />
                        </InputGroup.Prepend>
                        <span>{t('_off_')}</span>
                      </ListGroup.Item>
                      <ListGroup.Item className="user-list-group-item">
                        <InputGroup.Prepend>
                          <InputGroup.Radio
                            name="loginMessageGroup"
                            checked={message.state == MESSAGE_STATE.TRAINER}
                            onChange={() =>
                              changeMessage({ name: 'state', value: MESSAGE_STATE.TRAINER })
                            }
                          />
                        </InputGroup.Prepend>
                        <span>{t('_trainer_only_')}</span>
                      </ListGroup.Item>
                      <ListGroup.Item className="user-list-group-item">
                        <InputGroup.Prepend>
                          <InputGroup.Radio
                            name="loginMessageGroup"
                            checked={message.state == MESSAGE_STATE.TRAINER_AND_GAME}
                            onChange={() =>
                              changeMessage({
                                name: 'state',
                                value: MESSAGE_STATE.TRAINER_AND_GAME,
                              })
                            }
                          />
                        </InputGroup.Prepend>
                        <span>{t('_trainer_and_game_clients_')}</span>
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={8} xs={12}>
                <Form.Group as={Row} controlId="formMessageTitle">
                  <Form.Label column md={3} xs={7} className="message-title">
                    {t('_message_title_')}
                  </Form.Label>
                  <Col md={8} sm={8} xs={10}>
                    <Form.Control
                      value={message.title || ''}
                      name="title"
                      onChange={({ target }) =>
                        changeMessage({
                          name: target.name,
                          value: target.value.trimLeft(),
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formMessageText">
                  <Form.Label column md={3} xs={7} className="message-title">
                    {t('_message_text_')}
                  </Form.Label>
                  <Col md={8} sm={8} xs={10}>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      value={message.text || ''}
                      name="text"
                      onChange={({ target }) =>
                        changeMessage({
                          name: target.name,
                          value: target.value.trimLeft(),
                        })
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Form.Group>
        <hr className="dotted-line" />
        <Form.Group as={Row}>
          <Form.Label as="legend" column xs={{ span: 3, offset: 1 }}>
            <strong>{t('_session_key_length_')}</strong>
          </Form.Label>
          <Col xs={{ span: 4, offset: 0 }}>
            <ButtonGroup>
              {Object.keys(SESSION_KEYS).map((sessionKey) => (
                <Button
                  variant="light"
                  active={sessionKey == key}
                  key={`session-key-${sessionKey}`}
                  className="server-key-length"
                  onClick={() => setKey(sessionKey)}
                >
                  {sessionKey}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formLog">
          <Form.Label column xs={{ span: 3, offset: 1 }}>
            <strong>{t('_size_of_log_file_')}</strong>
          </Form.Label>
          <Col xs={4} md={2}>
            {log != null && (
              <Form.Control
                value={log}
                placeholder={t('_enter_log_file_size_')}
                type="number"
                name="log"
                {...validator.validate('log', log)}
                onChange={(e) => setԼog(e.target.value)}
                required
              />
            )}
            <Form.Control.Feedback type="invalid">{t('_log_size_is_small_')}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <hr className="dotted-line" />
        <Form.Group as={Row}>
          <Form.Label as="legend" column xs={{ span: 3, offset: 1 }}>
            <strong>{t('_server_date_time_')}</strong>
          </Form.Label>
          <Col xs={{ span: 3, offset: 0 }}>
            <Form.Control value={`${serverTime} (UTC)`} disabled />
          </Col>
          <Col xs={{ span: 5, offset: 0 }}>
            <Button variant="light" onClick={changeTime}>
              {t('_set_date_time_')}
            </Button>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label as="legend" column xs={{ span: 3, offset: 1 }}>
            <strong>{t('_available_disk_space_')}</strong>
          </Form.Label>
          <Col xs={{ span: 3, offset: 0 }}>
            <Form.Control value={`${diskSize} MB`} style={{ fontWeight: '500' }} disabled />
          </Col>
        </Form.Group>
        <hr className="dotted-line" />
        <Form.Group as={Row}>
          <Form.Label as="legend" column xs={{ span: 3, offset: 1 }}>
            <strong>{t('_delete_')}</strong>
          </Form.Label>
          <Col>
            <Button variant="light" className="mr-2" onClick={() => setShowUser(true)}>
              <FontAwesomeIcon icon={faTrashAlt} className="mr-1" />
              {` ${t('_all_licensees_')}`}
            </Button>
            <Button variant="light" className="mr-2" onClick={() => setShowMission(true)}>
              <FontAwesomeIcon icon={faTrashAlt} className="mr-1" />
              {` ${t('_all_missions_')}`}
            </Button>
            <Button variant="light" className="mr-2" onClick={() => setShowVideo(true)}>
              <FontAwesomeIcon icon={faTrashAlt} className="mr-1" />
              {` ${t('_all_videos_')}`}
            </Button>
          </Col>
        </Form.Group>
        <hr className="dotted-line" />
        <Row>
          <Col className="d-flex justify-content-start">
            {isLocalServer && (
              <Row>
                <Col>
                  <Button
                    variant="danger"
                    type="button"
                    className="settings-action-button"
                    onClick={() => setShowPower(true)}
                  >
                    <FontAwesomeIcon icon={faPlug} className="mr-1" />
                    {`${t('_power_off_btn_text_')}`}
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="danger"
                    type="button"
                    className="settings-action-button"
                    onClick={() => setShowRestart(true)}
                  >
                    <FontAwesomeIcon icon={faUndo} className="mr-1" />
                    {`${t('_restart_btn_text_')}`}
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
          <Col className="text-right">
            <Button
              className="action-button"
              variant="success"
              type="submit"
              disabled={!isServerFormValid}
            >
              <FontAwesomeIcon icon={faCheck} />
              {` ${t('_save_settings_')}`}
            </Button>
          </Col>
        </Row>
      </Form>
      <DeleteComponent
        handleClose={() => setShowUser(false)}
        show={showUser}
        handleDelete={deleteAllLicensees}
        title="_delete_user_message_"
        keepText="_keep_user_"
        deleteText="_delete_user_"
      />
      <DeleteComponent
        handleClose={() => setShowMission(false)}
        show={showMission}
        handleDelete={deleteAllMissions}
        title="_delete_mission_msg_"
        keepText="_keep_mission_"
        deleteText="_delete_mission_"
      />
      <DeleteComponent
        handleClose={() => setShowVideo(false)}
        show={showVideo}
        handleDelete={deleteAllVideos}
        title="_delete_video_msg_"
        keepText="_keep_video_"
        deleteText="_delete_video_"
      />
      <DeleteComponent
        handleClose={() => setShowRestart(false)}
        show={showRestart}
        handleDelete={restartMachine}
        title="_delete_user_message_"
        keepText="_continue_"
        deleteText="_restart_btn_text_"
      />
      <DeleteComponent
        handleClose={() => setShowPower(false)}
        show={showPower}
        handleDelete={powerOffMachine}
        title="_delete_user_message_"
        keepText="_continue_"
        deleteText="_power_off_btn_text_"
      />
    </Container>
  );
};

export default Server;
