import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/TimeLine/Components/SectionHeader';
import SectionRow from 'components/TimeLine/Components/SectionRow';
import NumberRow from 'components/TimeLine/Components/NumberRow';
import AssesmentRow from './Components/AssesmentRow';

const collectAssesment = (data) => {
  const dataMassive = Object.values(data);
  const dataMap = dataMassive.reduce((acc, item, index) => {
    const nextItem = dataMassive[index + 1];
    if (
      item.control &&
      nextItem &&
      nextItem.control &&
      Object.keys(item.control.situation).length
    ) {
      const { situation } = item.control;
      const { situation: next } = nextItem.control;
      acc[situation.time] = {
        value: situation.state,
        start: situation.time,
        end: next.time,
      };
    }
    return acc;
  }, {});
  const result = Object.values(dataMap);
  const last = result[result.length - 1];
  if (last) {
    result[result.length - 1].end = dataMassive.length;
  }
  return result;
};

const MissionControl = ({ t, data, step, stepCount }) => (
  <div className="time-line-view-body">
    <SectionHeader t={t} title="_mission_control_" />
    <SectionRow t={t} title="_number_of_orders_">
      <NumberRow
        step={step}
        stepCount={stepCount}
        data={data}
        cellKey="orders"
        groupKey="control"
        isCumulative={false}
        allowNegative={false}
      />
    </SectionRow>
    <SectionRow t={t} title="_assessment_of_situation_">
      <AssesmentRow data={collectAssesment(data)} length={Object.values(data).length} />
    </SectionRow>
  </div>
);

MissionControl.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  step: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
};
export default MissionControl;
