import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import InfoBar from 'common/UploadFile/InfoBar';
import UploadBtn from 'common/UploadBtn/UploadBtn';

const UploadFile = ({ data, t, changeInfo, onDrop, accept, infoText }) => (
  <>
    <InfoBar name={data.name} info={data.info} t={t} changeInfo={changeInfo} infoText={infoText} />
    <Form.Group as={Row} className="my-4">
      <Form.Label column xs={{ span: 2, offset: 2 }}>
        <strong>{t('_file_')}</strong>
      </Form.Label>
      <Col xs={2}>
        <UploadBtn t={t} accept={accept} onDrop={onDrop} />
      </Col>
    </Form.Group>
  </>
);

UploadFile.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  infoText: PropTypes.string.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  changeInfo: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
};
export default UploadFile;
