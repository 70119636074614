import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavbar } from 'common/components';
import { get, remove, getMeta, removeData } from 'components/Session/sessionActions';
import { resetHistory } from 'utils/common';
import Notifier from 'components/Message/Notifier';

const SessionDelete = () => {
  let { sessionId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState('');
  const [sessionHistory, setSessionHistory] = useState({ count: 0, _id: {} });
  const goToSession = useCallback(() => {
    resetHistory(history);
  }, [history]);
  useEffect(() => {
    if (sessionId) {
      get(sessionId, (session) => {
        setName(session.name);
        getMeta(sessionId, setSessionHistory);
      });
    }
  }, [sessionId]);
  const deleteSession = useCallback(() => {
    dispatch(
      remove(sessionId, () => {
        Notifier.success('_session_successfully_removed_');
        goToSession();
      }),
    );
  }, [dispatch, goToSession, sessionId]);
  const removeDataHandler = useCallback(() => {
    removeData(sessionId, () => {
      Notifier.success('_session_data_successfully_removed_');
      goToSession();
    });
  }, [goToSession, sessionId]);
  return (
    <Container>
      {useNavbar(`${t('_configure_session_')} | ${name}`)}
      <Row className="session-page-delete">
        <Col>
          <h2 className="mt-4 text-center">{t('_what_you_want_to_delete_')}</h2>
          <Row>
            <Col className="text-center">
              <Button
                variant="danger"
                size="lg"
                className="col-3 mt-5"
                id="deleteHistory"
                disabled={!sessionHistory.count}
                onClick={removeDataHandler}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
                {` ${t('_delete_data_')}`}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                variant="danger"
                size="lg"
                id="deleteSession"
                className="col-3 mt-5"
                onClick={deleteSession}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
                {` ${t('_delete_session_')}`}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SessionDelete;
