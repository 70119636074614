import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Sessions from 'components/Session/List';
import Session from 'components/Session/Session';
import Missions from 'components/Mission/Mission';
import SessionDelete from 'components/Session/SessionDelete';
import SessionHistory from 'components/Session/SessionHistory';
import Scores from 'components/Scores/Scores';
import { matchPT, locationPT } from 'routes/PropTypes';
import { ROUTES } from 'config/constants';

const SessionComponent = ({ match, location }) => (
  <Switch>
    <Route path={`${match.path}/new`} exact component={Session} />
    <Route path={`${match.path}/edit/:sessionId`} exact component={Session} />
    <Route path={`${match.path}/delete/:sessionId`} exact component={SessionDelete} />
    <Route path={`${match.path}/:sessionId/missions/history`} exact component={SessionHistory} />
    <Route path={`${match.path}/:sessionId/scores/:missionId`} exact component={Scores} />
    <Route path={`${match.path}/:sessionId/missions`} exact component={Missions} />
    <Route path={match.path} exact component={Sessions} />
    <Redirect to={{ pathname: ROUTES.SESSIONS, state: { from: location } }} />;
  </Switch>
);

SessionComponent.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default SessionComponent;
