import { INIT_MISSION_LIST, ADD_VIDEOS } from './missionActionTypes';

export const initialState = [];
let missions = [],
  videos = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case INIT_MISSION_LIST:
      missions = action.data;
      return [...videos, ...missions];
    case ADD_VIDEOS:
      videos = action.data;
      return [...videos, ...missions];
    default:
      return state;
  }
}
