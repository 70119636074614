import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Account from 'components/Account/Account';
import AdminAccount from 'components/Account/AdminAccount';
import { matchPT, locationPT } from 'routes/PropTypes';
import { ROUTES } from 'config/constants';

const SessionComponent = ({ match, location }) => (
  <Switch>
    <Route path={`${match.path}/admin`} exact component={AdminAccount} />
    <Route path={match.path} exact component={Account} />
    <Redirect to={{ pathname: ROUTES.ACCOUNT, state: { from: location } }} />;
  </Switch>
);

SessionComponent.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default SessionComponent;
