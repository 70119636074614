/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { videosPT } from 'components/Video/PropTypes';
import SortableTable from 'components/SortableTable/SortableTable';
import { list } from 'components/Video/videoAction';
import { useNavbar } from 'common/components';
import { toTime } from 'utils/common';

export const correctRows = (t) => [
  {
    Header: t('_name_'),
    sortable: true,
    size: 6,
    accessor: (video) => t(video.name),
  },
  {
    Header: t('_language_'),
    sortable: true,
    center: true,
    size: 2,
    accessor: (video) => t(video.language),
  },
  {
    Header: t('_duration_'),
    sortable: true,
    size: 3,
    accessor: (video) => `${toTime(video.duration)} ${t('_min_')}.`,
  },
  {
    Header: t('_edit_'),
    sortable: false,
    center: true,
    size: 1,
    accessor: (video) => (
      <Link to={`/video/edit/${video._id}`}>
        <Button variant="outline-secondary" size="sm">
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Link>
    ),
  },
];
export const List = ({ videos }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(list());
  }, [dispatch]);
  return (
    <Container>
      {useNavbar('_videos_')}
      <div className="video-page mt-2 mb-2">
        <Nav id="video-table" className="navbar navbar-light bg-light">
          <Nav.Item className="navbar-brand">{t('_available_videos_')}</Nav.Item>
          <Nav.Item className="nav nav-pills" as="ul">
            <Nav.Item as="li">
              <Link to="/video/new">
                <Button variant="success">
                  <FontAwesomeIcon icon={faPlus} />
                  {` ${t('_create_new_')}`}
                </Button>
              </Link>
            </Nav.Item>
          </Nav.Item>
        </Nav>
        <div data-spy="scroll" data-target="#video-table" data-offset="0">
          <SortableTable
            columns={correctRows(t)}
            data={videos}
            additionalClass="video-page-table"
          />
        </div>
      </div>
    </Container>
  );
};

List.propTypes = {
  videos: videosPT.isRequired,
};

const mapStateToProps = (state) => ({ videos: state.videos });

export default connect(mapStateToProps, null)(List);
