import React from 'react';
import { Image, Row, NavLink } from 'react-bootstrap';
import InterLABLogo from 'assets/images/sloagan-InterLAB-regularsize.png';

export const Footer = () => (
  <Row className="footer">
    <NavLink href="https://www.interpersonalskillslab.ch" target="_blank">
      <Image src={InterLABLogo} />
    </NavLink>
  </Row>
);
export default Footer;
