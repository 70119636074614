import React from 'react';
import PropTypes from 'prop-types';
import { Row, Badge } from 'react-bootstrap';
import { rolesPT } from 'components/Mission/lib/PropTypes';

const TeamStatus = ({ clientId, roles, clients }) => {
  const hasSecondLine = (role) =>
    clients.filter((client) => client.role == role && client.connected).length > 1;
  const isConnected = (role) =>
    clients.filter((client) => client.role == role && client.connected).length > 0;
  const myClasses = {
    m: {
      active: roles[0] || isConnected(0) ? 'active' : '',
      ring: hasSecondLine(0) ? 'active' : '',
    },
    s: {
      active: roles[1] || isConnected(1) ? 'active' : '',
      ring: hasSecondLine(1) ? 'active' : '',
    },
    c: {
      active: roles[2] || isConnected(2) ? 'active' : '',
      ring: hasSecondLine(2) ? 'active' : '',
    },
  };
  return (
    <Row className={`team-status team-${clientId}`}>
      <Badge pill className={`ring ${myClasses.m.ring} ring-${clientId}`}>
        <Badge pill className={`circle ${myClasses.m.active}`}>
          M
        </Badge>
      </Badge>
      <Badge pill className={`ring ${myClasses.s.ring} ring-${clientId}`}>
        <Badge pill className={`circle ${myClasses.s.active}`}>
          S
        </Badge>
      </Badge>
      <Badge pill className={`ring ${myClasses.c.ring} ring-${clientId}`}>
        <Badge pill className={`circle ${myClasses.c.active}`}>
          C
        </Badge>
      </Badge>
    </Row>
  );
};
TeamStatus.defaultProps = {
  clients: [],
};

TeamStatus.propTypes = {
  clientId: PropTypes.number.isRequired,
  roles: rolesPT.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TeamStatus;
