import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { stepsPT } from 'components/Mission/lib/PropTypes';

const CustomBreadCrumb = ({ t, steps, currentStep }) => (
  <div className="breadcrumbs">
    <div className="breadcrumbs-container">
      <div className="btn-breadcrumb">
        {steps.map((step) => (
          <Button
            key={step.id}
            variant="light"
            href="#"
            className={currentStep == step.id ? 'active' : 'disabled'}
          >
            <div>
              <span className="step-number">{step.id}</span>
              {t(step.label)}
            </div>
          </Button>
        ))}
      </div>
    </div>
  </div>
);
CustomBreadCrumb.propTypes = {
  t: PropTypes.func.isRequired,
  steps: stepsPT.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default CustomBreadCrumb;
