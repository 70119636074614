import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getActiveSessions } from 'components/Account/accountActions';
import { PING_INTERVAL, ROLES } from 'config/constants';
import { userPT } from 'components/User/PropTypes';
import { serverInfo } from 'components/User/userActions';
import { getSettingsData, usbKeyChanged } from 'components/Settings/settingsAction';

import info from '../../../package';

export const StatusBar = ({ user, usbKey }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAdmin = ROLES[user.role].role == ROLES.admin.role;
  const [server, setServer] = useState({
    mode: '',
    key: '',
  });

  // const groups = useSelector(({ settings }) => settings.groups);
  const ping = useCallback(() => dispatch(getActiveSessions(false)), [dispatch]);
  useEffect(() => {
    dispatch(getSettingsData());
    let interval = null;
    if (!isAdmin) {
      dispatch(getActiveSessions());
      interval = setInterval(ping, PING_INTERVAL);
    } else {
      serverInfo((data) => {
        setServer(data);
        // istanbul ignore else
        if (data.key) {
          data.key = String(data.key);
        }
        dispatch(usbKeyChanged(data.key));
      });
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [dispatch, isAdmin, ping]);
  return (
    <>
      <Nav className="status-bar">
        <Nav.Item>
          {isAdmin ? `${t('_administration_')} | ${t(server.mode)} ` : user.company}
        </Nav.Item>
        <Nav.Item className="ml-1">
          {isAdmin && server.mode == '_local_server_'
            ? usbKey
              ? ` (${t('_licensee_id_on_key_')}: ${usbKey})`
              : t('_no_valid_key_connected_')
            : ''}
        </Nav.Item>
        <Nav.Item className="ml-auto">
          <span className="text-uppercase">{t('_skills_lab_')}</span> v{info.version}-
          {info.releaseDate}
        </Nav.Item>
      </Nav>
    </>
  );
};

StatusBar.propTypes = {
  user: userPT.isRequired,
  usbKey: PropTypes.string,
};

StatusBar.defaultProps = {
  usbKey: '',
};

export default connect(
  ({ user, settings }) => ({ user, usbKey: settings.usbKey }),
  null,
)(StatusBar);
