import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/TimeLine/Components/SectionHeader';
import SectionRow from 'components/TimeLine/Components/SectionRow';
import ChartAreaRow from 'components/TimeLine/Components/ChartAreaRow';
import NumberRow from 'components/TimeLine/Components/NumberRow';
import AbsoluteBooleanRow from 'components/TimeLine/Components/AbsoluteBooleanRow';
import { MAX_VALUES } from 'config/constants';

const Position = ({ t, data, formattedData }) => {
  const stepCount = 20;
  const duration = formattedData.keys[formattedData.length - 1] * 1;
  const step = parseInt(duration / stepCount);
  return (
    <div className="time-line-view-body">
      <SectionHeader t={t} title="_position_" />
      <SectionRow t={t} title="_total_score_">
        <NumberRow
          step={step}
          stepCount={stepCount}
          data={data}
          cellKey="totalScore"
          groupKey="position"
        />
      </SectionRow>
      <SectionRow t={t} title="_distance_points_">
        <NumberRow
          step={step}
          stepCount={stepCount}
          data={data}
          cellKey="distancePoints"
          groupKey="position"
        />
      </SectionRow>
      <SectionRow
        t={t}
        title="_speed_"
        doubleLine
        isChart
        data={formattedData.errorPoints.position.speed}
      >
        <ChartAreaRow
          data={formattedData.chartData.position.speed}
          color="#3AAA35"
          maxValue={MAX_VALUES.SPEED}
          height={56}
        />
      </SectionRow>
      <SectionRow
        t={t}
        title="_solar_receptor_position_"
        isChart
        data={formattedData.errorPoints.position.solarPosition}
      >
        <ChartAreaRow
          data={formattedData.chartData.position.solarPosition}
          color="#b2b2b2"
          maxValue={MAX_VALUES.SOLAR_POSITION}
          height={28}
          inverted
        />
        <AbsoluteBooleanRow
          data={formattedData.chartData.position.solarReceptorCrash}
          length={formattedData.length}
          color="#E30613"
        />
      </SectionRow>
    </div>
  );
};

Position.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  formattedData: PropTypes.shape({
    length: PropTypes.number,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    errorPoints: PropTypes.shape({
      position: PropTypes.shape({
        solarPosition: PropTypes.arrayOf(PropTypes.shape({})),
        speed: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    chartData: PropTypes.shape({
      position: PropTypes.shape({
        solarPosition: PropTypes.arrayOf(PropTypes.shape({})),
        solarReceptorCrash: PropTypes.arrayOf(PropTypes.shape({})),
        speed: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
};
export default Position;
