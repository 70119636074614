import PropTypes from 'prop-types';

export const clientKeyPT = PropTypes.shape({
  clientId: PropTypes.number.isRequired,
  dateCreated: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
});

export const sessionPT = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  datePlayed: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  updatedAt: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  __v: PropTypes.number.isRequired,
  _id: PropTypes.string.isRequired,
  clientKeys: PropTypes.arrayOf(clientKeyPT).isRequired,
});

export const sessionsPT = PropTypes.arrayOf(sessionPT);

export const missionPT = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  script: PropTypes.string,
  type: PropTypes.string.isRequired,
  units: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
  __v: PropTypes.number.isRequired,
  _id: PropTypes.string.isRequired,
});

export const missionsPT = PropTypes.arrayOf(missionPT);

export default sessionsPT;
