import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { shaftsPT } from 'components/Mission/lib/Lift/PropTypes';

const Shaft = ({ shafts }) => {
  const list = JSON.parse(JSON.stringify(shafts));
  for (let i = 0; i < list.length; i++) {
    if (list[i].levels[1].accu) {
      list[i].levels.splice(2, 1);
    } else {
      list[i].levels.splice(1, 1);
    }
  }
  return (
    <div className="observe-simulation-lift-shaft mt-2">
      <div className="d-flex justify-content-between observe-simulation-lift-shaft-head">
        <div className="d-flex justify-content-center align-items-center">L1</div>
        <div className="d-flex justify-content-center align-items-center">L2</div>
        <div className="d-flex justify-content-center align-items-center">L3</div>
        <div className="d-flex justify-content-center align-items-center">L4</div>
      </div>
      {list[0].levels.map((level, i) => (
        <div key={level.id} className="d-flex justify-content-between">
          {list.map((shaft) => {
            let className = '';
            let icon = null;
            level = shaft.levels[i];
            if (shaft.status === 5 || shaft.isLocked) {
              className = 'reserved';
            } else if (shaft.status !== 0) {
              className = 'jammed';
            } else if (level.accu) {
              className = 'accu';
              if (level.status === 5 && level.type === 16) {
                icon = <FontAwesomeIcon icon={faCaretDown} />;
              } else if (level.status === 3 && level.type === 16) {
                icon = <FontAwesomeIcon icon={faCaretUp} />;
              }
            }
            return (
              <div
                className={`d-flex justify-content-center align-items-center field ${className}`}
                key={`${shaft.id}-${level.id}`}
              >
                {icon}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

Shaft.propTypes = {
  shafts: shaftsPT.isRequired,
};
export default Shaft;
