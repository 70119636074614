export const INIT_ADMIN_MISSION_LIST = 'INIT_ADMIN_MISSION_LIST';
export const UPDATE_ADMIN_MISSION = 'UPDATE_ADMIN_MISSION';
export const ADD_ADMIN_MISSION = 'ADD_ADMIN_MISSION';
export const DELETE_ADMIN_MISSION = 'DELETE_ADMIN_MISSION';

export default {
  INIT_ADMIN_MISSION_LIST,
  UPDATE_ADMIN_MISSION,
  ADD_ADMIN_MISSION,
  DELETE_ADMIN_MISSION,
};
