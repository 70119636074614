import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faTimesCircle, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { sessionPT } from 'components/Mission/PropTypes';
import { SESSION_TYPE } from 'config/constants';
import { userPT } from 'components/User/PropTypes';

const MissionFooter = ({
  t,
  session,
  historyExist,
  isDisconnectActive,
  disconnect,
  onAssignTeamsClick,
  user,
  waiting,
}) => (
  <div className="mission-page-footer">
    <Row>
      <Col xs="auto">
        <Button variant="danger" disabled={!isDisconnectActive} onClick={disconnect}>
          <FontAwesomeIcon icon={faTimesCircle} />
          {` ${t('_disconnect_all_teams_')}`}
        </Button>
      </Col>
      <Col className="mission-page-footer-tools d-flex justify-content-end">
        <Link to={`/sessions/${session._id}/missions/history`}>
          <Button
            variant="outline-secondary"
            disabled={!historyExist}
            className="mission-page-footer-tools-history"
          >
            <FontAwesomeIcon icon={faHistory} />
            {` ${t('_view_history_')}`}
          </Button>
        </Link>
        {session.sessionType == SESSION_TYPE.DYNAMIC ? (
          <>
            <Button
              variant="primary"
              onClick={onAssignTeamsClick}
              className="mission-page-footer-tools-assign"
            >
              <FontAwesomeIcon icon={faUserFriends} />
              {` ${t('_assign_teams_')}`}
            </Button>
            <Button variant="outline-secondary" className="mission-page-footer-tools-waiting">{`${t(
              '_waiting_',
            )}: ${waiting}`}</Button>
          </>
        ) : null}
        <Button variant="outline-secondary" className="mission-page-footer-tools-units">
          {`${t('_units_')}`} <b>{user.units}</b>
        </Button>
      </Col>
    </Row>
  </div>
);
MissionFooter.propTypes = {
  t: PropTypes.func.isRequired,
  session: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
    }),
    sessionPT,
  ]).isRequired,
  historyExist: PropTypes.bool.isRequired,
  isDisconnectActive: PropTypes.bool.isRequired,
  disconnect: PropTypes.func.isRequired,
  onAssignTeamsClick: PropTypes.func.isRequired,
  user: userPT.isRequired,
  waiting: PropTypes.number.isRequired,
};
export default MissionFooter;
