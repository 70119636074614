import React from 'react';
import PropTypes from 'prop-types';
import { Controlled as CodeMirror } from 'react-codemirror2';
import ruleDecorator from 'common/CoffeeScript/rule.decorator';
import coffeescript from 'coffeescript';

// Fix coffelint block
window.CoffeeScript = coffeescript;

const coffeelint = require('coffeelint');

window.coffeelint = ruleDecorator(coffeelint);

require('codemirror/addon/lint/lint');
require('codemirror/addon/lint/coffeescript-lint');
require('codemirror/mode/coffeescript/coffeescript');
// End of fix coffelint block

const CoffeeScript = ({ script, changeScript }) => (
  <div className="coffee-script">
    <CodeMirror
      value={script}
      name="code-mirror"
      options={{
        mode: 'coffeescript',
        lineNumbers: true,
        gutters: ['CodeMirror-lint-markers'],
        lint: true,
      }}
      onBeforeChange={(_, __, value) => changeScript(value)}
    />
  </div>
);
CoffeeScript.propTypes = {
  script: PropTypes.string.isRequired,
  changeScript: PropTypes.func.isRequired,
};
export default CoffeeScript;
