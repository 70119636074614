import io from 'socket.io-client';
import { SESSION } from 'config/constants';

export const defaultOptions = {
  transports: ['websocket'],
  rejectUnauthorized: false,
  secure: false,
  verify: true,
  reconnection: true,
  forceNew: true,
  reconnectionDelay: 3000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 100,
};

class Session {
  constructor(url, id, options = defaultOptions) {
    this.id = id;
    this.url = url;
    this.options = options;
    this.isAuthenticated = false;
    this.rooms = [];
    this.callbackMap = {};
    this.connect(this.onConnect);
  }

  onConnect = () => {
    this.register();
  };

  on(eventName, callback, cbId) {
    if ('string' !== typeof eventName) {
      throw new Error('eventName should be a string');
    }
    if ('function' !== typeof callback) {
      throw new Error('callback should be a function');
    }
    if (!this.socket) {
      this.connect();
    }
    if (cbId) {
      const prev = this.callbackMap[cbId];
      if (prev) {
        this.off(prev.eventName, prev.callback);
      }
      this.callbackMap[cbId] = {
        callback,
        eventName,
      };
    }
    this.socket.on(eventName, callback);
    return () => {
      this.off(eventName, callback);
    };
  }

  once(eventName, callback) {
    if ('string' !== typeof eventName) {
      throw new Error('eventName should be a string');
    }
    if ('function' !== typeof callback) {
      throw new Error('callback should be a function');
    }
    if (!this.socket) {
      this.connect();
    }
    this.socket.once(eventName, callback);
    return () => {
      this.off(eventName, callback);
    };
  }

  emit(eventName, data = {}) {
    if ('string' !== typeof eventName) {
      throw new Error('eventName should be a string');
    }
    if (!this.socket) {
      this.connect();
    }
    this.socket.emit(eventName, data);
  }

  off(eventName, callback) {
    if ('string' !== typeof eventName) {
      throw new Error('eventName should be a string');
    }
    if ('function' !== typeof callback) {
      throw new Error('callback should be a function');
    }
    if (!this.socket) {
      this.connect();
    }
    this.socket.removeEventListener(eventName, callback);
  }

  disconnect() {
    if (this.socket) {
      this.socket.io.disconnect();
      this.socket = null;
    }
  }

  connect(callback) {
    if (!this.socket) {
      this.socket = io.connect(`${this.url}?id=${this.id}`, this.options);
    }
    if ('function' === typeof callback) {
      return this.on(SESSION.CONNECT, callback, 'connect-callback');
    }
  }

  register() {
    this.emit('licensee:connect');
  }

  loadTeamStatuses() {
    this.emit('licensee:init');
  }

  clientJoined(callback, cbId = 'client-joined') {
    return this.on(SESSION.JOINED, callback, cbId);
  }

  totalsReceived(callback, cbId = 'totals-received') {
    return this.on(SESSION.TOTAL, callback, cbId);
  }

  missionComplete(callback, cbId = 'mission-complete') {
    return this.on(SESSION.COMPLETE, callback, cbId);
  }

  onLicenseeConnect(callback, cbId = 'on-licensee-connect') {
    return this.on(SESSION.CONNECT, callback, cbId);
  }

  remainingTimeReceived(callback, cbId = 'time-received') {
    return this.on(SESSION.TIME, callback, cbId);
  }

  onLicenseeDisconnect(callback, cbId = 'licensee-disconnect') {
    return this.on(SESSION.LICENSEE_DISCONNECT, callback, cbId);
  }
  onDisconnect(callback, cbId) {
    return this.on(SESSION.DISCONNECT, callback, cbId);
  }

  onGameError(callback, cbId = 'game-error') {
    return this.on(SESSION.GAME_ERROR, callback, cbId);
  }

  onGameStart(callback) {
    return this.on(SESSION.GAME_START, callback);
  }

  onGameComplete(callback) {
    return this.on(SESSION.GAME_COMPLETE, callback);
  }
  onGameSaved(callback, cbId = 'game-saved') {
    return this.on(SESSION.GAME_SAVED, callback, cbId);
  }

  onGameAbort(callback, cbId = 'game-aborted') {
    return this.on(SESSION.GAME_ABORT, callback, cbId);
  }

  observe(callback, cbId = 'observe') {
    return this.on(SESSION.OBSERVE, callback, cbId);
  }

  switchGame(data) {
    this.emit('game:switch', data);
  }

  offAll(
    clientJoined,
    missionComplete,
    observe,
    onDisconnect,
    onGameAbort,
    onGameError,
    remainingTimeReceived,
    totalsReceived,
    onTagAdded,
    onTagRemoved,
    onGameSaved,
  ) {
    this.off(SESSION.JOINED, clientJoined);
    this.off(SESSION.TOTAL, totalsReceived);
    this.off(SESSION.GAME_COMPLETE, missionComplete);
    this.off(SESSION.TIME, remainingTimeReceived);
    this.off(SESSION.DISCONNECT, onDisconnect);
    this.off(SESSION.GAME_ERROR, onGameError);
    this.off(SESSION.GAME_ABORT, onGameAbort);
    this.off(SESSION.OBSERVE, observe);
    this.off(SESSION.TAG_ADDED, onTagAdded);
    this.off(SESSION.TAG_REMOVED, onTagRemoved);
    this.off(SESSION.GAME_SAVED, onGameSaved);
  }

  sendMessage(data) {
    this.emit(SESSION.SEND_MESSAGE, data);
  }

  addTag(data) {
    this.emit(SESSION.ADD_TAG, data);
  }

  onTagAdded(callback, cbId = 'tag-added') {
    return this.on(SESSION.TAG_ADDED, callback, cbId);
  }

  onTagRemoved(callback, cbId = 'tag-removed') {
    return this.on(SESSION.TAG_REMOVED, callback, cbId);
  }

  /* istanbul ignore next */
  offByIds(list) {
    list.forEach((id) => {
      const { eventName, callback } = this.callbackMap[id] || {};
      if (eventName && callback) {
        this.off(eventName, callback);
      }
    });
  }
}

export default Session;
