import { CLIENT_LIST, UPDATE_CLIENT } from 'components/Client/clientActionTypes';

export const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case CLIENT_LIST:
      return action.data;
    case UPDATE_CLIENT:
      return state.map((client) => {
        if (client._id == action.data._id) {
          client = { ...client, ...action.data };
        }
        return client;
      });
    default:
      return state;
  }
}
