/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MESSAGE } from 'config/constants';

const MessageBody = ({ text, variant, title, showCloseButton, onDismiss, translate }) => {
  const { t } = useTranslation();
  return (
    <Alert variant={variant} dismissible={showCloseButton} onClose={onDismiss} className="mt-2">
      {title ? <Alert.Heading>{t(title)}</Alert.Heading> : null}
      <div>
        <div dangerouslySetInnerHTML={{ __html: translate ? t(text) : text }} />
      </div>
    </Alert>
  );
};

MessageBody.deafultProps = {
  text: '',
  title: '',
  variant: MESSAGE.ERROR,
  showCloseButton: false,
};
MessageBody.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([MESSAGE.ERROR, MESSAGE.SUCCESS, MESSAGE.WARNING, MESSAGE.INFO])
    .isRequired,
  showCloseButton: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  translate: PropTypes.bool.isRequired,
};
export default MessageBody;
