import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Form } from 'react-bootstrap';
import { useNavbar } from 'common/components';
import {
  list,
  getClient,
  uploadFile,
  updateData,
  deleteClient,
} from 'components/Client/clientAction';
import ButtonBar from 'components/ButtonBar/ButtonBar';
import DeleteComponent from 'common/DeleteComponent/DeleteComponent';
import UploadFile from 'common/UploadFile/UploadFile';
import Notifier from 'components/Message/Notifier';
import security from 'common/security/security';
import { goBack } from 'utils/common';
import { ROLES } from 'config/constants';

const Edit = () => {
  const { clientId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [client, setClient] = useState(null);
  const [show, setShow] = useState(false);
  const modalOpen = () => setShow(true);
  const modalClose = () => setShow(false);
  const changeInfo = useCallback((info) => setClient({ ...client, info }), [client]);
  const changeFile = useCallback(
    (files) => {
      const formData = new FormData();
      formData.append('file', client.file);
      formData.append('info', client.info);
      formData.append('files[0]', files[0], files[0].name);
      Notifier.loading(true);
      uploadFile(client._id, formData, (data) => {
        if (security.isAuthorized() && security.getUser().role == ROLES.admin.role) {
          setClient({ ...data, info: client.info });
          Notifier.success(t('_client_successfully_updated_'));
          Notifier.loading(false);
        }
      });
    },
    [client, t],
  );
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const form = {
        info: client.info.trim(),
        file: client.file,
      };
      dispatch(updateData(client._id, form, () => goBack(history)));
    },
    [client, dispatch, history],
  );
  const handleDelete = useCallback(() => {
    modalClose();
    dispatch(deleteClient(clientId, (client) => setClient(client)));
  }, [clientId, dispatch]);
  useEffect(() => {
    dispatch(list());
    dispatch(getClient(clientId, (data) => setClient(data)));
    return () => Notifier.loading(false);
  }, [dispatch, clientId]);
  return (
    <Container className="resource-page-edit">
      {useNavbar(t('_edit_delete_client_'))}
      <Form method="post" onSubmit={handleSubmit}>
        {client ? (
          <UploadFile
            data={{
              ...client,
              name: t(client.name),
            }}
            t={t}
            changeInfo={changeInfo}
            onDrop={changeFile}
            infoText="_enter_client_info_"
            accept={['zip']}
          />
        ) : null}
        <ButtonBar
          isFormFilled
          saveActionType="submit"
          showHistory={false}
          {...{
            missionDelete: client && client.exist ? modalOpen : null,
          }}
        />
      </Form>
      <DeleteComponent
        handleClose={modalClose}
        show={show}
        handleDelete={handleDelete}
        title="_delete_mission_msg_"
        keepText="_keep_client_"
        deleteText="_delete_client_"
      />
    </Container>
  );
};

export default Edit;
