import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { fillZero } from 'utils/common';

const EnergyGenerator = ({ generator }) => (
  <div className="observe-simulation-generator">
    <Table bordered className="observe-simulation-generator-table">
      <thead>
        <tr>
          <th>k</th>
          <th>l</th>
          <th>m</th>
          <th>n</th>
        </tr>
      </thead>
      <tbody>
        {generator.fields.map((row, i) => (
          <tr key={`row-${i + 1}`}>
            {row.map((column, j) => {
              let className = '';
              const { energy } = generator.dials[j];
              if (25 <= energy && energy < 50) {
                className = 'yellow';
              } else if (energy < 25) {
                className = 'red';
              }
              return (
                <td key={`${i + 1}-col-${j + 1}`} className={className}>
                  {column == null ? '-' : fillZero(column / 10)}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

EnergyGenerator.propTypes = {
  generator: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    dials: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
export default EnergyGenerator;
