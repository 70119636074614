import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const DeleteComponent = ({
  handleClose,
  show,
  handleDelete,
  title,
  keepText,
  deleteText,
  hideTrash,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={(e) => handleClose(e, true)}>
      <Modal.Header closeButton>
        <Modal.Title> {` ${t(title)}`} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="text-left">
            <Button variant="danger" onClick={handleDelete}>
              {!hideTrash && <FontAwesomeIcon icon={faTrashAlt} />}
              {` ${t(deleteText)}`}
            </Button>
          </Col>
          <Col className="text-right">
            <Button variant="success" onClick={handleClose}>
              {` ${t(keepText)}`}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
DeleteComponent.defaultProps = {
  hideTrash: false,
};
DeleteComponent.propTypes = {
  title: PropTypes.string.isRequired,
  keepText: PropTypes.string.isRequired,
  deleteText: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  hideTrash: PropTypes.bool,
};
export default DeleteComponent;
