import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Button, Form, Row, Col, ListGroup, InputGroup } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrashAlt, faBolt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import validator from 'utils/validator';
import DeleteComponent from 'common/DeleteComponent/DeleteComponent';
import { useNavbar } from 'common/components';
import {
  create,
  get,
  remove,
  update,
  checkUsernameExistance,
  updateKey,
  serverInfo,
  getSettingsData,
} from 'components/User/userActions';
import { usbKeyChanged } from 'components/Settings/settingsAction';
import { goBack, excludeLetters } from 'utils/common';
import Notifier from 'components/Message/Notifier';
import { MESSAGE_STATE } from 'config/constants';

const User = () => {
  let { userId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [id, setId] = useState('');
  const [company, setCompany] = useState('');
  const [username, setUsername] = useState('');
  const [units, setUnits] = useState('');
  const [maxActiveSessions, setMaxActiveSessions] = useState('');
  const [password, setPassword] = useState('');
  const [verification, setVerification] = useState('');
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [writeKey, setWriteKey] = useState(false);
  const [unitsEnabled, setUnitsEnabled] = useState(null);
  const [showWriteKey, setShowWriteKey] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [groups, setGroups] = useState([]);
  const [message, setMessage] = useState({ state: MESSAGE_STATE.OFF });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseWriteKey = () => setShowWriteKey(false);
  const handleShowWriteKey = () => setShowWriteKey(true);

  const usbKey = useSelector(({ settings }) => settings.usbKey);

  const user = {
    id,
    company,
    username,
    units,
    maxActiveSessions,
    active,
    password,
    verification,
    unitsEnabled,
    availableLanguages,
    availableGroups,
    message,
    tmpLanguages: availableLanguages.map((availableLanguage) =>
      languages.find((language) => language._id == availableLanguage),
    ),
  };

  const isUserFormValid = validator.isUserFormValid(user);
  const isUserKeyIdIsValid = validator.isUserKeyIdIsValid(user.id);

  if (!password.length) {
    delete user.password;
    delete user.verification;
  }
  const handleCreate = useCallback(
    (event) => {
      event.preventDefault();
      if (isUserFormValid) {
        checkUsernameExistance({ username: user.username, userId }, () => {
          /* istanbul ignore else */
          if (user.units) {
            user.units = parseFloat(user.units);
          }
          /* istanbul ignore else */
          if (!user.unitsEnabled || (usbKey && usbKey != user.id)) {
            delete user.units;
          }
          delete user.unitsEnabled;
          dispatch(
            !userId
              ? create(user, () => {
                  Notifier.success('_licensee_successfully_created_');
                  goBack(history);
                })
              : update(userId, user, () => {
                  Notifier.success('_licensee_successfully_updated_');
                  goBack(history);
                }),
          );
        });
      }
    },
    [isUserFormValid, user, userId, usbKey, dispatch, history],
  );

  const handleLanguageChange = (event) => {
    if (!availableLanguages.includes(event.target.value) && event.target.checked) {
      setAvailableLanguages([...availableLanguages, event.target.value]);
    } else if (availableLanguages.includes(event.target.value) && !event.target.checked) {
      setAvailableLanguages(availableLanguages.filter((e) => e !== event.target.value));
    }
  };

  const handleGroupChange = (event) => {
    if (!availableGroups.includes(event.target.value) && event.target.checked) {
      setAvailableGroups([...availableGroups, event.target.value]);
    } else if (availableGroups.includes(event.target.value) && !event.target.checked) {
      setAvailableGroups(availableGroups.filter((e) => e !== event.target.value));
    }
  };
  const handleDeleteUser = useCallback(() => {
    dispatch(
      remove(userId, () => {
        Notifier.success('_licensee_successfully_removed_');
        goBack(history);
      }),
    );
  }, [dispatch, history, userId]);
  const handleWriteKey = useCallback(
    (e, onHide = false) => {
      handleCloseWriteKey();
      // istanbul ignore else
      if (!onHide) {
        Notifier.loading(true);
        updateKey(
          {
            userId,
            key: id,
          },
          (user) => {
            dispatch(usbKeyChanged(id));
            setCompany(user.company);
            setUsername(user.username);
            setUnits(user.units);
            setMaxActiveSessions(user.maxActiveSessions);
            setActive(user.active);
            setWriteKey(user.writeKey);
            setUnitsEnabled(user.unitsEnabled);
            Notifier.loading(false);
            Notifier.success('_id_successfully_wrote_');
          },
          () => {
            handleCloseWriteKey();
            Notifier.loading(false);
          },
        );
      }
    },
    [dispatch, id, userId],
  );

  const changeMessage = useCallback(
    ({ name, value }) => setMessage({ ...message, [name]: value }),
    [message],
  );

  useEffect(() => {
    serverInfo((data) => {
      setUnitsEnabled(!data.hasOwnProperty('key'));
      if (userId) {
        get(userId, (user) => {
          setId(user.id);
          setCompany(user.company);
          setUsername(user.username);
          setUnits(user.units);
          setMaxActiveSessions(user.maxActiveSessions);
          setActive(user.active);
          setWriteKey(user.writeKey);
          setUnitsEnabled(user.unitsEnabled);
          setAvailableLanguages(user.availableLanguages || []);
          setAvailableGroups(user.availableGroups || []);
          setMessage(user.message || { state: 0 });
        });
      }
      getSettingsData((settingsData) => {
        setLanguages(settingsData.languages);
        setGroups(settingsData.groups);
      });
    });
  }, [userId]);
  const navBarText = !userId ? '_create_licensee_' : '_edit_delete_licensee_';
  return (
    <Container>
      {useNavbar(navBarText)}
      <Form method="POST" onSubmit={handleCreate} className="mt-5">
        <Form.Group as={Row} controlId="formLicenseeId">
          <Form.Label column xs={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 2 }}>
            {t('_company_key_id_')}
          </Form.Label>
          <Col xs={6}>
            <Row>
              <Col>
                <Form.Control
                  placeholder={t('_enter_company_name_')}
                  name="company"
                  value={company}
                  {...(company.length ? validator.validate('company', company) : {})}
                  onChange={(e) => setCompany(e.target.value.trimLeft())}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t('_company_name_is_short_')}
                </Form.Control.Feedback>
              </Col>
              <Col
                lg={3}
                md={4}
                sm={5}
                xs={12}
                className="d-flex justify-content-end align-items-end flex-column"
              >
                <Form.Control
                  className="company-key"
                  placeholder={t('_id_')}
                  type="number"
                  name="id"
                  value={id}
                  {...(id.length ? validator.validate('id', id) : {})}
                  onChange={(e) => setId(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t('_not_a_valid_number_')}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Col>
          {writeKey ? (
            <Col sm={{ span: 2, offset: 0 }} xs={{ span: 7, offset: 4 }} className="write-key">
              <Button
                variant="light"
                className="pull-right"
                onClick={handleShowWriteKey}
                id="write-key-button"
                disabled={!isUserKeyIdIsValid}
              >
                <FontAwesomeIcon icon={faBolt} />
                {` ${t('_write_id_to_key_')}`}
              </Button>
            </Col>
          ) : null}
        </Form.Group>

        <Form.Group as={Row} controlId="formUsername">
          <Form.Label column xs={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 2 }}>
            {t('_username_')}
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              placeholder={t('_enter_username_')}
              minLength="4"
              name="username"
              value={username}
              {...(username.length ? validator.validate('username', username) : {})}
              onChange={(e) => setUsername(e.target.value.trimLeft())}
              required
            />
            <Form.Control.Feedback type="invalid">{t('_username_is_short_')}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formPassword">
          <Form.Label column xs={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 2 }}>
            {t('_password_')}
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              name="password"
              type="password"
              placeholder={t('_enter_password_')}
              autoComplete="new-password"
              value={password}
              {...(password.length ? validator.validate('password', password) : {})}
              onChange={(e) => setPassword(e.target.value)}
              required={!userId}
            />
            <Form.Control.Feedback type="invalid">
              {t('_password_strength_is_low_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formVerificationPassword">
          <Form.Label column xs={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 2 }}>
            {t('_confirm_password_')}
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              name="verification"
              type="password"
              autoComplete="new-password"
              placeholder={t('_repeat_password_')}
              value={verification}
              {...(password.length || verification.length
                ? validator.validate('match', { verification, value: password })
                : {})}
              onChange={(e) => setVerification(e.target.value)}
              required={!userId}
            />
            <Form.Control.Feedback type="invalid">
              {t('_passwords_dont_match_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <hr className="dotted-line" />
        <Form.Group as={Row} controlId="formAccount">
          <Form.Label column xs={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 2 }}>
            {t('_account_')}
          </Form.Label>
          <Col xs={6}>
            <Row>
              <Col md={4} xs={12}>
                <Form.Group as={Row} controlId="formUnits">
                  <Form.Label column md={3} xs={4}>
                    {t('_units_')}
                  </Form.Label>
                  <Col md={9} xs={8} className="justify-content-end">
                    {typeof unitsEnabled != 'boolean' ? (
                      ''
                    ) : unitsEnabled ? (
                      <Form.Control
                        xs={{ offset: 0 }}
                        placeholder={t('_enter_units_')}
                        type="number"
                        name="units"
                        onKeyDown={excludeLetters}
                        step={0.5}
                        value={units}
                        {...(units.length ? validator.validate('units', units) : {})}
                        onChange={(e) => setUnits(e.target.value)}
                        required
                      />
                    ) : (
                      <p className="user-page-key">{t('_licensee_key_not_connected_')}</p>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {t('_not_a_valid_number_')}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4} xs={12}>
                <Form.Group as={Row} controlId="formMaxActiveSessions">
                  <Form.Label column md={3} xs={4}>
                    {t('_teams_')}
                  </Form.Label>
                  <Col md={9} xs={8} className="justify-content-end">
                    <Form.Control
                      xs={{ offset: 0 }}
                      placeholder={t('_enter_teams_')}
                      type="number"
                      step={1}
                      min={0}
                      name="maxActiveSessions"
                      required
                      onKeyDown={excludeLetters}
                      value={maxActiveSessions}
                      {...(maxActiveSessions.length
                        ? validator.validate('maxActiveSessions', maxActiveSessions)
                        : {})}
                      onChange={(e) => setMaxActiveSessions(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t('_not_a_valid_number_')}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4} xs={12}>
                <Form.Group
                  className="d-flex justify-content-end switch-button"
                  as={Row}
                  controlId="formActive"
                >
                  <Col xs={12}>
                    <BootstrapSwitchButton
                      checked={active}
                      onlabel={t('_active_')}
                      offlabel={t('_inactive_')}
                      onstyle="light"
                      offstyle="light"
                      border
                      onChange={setActive}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Form.Group>
        <hr className="dotted-line" />

        <Form.Group as={Row} controlId="formPermissions">
          <Form.Label column xs={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 2 }}>
            {t('_permissions_')}
          </Form.Label>
          <Col xs={6}>
            <Row>
              <Col md={4} xs={12}>
                <Form.Group as={Row} className="mr-md-1" controlId="formLanguages">
                  <Form.Label column md={12} xs={4}>
                    {t('_languages_')}
                  </Form.Label>
                  <Col xs={8} md={12} className="languages">
                    <ListGroup>
                      {languages.map((item) => (
                        <ListGroup.Item className="user-list-group-item" key={item.name}>
                          <InputGroup.Prepend>
                            <InputGroup.Checkbox
                              onChange={handleLanguageChange}
                              value={item._id}
                              name="availableLanguages"
                              checked={availableLanguages.indexOf(item._id) != -1}
                            />
                          </InputGroup.Prepend>
                          <span>{t(item.name)}</span>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={8} xs={12} className="justify-content-end">
                <Form.Group as={Row} controlId="formMissionGroup">
                  <Form.Label column md={12} xs={4}>
                    {t('_mission_groups_')}
                  </Form.Label>
                  <Col xs={8} md={12}>
                    <Row>
                      <Col md={4} xs={12} className="justify-content-end mission-group">
                        <ListGroup>
                          {groups.slice(0, 4).map((item) => (
                            <ListGroup.Item className="user-list-group-item" key={item.name}>
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  onChange={handleGroupChange}
                                  value={item._id}
                                  name="availableGroups"
                                  checked={availableGroups.indexOf(item._id) != -1}
                                />
                              </InputGroup.Prepend>
                              <span>{t(item.name)}</span>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                      <Col md={4} xs={12} className="justify-content-end mission-group">
                        <ListGroup>
                          {groups.slice(4, 8).map((item) => (
                            <ListGroup.Item className="user-list-group-item" key={item.name}>
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  onChange={handleGroupChange}
                                  value={item._id}
                                  name="availableGroups"
                                  checked={availableGroups.indexOf(item._id) != -1}
                                />
                              </InputGroup.Prepend>
                              <span>{t(item.name)}</span>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                      <Col md={4} xs={12} className="justify-content-end mission-group">
                        <ListGroup>
                          {groups.slice(8, 12).map((item) => (
                            <ListGroup.Item className="user-list-group-item" key={item.name}>
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  onChange={handleGroupChange}
                                  value={item._id}
                                  name="availableGroups"
                                  checked={availableGroups.indexOf(item._id) != -1}
                                />
                              </InputGroup.Prepend>
                              <span>{t(item.name)}</span>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Form.Group>
        <hr className="dotted-line" />

        <Form.Group as={Row} controlId="formLoginMessage">
          <Form.Label column xs={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 2 }}>
            {t('_login_message_')}
          </Form.Label>
          <Col xs={8}>
            <Row>
              <Col md={4} xs={12}>
                <Form.Group as={Row} controlId="formLoginMessageOptions">
                  <Col md={12} sm={6} xs={10} className="justify-content-end">
                    <ListGroup>
                      <ListGroup.Item className="user-list-group-item">
                        <InputGroup.Prepend>
                          <InputGroup.Radio
                            name="loginMessageGroup"
                            checked={message.state == MESSAGE_STATE.OFF}
                            onChange={() =>
                              changeMessage({ name: 'state', value: MESSAGE_STATE.OFF })
                            }
                          />
                        </InputGroup.Prepend>
                        <span>{t('_off_')}</span>
                      </ListGroup.Item>
                      <ListGroup.Item className="user-list-group-item">
                        <InputGroup.Prepend>
                          <InputGroup.Radio
                            name="loginMessageGroup"
                            checked={message.state == MESSAGE_STATE.TRAINER}
                            onChange={() =>
                              changeMessage({ name: 'state', value: MESSAGE_STATE.TRAINER })
                            }
                          />
                        </InputGroup.Prepend>
                        <span>{t('_trainer_only_')}</span>
                      </ListGroup.Item>
                      <ListGroup.Item className="user-list-group-item">
                        <InputGroup.Prepend>
                          <InputGroup.Radio
                            name="loginMessageGroup"
                            checked={message.state == MESSAGE_STATE.TRAINER_AND_GAME}
                            onChange={() =>
                              changeMessage({
                                name: 'state',
                                value: MESSAGE_STATE.TRAINER_AND_GAME,
                              })
                            }
                          />
                        </InputGroup.Prepend>
                        <span>{t('_trainer_and_game_clients_')}</span>
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={8} xs={12}>
                <Form.Group as={Row} controlId="formMessageTitle">
                  <Form.Label column md={3} xs={7} className="message-title">
                    {t('_message_title_')}
                  </Form.Label>
                  <Col md={8} sm={8} xs={10}>
                    <Form.Control
                      value={message.title || ''}
                      name="title"
                      onChange={({ target }) =>
                        changeMessage({
                          name: target.name,
                          value: target.value.trimLeft(),
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formMessageText">
                  <Form.Label column md={3} xs={7} className="message-title">
                    {t('_message_text_')}
                  </Form.Label>
                  <Col md={8} sm={8} xs={10}>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      value={message.text || ''}
                      name="text"
                      onChange={({ target }) =>
                        changeMessage({
                          name: target.name,
                          value: target.value.trimLeft(),
                        })
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Form.Group>

        <Row>
          <Col xs={6} md={6}>
            {userId ? (
              <Button
                variant="danger"
                type="button"
                className="action-button"
                id="delete-button"
                onClick={handleShow}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
                {`  ${t('_delete_')}`}
              </Button>
            ) : null}
          </Col>
          <Col xs={5} md={6} className="text-right">
            <Button
              className="action-button"
              variant="success"
              type="submit"
              disabled={!isUserFormValid}
            >
              <FontAwesomeIcon icon={faCheck} />
              {` ${t('_save_changes_')}`}
            </Button>
          </Col>
        </Row>
        <DeleteComponent
          handleClose={handleClose}
          show={show}
          handleDelete={handleDeleteUser}
          title="_delete_user_message_"
          keepText="_keep_user_"
          deleteText="_delete_user_"
        />
        <DeleteComponent
          handleClose={handleWriteKey}
          show={showWriteKey}
          hideTrash
          handleDelete={handleCloseWriteKey}
          title="_delete_user_message_"
          keepText="_overwrite_key_id_"
          deleteText="_cancel_"
        />
      </Form>
    </Container>
  );
};

export default User;
