import { UPDATE_TEAMS } from 'components/Mission/missionActionTypes';

export const initialState = {
  clientId: 5,
  key: '',
  isMC: true,
  isReady: false,
};

export default function (state = initialState, action) {
  if (!action.teams) return state;

  const mc = action.teams.find((team) => team.clientId === state.clientId);

  switch (action.type) {
    case UPDATE_TEAMS:
      return {
        ...state,
        key: mc.key,
        isReady: mc.isReady,
      };
    default:
      return state;
  }
}
