import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import List from 'components/Scenario/List';
import Scenario from 'components/Scenario/Scenario';
import { matchPT, locationPT } from 'routes/PropTypes';
import { ROUTES } from 'config/constants';

const ScenarioRoute = ({ match, location }) => (
  <Switch>
    <Route path={match.path} exact component={List} />
    <Route path={`${match.path}/edit/:scenarioId`} exact component={Scenario} />
    <Route path={`${match.path}/new`} exact component={Scenario} />
    <Redirect to={{ pathname: ROUTES.SCENARIO, state: { from: location } }} />
  </Switch>
);

ScenarioRoute.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default ScenarioRoute;
