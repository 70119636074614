import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Nav, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useNavbar } from 'common/components';
import moment from 'moment';
import downloader from 'utils/downloader';
import { get, getMissions, exportSession } from 'components/Session/sessionActions';
import SortableTable from 'components/SortableTable/SortableTable';
import Notifier from 'components/Message/Notifier';

export const correctRows = (t) => [
  {
    Header: '#',
    sortable: false,
    center: true,
    size: 0,
    accessor: (_, index) => index + 1,
  },
  {
    Header: t('_time_and_date_'),
    accessor: (mission) => moment(mission.date).format('YYYY-MM-DD HH:mm'),
    sortable: true,
    size: 6,
  },
  {
    Header: t('_mission_'),
    sortable: true,
    size: 6,
    accessor: (mission) => mission.name,
  },
];
const SessionHistory = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { sessionId } = useParams();
  const [name, setName] = useState('');
  const [missions, setMissions] = useState([]);
  useEffect(() => {
    if (sessionId) {
      Notifier.loading(true);
      get(sessionId, (session) => {
        setName(session.name);
        getMissions(session._id, (missions) => {
          setMissions(missions);
          Notifier.loading(false);
        });
      });
    }
  }, [sessionId]);

  const rowClickHandler = useCallback(
    (row) => history.push(`/sessions/${sessionId}/scores/${row._id}/`),
    [history, sessionId],
  );

  const exportSessionHandler = useCallback(() => {
    exportSession(sessionId, (data) => downloader.download(data.filename, data.value));
  }, [sessionId]);

  return (
    <Container>
      {useNavbar(`${t('_mission_list_')} | ${name}`)}
      <div className="session-page mt-3 mb-3">
        <Nav id="mission-table" className="navbar navbar-light bg-light">
          <Nav.Item className="navbar-brand">{t('_history_of_played_missions_')}</Nav.Item>
          <Nav.Item className="nav nav-pills" as="ul">
            <Nav.Item as="li">
              <Button variant="success" onClick={exportSessionHandler}>
                <FontAwesomeIcon icon={faFileExport} />
                {` ${t('_export_')}`}
              </Button>
            </Nav.Item>
          </Nav.Item>
        </Nav>
        <div data-spy="scroll" data-target="#session-table" data-offset="0">
          <SortableTable
            columns={correctRows(t)}
            data={missions}
            additionalClass="session-page-mission-table table-hover"
            rowClickHandler={rowClickHandler}
          />
        </div>
      </div>
    </Container>
  );
};
export default SessionHistory;
