import { INIT_RESOURCE_LIST, UPDATE_RESOURCE } from 'components/Resource/resourceActionTypes';

export const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case INIT_RESOURCE_LIST:
      return action.data;
    case UPDATE_RESOURCE:
      return state.map((resource) => {
        if (resource._id == action.data._id) {
          resource = { ...resource, ...action.data };
        }
        return resource;
      });
    default:
      return state;
  }
}
