import React from 'react';
import PropTypes from 'prop-types';
import Achievements from 'components/TimeLine/Achievements';
import Discoveries from 'components/TimeLine/Discoveries';
import MissionControl from 'components/TimeLine/MissionControl';
import NexusEvents from 'components/TimeLine/NexusEvents';
import OrangeScreen from 'components/TimeLine/OrangeScreen';

const TimeLineTable = ({ t, data, duration }) => {
  const stepCount = 20;
  const keys = Object.keys(data).filter((key) => !isNaN(Number(key)));
  duration = duration || keys[keys.length - 1] * 1;
  const step = duration / 600;
  return (
    <div className="scores-page row mb-2">
      <div className="time-line-view">
        <div className="time-line-view-header">
          <div>
            <span>{t('_mission_time_')}</span>
            <span>0</span>
          </div>
          {Array(10)
            .fill(1)
            .map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`time-line-view-header-${index}`}>
                {Math.round((item + index) * step * 100) / 100}
              </div>
            ))}
        </div>
        <Achievements
          data={data}
          t={t}
          step={Math.floor(duration / stepCount)}
          stepCount={stepCount}
        />
        <Discoveries data={data} t={t} />
        <MissionControl
          data={data}
          t={t}
          step={Math.floor(duration / stepCount)}
          stepCount={stepCount}
        />
        <NexusEvents data={data} t={t} duration={duration} />
        <OrangeScreen data={data} />
      </div>
    </div>
  );
};

TimeLineTable.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  formattedData: PropTypes.shape({
    length: PropTypes.number.isRequired,
  }).isRequired,
  duration: PropTypes.number.isRequired,
};
export default TimeLineTable;
