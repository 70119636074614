import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const Disconnect = ({ t, show, onHide, onDisconnectClick, disconnectText }) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header>
      <Modal.Title> {t('_disconnect_teams_message_')} </Modal.Title>
    </Modal.Header>

    <Modal.Footer className="justify-content-between">
      <Button variant="danger" onClick={onDisconnectClick}>
        <FontAwesomeIcon icon={faTimesCircle} />
        {` ${t(disconnectText)}`}
      </Button>
      <Button variant="success" onClick={onHide}>
        {`${t('_continue_')} `}
        <FontAwesomeIcon icon={faPlayCircle} />
      </Button>
    </Modal.Footer>
  </Modal>
);

Disconnect.defaultProps = {
  disconnectText: '_disconnect_all_teams_',
};
Disconnect.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onDisconnectClick: PropTypes.func.isRequired,
  disconnectText: PropTypes.string,
};
export default Disconnect;
