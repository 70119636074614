import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const AlertMessage = ({ message, t, onConfirm }) => (
  <div className="alert-message">
    <div className="alert-message-header">
      <b>{message.title}</b>
    </div>
    <div className="alert-message-body">
      <b>{message.text}</b>
    </div>
    <div className="alert-message-footer">
      <Button variant="success" onClick={onConfirm}>
        <span>{t('_ok_')}</span>
      </Button>
    </div>
  </div>
);
AlertMessage.defaultProps = {
  message: null,
};
AlertMessage.propTypes = {
  t: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
};
export default AlertMessage;
