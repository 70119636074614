// eslint-disable-next-line import/no-cycle
import jwtHelper from 'common/jwt/jwtHelper';
import i18n from 'i18next';

class Security {
  configure(store) {
    if (store) {
      this.store = store;
    }
  }

  isAuthorized() {
    const token = jwtHelper.getToken();
    return token && !jwtHelper.isTokenExpired(token);
  }

  login(token) {
    jwtHelper.setToken(token);
  }

  logout() {
    jwtHelper.deleteToken();
  }

  getToken() {
    return jwtHelper.getToken();
  }

  getUser() {
    const token = jwtHelper.getToken();
    let user = {};
    if (token && this.isAuthorized()) {
      user = jwtHelper.decodeToken(token);
    }
    return user;
  }

  setLanguage(languages) {
    const token = jwtHelper.getToken();
    let current = 'en';
    let user = {};
    /* istanbul ignore else */
    if (token && this.isAuthorized()) {
      user = jwtHelper.decodeToken(token);
      const language = languages.find(
        (lng) => lng._id === user.language || lng.key == user.language,
      );
      language && (current = language.key.split('_')[0]);
    }
    i18n.changeLanguage(current);
  }
}

const security = new Security();

export default security;
