import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { NEXUS_CLIENT_ID, TEAMS, TRAINER_STEP } from 'config/constants';
import Alarm from 'components/Trainer/Alarm';
import Indicators from 'components/Trainer/Indicators';
import JobInfoList from 'components/Trainer/JobInfoList';
import EnergyGenerator from 'components/Trainer/EnergyGenerator';
import SolarReceptor from 'components/Trainer/SolarReceptor';
import Lift from 'components/Trainer/Lift';
import Actions from 'components/Trainer/Actions';
import External from 'components/Trainer/External';
import DefectiveSystem from 'components/Trainer/DefectiveSystem';
import Totals from 'components/Trainer/Totals';
import TimeLine from 'components/Trainer/TimeLine';
import Achievements from 'components/Trainer/Nexus/Achievements';
import Nexus from 'components/Trainer/Nexus/Nexus';
import Map from 'components/Trainer/Nexus/Map';
import Resources from 'components/Trainer/Nexus/Resources';
import globeIcon from 'assets/images/globe.png';
import { getActiveTeam } from 'utils/resultHelper';

const Trainer = ({ records, fullTeams }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(getActiveTeam(records));
  const [currentTime, setCurrentTime] = useState(0);
  const changeTime = (step = 1) => {
    const steps = Object.keys(records[active]);
    let time = currentTime + TRAINER_STEP * step;
    const last = parseInt(steps[steps.length - 1]);
    if (time >= last) {
      time = last;
    } else if (time <= 0) {
      time = 0;
    }
    if (records[active][time]) {
      setCurrentTime(time);
    }
  };
  const getDisplayClientId = (clientId) => {
    if (!fullTeams.length) {
      return clientId;
    }
    const team = fullTeams.find((team) => team.clientId === clientId) || {
      displayClientId: clientId,
    };
    return team.displayClientId;
  };
  const currentActive =
    records[active] && records[active][currentTime] && records[active][currentTime].eventData;
  const isNexus = !!records.nexus;
  const isActiveNexus = active === NEXUS_CLIENT_ID;
  return currentActive ? (
    <Container className="trainer-screen d-print-none">
      <Row>
        <ButtonGroup className="trainer-screen-switch" toggle>
          {TEAMS.map((team) => (
            <Button
              variant="light"
              key={`team-client-${team.clientId}`}
              onClick={() => setActive(team.clientId)}
              disabled={!records[team.clientId] || !records[team.clientId][0]}
              className={`${active == team.clientId ? 'active' : ''}`}
            >
              {t('_team_')} {getDisplayClientId(team.clientId)}
            </Button>
          ))}
          {isNexus && (
            <Button
              variant="light"
              key="team-client-nexus"
              onClick={() => setActive(NEXUS_CLIENT_ID)}
              className={`${active == NEXUS_CLIENT_ID ? 'active' : ''}`}
            >
              <img alt="" src={globeIcon} width="20" />
            </Button>
          )}
        </ButtonGroup>
      </Row>
      {!isActiveNexus && (
        <Row className="trainer-screen-body mt-2">
          <Col xs={6}>
            <Row>
              <Col>
                <JobInfoList t={t} infoJob={currentActive.infoJob} />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Indicators t={t} indicators={currentActive.indicators} />
              </Col>
              <Col>
                <Alarm t={t} alarm={currentActive.alarm} />
              </Col>
            </Row>
          </Col>
          <Col xs={2}>
            <Row>
              <Col>
                <EnergyGenerator generator={currentActive.generator} />
              </Col>
            </Row>
            <Row>
              <Col xs={10}>
                <SolarReceptor t={t} solarReceptor={currentActive.solarReceptor} />
              </Col>
            </Row>
          </Col>
          <Col xs={2}>
            <Lift t={t} lift={currentActive.lift} />
          </Col>
          <Col xs={2}>
            <Row>
              <Actions t={t} actions={currentActive.actions} />
            </Row>
            <Row>
              <External t={t} external={currentActive.external} />
            </Row>
            <Row>
              <DefectiveSystem t={t} defectiveSystem={currentActive.defectiveSystem} />
            </Row>
          </Col>
        </Row>
      )}
      {isActiveNexus && (
        <Row className="trainer-screen-body nexus mt-2">
          <div className="observe-simulation-nexus-block block-first">
            <Achievements t={t} achievements={currentActive.achievements} />
            <Nexus t={t} nexus={currentActive.nexus} />
          </div>
          <div className="observe-simulation-nexus-block block-second">
            <Resources resources={currentActive.resources} />
          </div>
          <div className="observe-simulation-nexus-block block-third">
            <Map
              mapData={currentActive.map}
              nxpData={currentActive.nxps}
              teams={Object.keys(records)
                .map(Number)
                .filter((item) => !isNaN(item))}
            />
          </div>
        </Row>
      )}
      <Row>
        <Col xs={9}>
          <Row>
            <Totals teams={records} currentTime={currentTime} />
          </Row>
        </Col>
      </Row>
      <Row>
        <TimeLine currentTime={currentTime} team={records[active]} changeTime={changeTime} />
      </Row>
    </Container>
  ) : null;
};

Trainer.defaultProps = {
  records: {},
};
Trainer.propTypes = {
  fullTeams: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  records: PropTypes.shape({
    nexus: PropTypes.shape({}),
  }),
};
export default Trainer;
