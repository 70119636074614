import PropTypes from 'prop-types';

export const userPT = PropTypes.shape({
  company: PropTypes.string.isRequired,
  id: PropTypes.number,
  username: PropTypes.string.isRequired,
  dateLoggedIn: PropTypes.string,
  active: PropTypes.bool.isRequired,
  _id: PropTypes.string.isRequired,
  units: PropTypes.any.isRequired,
});

export const usersPT = PropTypes.arrayOf(userPT);

export default usersPT;
