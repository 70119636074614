import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/TimeLine/Components/SectionHeader';
import SectionRow from 'components/TimeLine/Components/SectionRow';
import ChartAreaRow from 'components/TimeLine/Components/ChartAreaRow';
import NumberRow from 'components/TimeLine/Components/NumberRow';
import BooleanRow from 'components/TimeLine/Components/BooleanRow';
import AlarmRow from 'components/TimeLine/Components/AlarmRow';
import { MAX_VALUES } from 'config/constants';

const Energy = ({ t, data, formattedData }) => {
  const stepCount = 20;
  const duration = formattedData.keys[formattedData.length - 1] * 1;
  const step = parseInt(duration / stepCount);
  return (
    <div className="time-line-view-body">
      <SectionHeader t={t} title="_energy_" />
      <SectionRow t={t} title="_generator_" doubleLine isChart>
        <ChartAreaRow
          data={formattedData.chartData.energy.generator}
          color="#3AAA35"
          maxValue={MAX_VALUES.GENERATOR_ENERGY}
          height={56}
        />
      </SectionRow>
      <SectionRow t={t} title="_solar_receptor_" isChart>
        <ChartAreaRow
          data={formattedData.chartData.energy.solarReceptor}
          color="#b2b2b2"
          maxValue={MAX_VALUES.SOLAR_ENERGY}
          height={28}
          inverted
        />
      </SectionRow>
      <SectionRow
        t={t}
        title="_full_accus_in_main_reserve_"
        data={formattedData.errorPoints.energy.accusInMain}
      >
        <NumberRow
          step={step}
          stepCount={stepCount}
          data={data}
          cellKey="accusInMain"
          groupKey="energy"
          colored
        />
      </SectionRow>
      <SectionRow t={t} title="_full_accus_in_support_reserve_">
        <NumberRow
          step={step}
          stepCount={stepCount}
          data={data}
          cellKey="accusInSupport"
          groupKey="energy"
        />
      </SectionRow>
      <SectionRow t={t} title="_full_accus_in_solar_receptor_">
        <NumberRow
          step={step}
          stepCount={stepCount}
          data={data}
          cellKey="accusInSolar"
          groupKey="energy"
        />
      </SectionRow>
      <SectionRow t={t} title="_alarm_">
        <AlarmRow data={formattedData.chartData.energy.alarm} length={formattedData.length} />
      </SectionRow>
      <SectionRow t={t} title="_energy_consumption_high_">
        <BooleanRow
          length={formattedData.length}
          data={formattedData.chartData.energy.energyConsumptionHigh}
          cellKey="energyConsumptionHigh"
          groupKey="energy"
          color="#b2b2b2"
        />
      </SectionRow>
      <SectionRow t={t} title="_warnings_activated_">
        <BooleanRow
          length={formattedData.length}
          data={formattedData.chartData.energy.warnings}
          cellKey="warnings"
          groupKey="energy"
          color="#b2b2b2"
        />
      </SectionRow>
    </div>
  );
};

Energy.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  formattedData: PropTypes.shape({
    length: PropTypes.number,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    errorPoints: PropTypes.shape({
      energy: PropTypes.shape({
        accusInMain: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    chartData: PropTypes.shape({
      energy: PropTypes.shape({
        solarReceptor: PropTypes.arrayOf(PropTypes.shape({})),
        generator: PropTypes.arrayOf(PropTypes.shape({})),
        alarm: PropTypes.arrayOf(PropTypes.shape({})),
        energyConsumptionHigh: PropTypes.arrayOf(PropTypes.shape({})),
        warnings: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
};
export default Energy;
