import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import triangle from 'assets/icons/triangle.png';
import circle from 'assets/icons/circle.png';
import square from 'assets/icons/square.png';
import { NEXUS_CLIENT_ID, TAGS } from 'config/constants';

const Shape = ({ addTag, currentGame }) => {
  const [disabled, setDisabled] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const createTimer = (id, gameId) => {
    addTag(id);
    setDisabled({ ...disabled, [gameId]: true });
    setTimeout(() => {
      setDisabled((prev) => ({ ...prev, [gameId]: false }));
    }, TAGS.DISABLE_TIME);
  };

  return (
    <div className="observe-simulation-shape">
      <div className="observe-simulation-shape-tools">
        <Button
          className="observe-simulation-shape-tools-triangle"
          onClick={() => createTimer(TAGS.TRIANGLE, currentGame)}
          disabled={disabled[currentGame]}
        >
          <img alt="" src={triangle} />
        </Button>
        <Button
          className="observe-simulation-shape-tools-square"
          onClick={() => createTimer(TAGS.SQUARE, currentGame)}
          disabled={disabled[currentGame]}
        >
          <img alt="" src={square} />
        </Button>
        <Button
          className="observe-simulation-shape-tools-circle"
          onClick={() => createTimer(TAGS.CIRCLE, currentGame)}
          disabled={disabled[currentGame]}
        >
          <img alt="" src={circle} />
        </Button>
      </div>
    </div>
  );
};
Shape.propTypes = {
  addTag: PropTypes.func.isRequired,
  currentGame: PropTypes.oneOf(PropTypes.number, NEXUS_CLIENT_ID).isRequired,
};
export default Shape;
