import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faPlay } from '@fortawesome/free-solid-svg-icons';

const TransferUnit = ({ t, currentUnits, masterUnits, submitHandler, isBalance }) => {
  const maxUnits = parseFloat(currentUnits) + parseFloat(masterUnits);
  const units = [
    { key: 1, value: 1 },
    {
      key: 10,
      value: 10,
    },
    { key: '_all_', value: maxUnits },
  ];
  const [myCurrentUnits, setMyCurrentUnits] = useState(parseFloat(currentUnits));
  const [myMasterUnits, setMyMasterUnits] = useState(parseFloat(masterUnits));
  const moveUnits = useCallback(
    (unitDiff, toMaster) => {
      let newMaster =
        toMaster && parseFloat(maxUnits) === parseFloat(unitDiff) ? parseFloat(unitDiff) : 0;
      let newCurrent =
        !toMaster && parseFloat(maxUnits) === parseFloat(unitDiff) ? parseFloat(unitDiff) : 0;
      if (toMaster) {
        newMaster =
          parseFloat(myMasterUnits) + parseFloat(unitDiff) >= parseFloat(maxUnits)
            ? parseFloat(maxUnits)
            : parseFloat(myMasterUnits) + parseFloat(unitDiff);
        setMyMasterUnits(parseFloat(newMaster));
        setMyCurrentUnits(parseFloat(maxUnits) - parseFloat(newMaster));
      } else {
        newCurrent =
          parseFloat(myCurrentUnits) + parseFloat(unitDiff) >= parseFloat(maxUnits)
            ? parseFloat(maxUnits)
            : parseFloat(myCurrentUnits) + parseFloat(unitDiff);
        setMyMasterUnits(parseFloat(maxUnits) - parseFloat(newCurrent));
        setMyCurrentUnits(parseFloat(newCurrent));
      }
    },
    [maxUnits, myCurrentUnits, myMasterUnits],
  );
  return (
    <Form
      method="post"
      className="mt-5 transfer-form"
      onSubmit={(e) => {
        e.preventDefault();
        submitHandler(myCurrentUnits, myMasterUnits);
      }}
    >
      <Row>
        <Col xs={{ span: 9, offset: 1 }}>
          {isBalance ? <Alert variant="dark">{t('_your_units_was_transfered_')}</Alert> : null}
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3, offset: 1 }}>
          <div className="title mt-5 mb-2">
            <span>{t('_units_on_this_server_')}</span>
          </div>
          <Card>
            <Card.Body className="d-flex justify-content-center align-items-center">
              <span>{myCurrentUnits}</span>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}>
          {!isBalance ? (
            <div className="title mt-5 mb-2">
              <span>{t('_transfer_units_block_')}</span>
            </div>
          ) : null}
          {!isBalance
            ? units.map((unit) => (
                <Row key={unit.key} className="transfer-form-row my-3">
                  <Col className="d-flex justify-content-center">
                    <Button variant="secondary" block onClick={() => moveUnits(unit.value, false)}>
                      <FontAwesomeIcon icon={faCaretLeft} />
                    </Button>
                  </Col>
                  <Col className="d-flex justify-content-center align-items-center">
                    <span className="unit">{t(unit.key)}</span>
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <Button variant="secondary" block onClick={() => moveUnits(unit.value, true)}>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </Button>
                  </Col>
                </Row>
              ))
            : null}
        </Col>
        <Col xs={3}>
          <div className="title mt-5 mb-2">
            <span>{t('_units_on_master_server_')}</span>
          </div>
          <Card>
            <Card.Body className="d-flex justify-content-center align-items-center">
              <span>{myMasterUnits}</span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Form.Group as={Row} className="my-5">
        <Col xs={{ span: 4, offset: 6 }} className="d-flex justify-content-end">
          <Button
            variant="success"
            block
            className="d-flex justify-content-around align-items-center transfer-button"
            type="submit"
          >
            <span>{`${t(isBalance ? '_ok_' : '_start_transfer_')}`}</span>
            <FontAwesomeIcon icon={faPlay} />
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};
TransferUnit.propTypes = {
  t: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  currentUnits: PropTypes.number.isRequired,
  masterUnits: PropTypes.number.isRequired,
  isBalance: PropTypes.bool.isRequired,
};
export default TransferUnit;
