import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ t, title, up }) => (
  <div className="time-line-view-body-header">
    <div>{up ? t(title).toUpperCase() : t(title)}</div>
    {Array(10)
      .fill(1)
      .map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`time-line-view-body-header-${index}}`}>
          <div className="dashed" />
          <div />
        </div>
      ))}
  </div>
);
SectionHeader.defaultProps = {
  up: false,
};

SectionHeader.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  up: PropTypes.bool,
};
export default SectionHeader;
