import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from 'components/TimeLine/Components/SectionHeader';
import SectionRow from 'components/TimeLine/Components/SectionRow';
import NumberRow from 'components/TimeLine/Components/NumberRow';

const Achievements = ({ t, data, step, stepCount }) => (
  <div className="time-line-view-body">
    <div className="time-line-view-body-achievements" />
    <div className="time-line-view-body-achievements-vertical" />
    <SectionHeader t={t} title="_achievements_" />
    <SectionRow t={t} title="_total_primary_" className="total primary nexus">
      <NumberRow
        step={step}
        stepCount={stepCount}
        data={data}
        cellKey="totalPrimary"
        groupKey="achievements"
      />
    </SectionRow>
    <SectionRow t={t} title="_accomodations_" className="nexus">
      <NumberRow
        step={step}
        stepCount={stepCount}
        data={data}
        cellKey="accommodations"
        groupKey="achievements"
      />
    </SectionRow>
    <SectionRow t={t} title="_food_" className="nexus">
      <NumberRow
        step={step}
        stepCount={stepCount}
        data={data}
        cellKey="food"
        groupKey="achievements"
      />
    </SectionRow>
    <SectionRow t={t} title="_total_secondary_" className="total secondary nexus">
      <NumberRow
        step={step}
        stepCount={stepCount}
        data={data}
        cellKey="totalSecondary"
        groupKey="achievements"
      />
    </SectionRow>
    <SectionRow t={t} title="_karmirium_" className="nexus karmirium-line">
      <NumberRow
        step={step}
        stepCount={stepCount}
        data={data}
        cellKey="karmirium"
        groupKey="achievements"
      />
    </SectionRow>
    <SectionRow t={t} title="_kapuyt_" className="nexus kapuyt-line">
      <NumberRow
        step={step}
        stepCount={stepCount}
        data={data}
        cellKey="kapuyt"
        groupKey="achievements"
      />
    </SectionRow>
    <SectionRow t={t} title="_ciranaguyit_" className="nexus ciranaguyit-line">
      <NumberRow
        step={step}
        stepCount={stepCount}
        data={data}
        cellKey="ciranaguyit"
        groupKey="achievements"
      />
    </SectionRow>
  </div>
);

Achievements.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  step: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
};
export default Achievements;
