import PropTypes from 'prop-types';

export const backupPT = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
);

export const accuPT = PropTypes.shape({
  energy: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
});

export const shaftLevelPT = PropTypes.shape({
  accu: PropTypes.oneOfType([accuPT, PropTypes.object]),
  id: PropTypes.number.isRequired,
  isBlinking: PropTypes.bool,
  isLocked: PropTypes.bool.isRequired,
  status: PropTypes.number.isRequired,
  type: PropTypes.number,
});

export const shaftLevelsPT = PropTypes.arrayOf(shaftLevelPT);

export const shaftPT = PropTypes.shape({
  id: PropTypes.number.isRequired,
  isLocked: PropTypes.bool.isRequired,
  levels: PropTypes.arrayOf(shaftLevelPT).isRequired,
  status: PropTypes.number.isRequired,
});

export const shaftsPT = PropTypes.arrayOf(shaftPT);

export const liftInfoPT = PropTypes.shape({
  mainBackups: backupPT,
  shafts: shaftsPT,
  supportBackups: backupPT,
});

export default liftInfoPT;
