import PropTypes from 'prop-types';

export const adminMissionPT = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  description: PropTypes.string,
  duration: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  script: PropTypes.string.isRequired,
  units: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
  __v: PropTypes.number.isRequired,
  _id: PropTypes.string.isRequired,
});

export const adminMissionsPT = PropTypes.arrayOf(adminMissionPT);

export default adminMissionsPT;
