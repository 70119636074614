import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, Jumbotron, Row, Col } from 'react-bootstrap';
import { videoTeamsPT } from 'components/Mission/lib/PropTypes';

export const teamStatusWasChanged = (current, previous) =>
  !current.find(
    ({ clientId, key, roles }) =>
      !previous.find(
        (team) =>
          team.clientId === clientId &&
          team.key === key &&
          team.roles[0] == roles[0] &&
          team.roles[1] == roles[1] &&
          team.roles[2] == roles[2],
      ),
  );

const Video = ({ t, videoTeams, results }) => {
  const teams = useSelector(
    ({ teams }) => teams.map(({ clientId, key, roles }) => ({ clientId, key, roles })),
    teamStatusWasChanged,
  );
  return (
    <>
      <Card className="mt-4 video-page">
        <Card.Body>
          <Row className="mt-5">
            {teams.map((team) => {
              const isInVideoRoom =
                videoTeams.videoRooms && videoTeams.videoRooms.indexOf(team.key) !== -1;
              const isMain = videoTeams.roles && videoTeams.roles.indexOf(0) !== -1;
              const isSupport = videoTeams.roles && videoTeams.roles.indexOf(1) !== -1;
              const isCommander = videoTeams.roles && videoTeams.roles.indexOf(2) !== -1;
              return (
                <Col className="text-center" key={team.clientId}>
                  <h6>{`${t('_team_')} ${team.clientId}`}</h6>
                  <Jumbotron className="video-page-item">
                    {isInVideoRoom ? (
                      <>
                        <span>{t(results ? '_playback_has_finised_' : '_playing_video_on_')}</span>
                        {isMain && team.roles[0] ? (
                          <>
                            <br />
                            <strong>{t('_0_deck_')}</strong>
                          </>
                        ) : null}
                        {isSupport && team.roles[1] ? (
                          <>
                            <br />
                            <strong>{t('_1_deck_')}</strong>
                          </>
                        ) : null}
                        {isCommander && team.roles[2] ? (
                          <>
                            <br />
                            <strong>{t('_2_deck_')}</strong>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <br />
                        <strong>-</strong>
                        <br />
                        <br />
                      </>
                    )}
                  </Jumbotron>
                </Col>
              );
            })}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
Video.defaultProps = {
  videoTeams: { videoRooms: [], roles: [] },
  results: false,
};
Video.propTypes = {
  videoTeams: videoTeamsPT,
  t: PropTypes.func.isRequired,
  results: PropTypes.bool,
};
export default Video;
