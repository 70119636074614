import PropTypes from 'prop-types';

export const resourcePT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  exist: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  isFile: PropTypes.bool.isRequired,
  script: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  __v: PropTypes.number.isRequired,
  _id: PropTypes.string.isRequired,
});

export const resourcesPT = PropTypes.arrayOf(resourcePT);

export default resourcesPT;
