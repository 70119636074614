import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import JobInfo from 'components/Mission/lib/JobInfo/JobInfo';
import { infoRowPT } from 'components/Mission/lib/JobInfo/PropTypes';

const JobInfoRow = ({ list, label }) => {
  const { t } = useTranslation();
  return (
    <Row className="info-job-row-container mt-1">
      <Col xs={2} className="font-weight-normal">
        {t(label)}
      </Col>
      <Col className="d-flex justify-content-between">
        {list.map((job) => (
          <JobInfo key={job.position} data={job} />
        ))}
      </Col>
    </Row>
  );
};

JobInfoRow.propTypes = {
  list: infoRowPT.isRequired,
  label: PropTypes.string.isRequired,
};

export default JobInfoRow;
