import axios from 'axios';
import { API_URL } from 'config/constants';

export const getLogs = (logCallback) => {
  axios.get(`${API_URL}log`).then(({ data }) => logCallback(data));
};

export const getLog = (id, logCallback) => {
  axios.get(`${API_URL}log/${id}`).then(({ data }) => logCallback(data));
};

export default { getLogs };
