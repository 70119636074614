import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Figure } from 'react-bootstrap';

const Alarm = ({ t, teamIndex }) => {
  const alarm = useSelector(({ teams }) => teams[teamIndex].alarm);
  return (
    <div className="observe-simulation-alarm">
      <Figure className={`d-flex justify-content-center align-items-center ${alarm ? 'on' : ''}`}>
        {t('_alarm_')}
      </Figure>
    </div>
  );
};

Alarm.propTypes = {
  t: PropTypes.func.isRequired,
  teamIndex: PropTypes.number.isRequired,
};
export default Alarm;
