import React from 'react';
import PropTypes from 'prop-types';
import Backup from 'components/Mission/lib/Lift/Backup';
import Shaft from 'components/Mission/lib/Lift/Shaft';

const Lift = ({ t, lift }) => (
  <div className="observe-simulation-lift">
    {lift.mainBackups ? <Backup backups={lift.mainBackups} t={t} isMain /> : null}
    {lift.shafts ? <Shaft shafts={lift.shafts} /> : null}
    {lift.supportBackups ? <Backup backups={lift.supportBackups} t={t} isMain={false} /> : null}
  </div>
);

Lift.propTypes = {
  lift: PropTypes.shape({
    mainBackups: PropTypes.arrayOf(PropTypes.number),
    supportBackups: PropTypes.arrayOf(PropTypes.number),
    shafts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  t: PropTypes.func.isRequired,
};
export default Lift;
