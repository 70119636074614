import axios from 'axios';
import i18n from 'i18next';
import { API_URL, NEXUS_CLIENT_ID } from 'config/constants';
import { orderDataByKey } from 'utils/common';
import {
  INIT_MISSION_LIST,
  ADD_VIDEOS,
  UPDATE_TEAMS,
  UPDATE_TEAM,
  END_GAMES,
  INIT_TEAMS,
  RESET_TEAMS,
  UPDATE_TEAM_KEY,
  UPDATE_TAGS,
  REMOVE_TAG,
  RESET_TAGS,
  UPDATE_NEXUS,
  UPDATE_TIME,
  RESET_NEXUS,
  RESET_GAMES,
} from 'components/Mission/missionActionTypes';

import { formatTimeLine, formatNexus } from 'utils/resultHelper';

export const list = (listCallback) => (dispatch) => {
  axios.get(`${API_URL}mission`).then(({ data }) => {
    data = data.map((mission) => {
      mission.missionType = mission.type;
      mission.type = '_mission_';
      return mission;
    });
    dispatch({
      type: INIT_MISSION_LIST,
      data: orderDataByKey(data),
    });
    /* istanbul ignore else */
    if (listCallback) {
      listCallback();
    }
  });
};

export const video = (language) => (dispatch) => {
  axios.get(`${API_URL}video`).then(({ data }) => {
    data = data.filter((video) => {
      video.type = '_video_';
      video.units = 0;
      return language && video.language == i18n.store.data[language.split('_')[0]].label;
    });
    dispatch({
      type: ADD_VIDEOS,
      data: orderDataByKey(data),
    });
  });
};

export const updateTeams = (teams) => (dispatch) => {
  dispatch({
    type: UPDATE_TEAMS,
    teams,
  });
};

export const updateTeam = (team) => (dispatch) => {
  if (team.clientId == NEXUS_CLIENT_ID) {
    team.clientId = NEXUS_CLIENT_ID;
    dispatch({
      type: UPDATE_NEXUS,
      ...team,
    });
  } else {
    team.clientId = parseInt(team.clientId);
    dispatch({
      type: UPDATE_TEAM,
      team,
    });
  }
};

export const updateTime =
  ({ time }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TIME,
      time,
    });
  };

export const disconnect = (sessionId, disconnectCallback) => {
  axios.post(`${API_URL}sessions/${sessionId}/disconnect`, { sessionId }).then(disconnectCallback);
};

export const disconnectWaitingRoom = (sessionId) => {
  axios.post(`${API_URL}sessions/${sessionId}/disconnectWaitingRoom`, { sessionId });
};

export const playMission = (sessionId, missionId, state, playCallback) => {
  axios.post(`${API_URL}sessions/${sessionId}/missions/${missionId}`, state).then(playCallback);
};

export const getGame = (sessionId, gameId, callback) => (dispatch) => {
  const gameReq = axios
    .get(`${API_URL}sessions/${sessionId}/missions/${gameId}`)
    .then(({ data }) => ({ game: data }));
  const timelineReq = axios
    .get(`${API_URL}sessions/${sessionId}/missions/${gameId}/timeline`)
    .then(({ data }) => ({ timeLine: data }));
  const recordsReq = axios
    .get(`${API_URL}sessions/${sessionId}/missions/${gameId}/records`)
    .then(({ data }) => ({ records: data }));
  return Promise.all([gameReq, timelineReq, recordsReq]).then((result) => {
    let timeLine, records, game;
    result.forEach((result) => {
      if (result.timeLine && Object.keys(result.timeLine).length > 0) {
        timeLine = result.timeLine;
      }
      if (result.records && Object.keys(result.records).length > 0) {
        records = result.records;
      }
      if (result.game) {
        game = result.game;
      }
    });
    // istanbul ignore if
    if (typeof timeLine === 'string') {
      timeLine = JSON.parse(timeLine);
    }
    // istanbul ignore if
    if (typeof records === 'string') {
      records = JSON.parse(records);
    }
    // istanbul ignore else
    if (game) {
      game.timeLine = timeLine;
      game.records = records;
      // istanbul ignore else
      if (timeLine) {
        // istanbul ignore else
        if (timeLine.nexus) {
          game.nexus = formatNexus(timeLine.nexus);
          delete timeLine.nexus;
        }
        game.formattedTimeLine = formatTimeLine(timeLine);
      }
    }
    dispatch({
      type: END_GAMES,
    });
    callback(game);
  });
};

export const sessionState = (sessionId, stateCallback) => {
  axios.get(`${API_URL}sessions/${sessionId}/state`).then(({ data }) => stateCallback(data));
};
export const sessionData = (sessionId, dataCallback) => {
  axios.get(`${API_URL}sessions/${sessionId}/data`).then(({ data }) => dataCallback(data));
};
export const abortSession = (sessionId, roomId, abortCallback) => {
  axios.post(`${API_URL}sessions/${sessionId}/abort/${roomId}`).then(abortCallback);
};

export const completeSession = (sessionId, completeCallback) => {
  axios.post(`${API_URL}sessions/${sessionId}/complete/`).then(completeCallback);
};

export const getLastMission = (sessionId, lastMissionCallback) => (dispatch) => {
  axios.get(`${API_URL}sessions/${sessionId}/missionsLast`).then(({ data }) => {
    dispatch({
      type: END_GAMES,
    });
    lastMissionCallback(data);
  });
};

export const initTeams = () => (dispatch) => {
  dispatch({
    type: INIT_TEAMS,
  });
};
export const resetTeams = () => (dispatch) => {
  dispatch({
    type: RESET_TEAMS,
  });
};

export const updateTeamByKey = (team) => (dispatch) => {
  dispatch({
    type: UPDATE_TEAM_KEY,
    team,
  });
};

export const moveAllToWaiting = (sessionId) => {
  axios.post(`${API_URL}sessions/${sessionId}/allToWaitingRoom`);
};
export const moveToRoom = (sessionId, clientId, roomId, role, position) => {
  axios.post(`${API_URL}sessions/${sessionId}/moveClientToRoom/`, {
    clientId,
    roomId,
    role,
    position,
  });
};

export const updateTags = (tags) => (dispatch) => {
  dispatch({
    type: UPDATE_TAGS,
    tags,
  });
};
export const resetTags = () => (dispatch) => {
  dispatch({
    type: RESET_TAGS,
  });
};

export const removeTag = (tag) => (dispatch) => {
  dispatch({
    type: REMOVE_TAG,
    tag,
  });
};

export const resetNexus = () => (dispatch) => {
  dispatch({
    type: RESET_NEXUS,
  });
};

export const resetGames = () => (dispatch) => {
  dispatch({
    type: RESET_GAMES,
  });
};

export default {
  list,
  getGame,
  video,
  updateTeams,
  updateTeam,
  disconnect,
  playMission,
  sessionState,
  sessionData,
  abortSession,
  completeSession,
  getLastMission,
  initTeams,
  resetTeams,
  updateTeamByKey,
  moveAllToWaiting,
  moveToRoom,
  updateTags,
  removeTag,
  resetNexus,
  resetGames,
};
