import React, { useEffect, useState, useCallback } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useNavbar } from 'common/components';
import downloader from 'utils/downloader';
import { getLogs, getLog } from 'components/Log/logActions';

const Log = () => {
  const { t } = useTranslation();
  const [logs, setLogs] = useState([]);
  const exportFile = useCallback(
    (id) => getLog(id, (data) => downloader.download(data.name, data.content)),
    [],
  );
  useEffect(() => getLogs((logs) => setLogs(logs)), []);
  return (
    <Container>
      {useNavbar(t('_logfiles_'))}
      <Form.Group as={Row} controlId="forOrganization" className="my-2">
        <Form.Label column xs={{ span: 2, offset: 2 }}>
          <strong>{t('_files_for_exports_')}</strong>
        </Form.Label>
        <Col xs={6}>
          {logs.map((log) => (
            <Row key={log.id} className="my-3">
              <Col>
                <Button
                  block
                  variant="light"
                  className="text-left"
                  onClick={() => exportFile(log.id)}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  {`  ${t('_log_file_')} ${t('_history_')} ${
                    log.isCurrent ? t('_in_progress_') : log.id + 1
                  }`}
                </Button>
              </Col>
              <Col className="d-flex align-items-center justify-content-between">
                <span>{t('_start_of_log_')}</span>
                <span>{`${log.date || ''} ${log.time || ''}`}</span>
              </Col>
            </Row>
          ))}
        </Col>
      </Form.Group>
    </Container>
  );
};

export default Log;
