import React, { useState, useCallback } from 'react';
import i18n from 'i18next';
import { InputGroup, FormControl, Button, Jumbotron, Row, Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { API_URL, ROLES, MESSAGE_STATE } from 'config/constants';
import Footer from 'components/Footer/Footer';
import Message from 'components/Message/Message';
import { hideMessage } from 'components/Message/messageActions';
import { login } from 'components/Login/loginActions';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import { getSettingsData } from 'components/User/userActions';
import info from '../../../package';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [captcha, setCaptcha] = useState({ value: '' });
  const [date, setDate] = useState(new Date().getTime());
  const [message, setMessage] = useState(null);
  const [role, setRole] = useState(null);
  const handleLogin = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(
        login(
          { username, password, captcha },
          (user) => {
            getSettingsData(({ languages }) => {
              const currentLanguage = languages.find((ln) => ln._id == user.language);
              currentLanguage && i18n.changeLanguage(currentLanguage.key.substr(0, 2));
              const role = ROLES[user.role];
              dispatch(hideMessage());
              /* istanbul ignore else */
              if (user.message && user.message.state != MESSAGE_STATE.OFF) {
                setMessage(user.message);
                setRole(role);
              } else if (role) {
                setRole(role);
                history.push(role.home);
              }
            });
          },
          () => {
            setDate(new Date().getTime());
            setCaptcha({ value: '' });
          },
        ),
      );
    },
    [dispatch, username, password, captcha, history],
  );
  const handleAlertConfirm = useCallback(() => {
    /* istanbul ignore else */
    if (role) {
      history.push(role.home);
    }
  }, [history, role]);
  return (
    <div className="login-page-container">
      <Jumbotron>
        {message && <AlertMessage message={message} t={t} onConfirm={handleAlertConfirm} />}
        <Message />
        <Form method="post" onSubmit={handleLogin}>
          <Row className="head">
            <Col>
              <Row>
                <h3>{t('_skills_lab_')}</h3>
              </Row>
              <Row>
                <h6>{t('_trainer_login_')}</h6>
              </Row>
            </Col>
          </Row>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUser} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              autoComplete={message ? 'off' : 'on'}
              placeholder={t('_username_')}
              required
              name="username"
              tabIndex={1}
              onChange={(e) => setUserName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faLock} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder={t('_password_')}
              type="password"
              name="password"
              required
              tabIndex={2}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <object
                className="captcha-image"
                aria-label="captcha"
                type="image/svg+xml"
                tabIndex={-1}
                data={`${API_URL}auth/captcha?t=${date}`}
              />
            </InputGroup.Prepend>
            <FormControl
              autoComplete="off"
              placeholder={t('_captcha_text_')}
              required
              name="captcha"
              className="captcha-input"
              value={captcha.value}
              tabIndex={3}
              onChange={(e) => setCaptcha({ value: e.target.value })}
            />
          </InputGroup>
          <Row>
            <Col className="button-bar">
              <span>
                v{info.version}-{info.releaseDate}
              </span>
              <Button size="lg" variant="success" type="submit" tabIndex={4}>
                {t('_log_in_')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Jumbotron>
      <Footer />
    </div>
  );
};

export default Login;
