import React from 'react';
import PropTypes from 'prop-types';

const STATE = {
  red: [1, 2, 3, 4],
  grey: [9],
};
const getColor = (status, isLocked) => {
  let color = 'transparent';
  if (STATE.red.indexOf(status) != -1) {
    color = '#E30613';
  }
  if (STATE.grey.indexOf(status) != -1 || isLocked) {
    color = '#b2b2b2';
  }
  return color;
};

const LiftRow = ({ data, maxLength }) => (
  <div className="time-line-view-body-row-content-row">
    {data.map((item) => (
      <div
        style={{
          width: `${(((item.end - item.start + 1) / maxLength) * 100).toFixed(2)}%`,
          backgroundColor: getColor(item.status, item.isLocked),
        }}
        className="filled"
        key={`time-line-view-body-row-content-number-${item.start}-${item.end}`}
      />
    ))}
  </div>
);

LiftRow.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
      endstatus: PropTypes.number,
    }),
  ).isRequired,
  maxLength: PropTypes.number.isRequired,
};
export default LiftRow;
