import axios from 'axios';
import { API_URL } from 'config/constants';

export const NOOP = () => {};

export const shutDown = (callback = NOOP, errorCallback = NOOP) =>
  axios
    .post(`${API_URL}settings/shutdown`, {})
    .then(({ data }) => callback(data))
    .catch(errorCallback);

export const restart = (callback = NOOP, errorCallback = NOOP) =>
  axios
    .post(`${API_URL}settings/restart`, {})
    .then(({ data }) => callback(data))
    .catch(errorCallback);
