/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { Redirect } from 'react-router-dom';
import security from 'common/security/security';
import { ROUTES, ROLES } from 'config/constants';
import { useFooter } from 'common/components';

const isRoleAllowed = (roles) => roles && -1 !== roles.indexOf(security.getUser().role);
export const AuthController = () =>
  security.isAuthorized() ? (
    <Redirect to={ROLES[security.getUser().role].home} />
  ) : (
    <Redirect to={ROUTES.LOGIN} />
  );

export const withPermissionControl = (WrappedComponent, options) => (props) => {
  const { allowedRoles, home, allowAuthorized, allowUnauthorized } = options;
  const isAuthorized = security.isAuthorized();
  if (allowAuthorized && !isAuthorized) {
    return <Redirect to={ROUTES.LOGIN} />;
  }
  if (allowUnauthorized && isAuthorized) {
    return <Redirect to={home} />;
  }
  if (allowedRoles && (!isAuthorized || !isRoleAllowed(allowedRoles))) {
    return <Redirect to={home} />;
  }
  return (
    <>
      <WrappedComponent {...props} />
      {allowAuthorized ? useFooter() : null}
    </>
  );
};
export default { AuthController, withPermissionControl };
