// TODO: Optimize component
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

const STATE = {
  0: 'yellow',
  1: 'grey',
  2: 'red',
  3: 'green',
};
const ICONS = {
  0: null,
  1: null,
  2: faTimes,
  3: faCheck,
};

const extract = (data) =>
  data.reduce((accumulator, item) => {
    const prevIndex = (accumulator.length || 1) - 1;
    const status = item.status;
    const newObj = { start: item.time - 1, end: item.time, status };
    const prev = accumulator[prevIndex];
    if (prev && prev.status == status) {
      prev.end = item.time;
    } else {
      accumulator.push(newObj);
    }
    return accumulator;
  }, []);

const InfoJobRow = ({ data, rowName, rowLength }) => {
  const step = 100 / rowLength;
  return (
    <div className="time-line-view-body-row-content-info-job">
      {Object.values(data).map((row, role) => {
        if (!row[rowName]) {
          // eslint-disable-next-line react/no-array-index-key
          return <div key={`info-job-row-${rowName}-${role}`} />;
        }
        const item = extract(row[rowName]);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`info-job-row-${rowName}-${role}`}>
            {item.map((part) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`info-job-row-${rowName}-${role}-${part.start}-${part.end}`}
                style={{
                  width: `${(step * (part.end - part.start)).toFixed(2)}%`,
                  left: `${(step * part.start).toFixed(2)}%`,
                }}
                className={`${STATE[part.status]}`}
              >
                <div>{ICONS[part.status] && <FontAwesomeIcon icon={ICONS[part.status]} />}</div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

InfoJobRow.propTypes = {
  data: PropTypes.shape({}).isRequired,
  rowName: PropTypes.string.isRequired,
  rowLength: PropTypes.number.isRequired,
};
export default InfoJobRow;
