import { BASE_SOCKET_SERVER } from 'config/constants';
import Session from './session';

const _store = {};

class SessionManager {
  createSession(id, host = BASE_SOCKET_SERVER) {
    let session = _store[id];
    if (!session) {
      session = new Session(host + id, id);
      _store[id] = session;
    }
    return session;
  }

  getSession(id) {
    return _store[id] || this.createSession(id);
  }

  get(id) {
    return _store[id] || null;
  }
  deleteAllSessions() {
    Object.values(_store).map((session) => {
      session.disconnect();
      this.deleteSession(session.id);
    });
  }

  deleteSession(id) {
    _store[id] = null;
    delete _store[id];
  }
}

const sessionManager = new SessionManager();

export default sessionManager;
