import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import JobInfoRow from 'components/Mission/lib/JobInfo/JobInfoRow';
import { GAME_DECKS } from 'config/constants';
import { deckJobInfoPT } from 'components/Mission/lib/JobInfo/PropTypes';

const DeckJobInfo = ({ data: { infos, jobs }, deck }) => (
  <Row className="deck-job-container">
    <Col
      xs={2}
      className={`deck font-weight-bold d-flex justify-content-center ${
        deck === GAME_DECKS.MAIN.id ? 'align-items-start' : 'align-items-end'
      }`}
    >
      {deck === GAME_DECKS.MAIN.id ? 'M' : 'S'}
    </Col>
    <Col className="info-job-row">
      <JobInfoRow list={infos} label="_infos_short_" />
      <JobInfoRow list={jobs} label="_jobs_" />
    </Col>
  </Row>
);

DeckJobInfo.propTypes = {
  data: deckJobInfoPT.isRequired,
  deck: PropTypes.number.isRequired,
};

export default DeckJobInfo;
