import React from 'react';
import PropTypes from 'prop-types';
import { Figure } from 'react-bootstrap';

const Alarm = ({ t, alarm }) => (
  <div className="observe-simulation-alarm">
    <Figure className={`d-flex justify-content-center align-items-center ${alarm ? 'on' : ''}`}>
      {t('_alarm_')}
    </Figure>
  </div>
);

Alarm.propTypes = {
  t: PropTypes.func.isRequired,
  alarm: PropTypes.bool.isRequired,
};
export default Alarm;
