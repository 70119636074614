import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Figure, Row } from 'react-bootstrap';
import { roundNumber } from 'utils/common';

const Acheivments = ({ t }) => {
  const nexus = useSelector(({ nexus }) => nexus.nexus);
  const data = useMemo(() => nexus, [nexus]);
  return (
    <Row className="observe-simulation-nexus-nexus">
      <Col>
        <Figure>
          <div>
            <strong>{t('_nexus_')}</strong>
          </div>
          <div>
            <div className="observe-simulation-nexus-achievements-board">
              <div className="observe-simulation-nexus-achievements-row">
                <div>{t('_energy_')}</div>
                <div>{roundNumber(data.energy)}</div>
              </div>
              <div className="observe-simulation-nexus-achievements-row">
                <div>{t('_wind_speed_')}</div>
                <div>{data.speed}</div>
              </div>
            </div>
          </div>
        </Figure>
      </Col>
    </Row>
  );
};

Acheivments.propTypes = {
  t: PropTypes.func.isRequired,
};
export default Acheivments;
