import { MISSION_TYPES } from 'config/constants';

const openSquareBracket = '[';
const closeSquareBracket = ']';

// the function returns substring within '[' and ']' valid parentheses if string starts with '['
export const getFolderName = (name) => {
  let folderName = '',
    firstIndex = 0,
    lastIndex = 0,
    array = [];
  if (name[0] == openSquareBracket) {
    for (let i = 0; i < name.length; i++) {
      folderName = '';
      if (name[i] == openSquareBracket) {
        array.push(i);
      } else if (name[i] == closeSquareBracket) {
        lastIndex = i;
        if (array.length != 1) {
          array.pop();
        } else {
          lastIndex = i;
          break;
        }
      }
    }
    folderName = name.slice(firstIndex + 1, lastIndex);
  }
  return folderName;
};

export const getFoldersWithMissions = (missions, type) =>
  missions.reduce(function (acc, mission) {
    let name = '',
      slicedName = '';
    name = getFolderName(mission.name);
    slicedName = mission.name.slice(name.length + 2, mission.name.length);
    if (name.length == mission.name.length - 2) {
      slicedName = mission.name;
    }
    const filter = MISSION_TYPES.getFilterIdByType(mission.type);
    if (name) {
      acc[name] = acc[name] || [];
      if (!type || filter == type) {
        acc[name].push({ ...mission, name: slicedName });
      }
      if (!acc[name].length) {
        delete acc[name];
      }
    }
    return acc;
  }, {});
