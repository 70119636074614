import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import List from 'components/Video/List';
import Video from 'components/Video/Video';
import { matchPT, locationPT } from 'routes/PropTypes';
import { ROUTES } from 'config/constants';

const VideoRoute = ({ match, location }) => (
  <Switch>
    <Route path={match.path} exact component={List} />
    <Route path={`${match.path}/edit/:videoId`} exact component={Video} />
    <Route path={`${match.path}/new`} exact component={Video} />
    <Redirect to={{ pathname: ROUTES.VIDEO, state: { from: location } }} />
  </Switch>
);

VideoRoute.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default VideoRoute;
