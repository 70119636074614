/* eslint-disable no-case-declarations */
import {
  END_GAMES,
  RESET_TEAMS,
  INIT_TEAMS,
  UPDATE_NEXUS,
  UPDATE_TAGS,
  REMOVE_TAG,
  RESET_TAGS,
  UPDATE_TIME,
  RESET_NEXUS,
} from 'components/Mission/missionActionTypes';

export const initialState = {
  achievements: { accomodations: 0, food: 0, karmirium: 0, kapuyt: 0, ciranaguyit: 0 },
  clientId: 'nexus',
  map: [],
  nexus: { speed: 0, energy: 0 },
  nxps: [],
  resources: { surface: 0, resource: 0 },
  tags: [],
};

export default function (state = JSON.parse(JSON.stringify(initialState)), action) {
  switch (action.type) {
    case INIT_TEAMS:
      return JSON.parse(JSON.stringify(initialState));
    case END_GAMES:
      return JSON.parse(JSON.stringify(initialState));
    case RESET_TEAMS:
      return JSON.parse(JSON.stringify(initialState));
    case RESET_NEXUS:
      return JSON.parse(JSON.stringify(initialState));
    case UPDATE_NEXUS:
      return { ...state, ...action.nexus };
    case UPDATE_TAGS:
      const teamTagsU = state.tags || [];
      const tags = (action.tags.nexus || []).filter(
        (tag) =>
          !teamTagsU.find(
            (teamTag) =>
              teamTag.eventTime == tag.eventTime &&
              teamTag.time == tag.time &&
              teamTag.eventData.tagId == tag.eventData.tagId,
          ),
      );
      return { ...state, tags: [...teamTagsU, ...tags] };
    case RESET_TAGS:
      return { ...state, tags: [] };
    case REMOVE_TAG:
      const teamTagsR = state.tags || [];
      const tagToRemove = action.tag.nexus;
      let removedTags = [];
      // istanbul ignore else
      if (tagToRemove) {
        removedTags = teamTagsR.filter(
          (teamTag) =>
            !(
              teamTag.eventTime == tagToRemove.eventTime &&
              teamTag.eventData.tagId == tagToRemove.eventData.tagId
            ),
        );
      }
      return { ...state, tags: removedTags };
    case UPDATE_TIME:
      return { ...state, time: action.time };
    default:
      return state;
  }
}
