import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Button, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { SESSION_KEY_TYPE } from 'config/constants';

const KeysComponent = ({ keys, regenerate, sessionKey, btnDisabled }) => {
  const { t } = useTranslation();
  const renderKey = (keyType, clientId) => {
    if (keyType == SESSION_KEY_TYPE.ROOM) {
      return <span>{`${t('_team_')} ${clientId}`}</span>;
    } else if (keyType == SESSION_KEY_TYPE.MISSION_CONTROL) {
      return <span>{`${t('_mission_control_')}`}</span>;
    } else if (keyType == SESSION_KEY_TYPE.WAITING_ROOM) {
      return <span>{`${t('_waiting_lounge_')}`}</span>;
    }
  };
  return (
    <Form.Group as={Row} controlId="formSessionKeys" className="session-form-gerenerate-key">
      <Form.Label column sm={{ span: 1, offset: 0 }} md={{ span: 2, offset: 1 }}>
        {t('_keys_')}
      </Form.Label>
      <Col lg={3} md={3} sm={3} className="session-form-gerenerate-key-button mb-3">
        <Button onClick={regenerate} disabled={btnDisabled}>
          <FontAwesomeIcon icon={faSyncAlt} />
          {` ${t('_generate_keys_')}`}
        </Button>
      </Col>
      <Col className="session-keys-container d-flex justify-content-end" xs={8} md={6} lg={4}>
        <ListGroup horizontal>
          <ListGroup.Item className="custom-list-group-item">
            <div className="key-column session-key">
              <strong>{sessionKey}</strong>
            </div>
            <div className="team-column">
              <span>{`${t('_session_')}`}</span>
            </div>
          </ListGroup.Item>
          {keys.map((key) => (
            <ListGroup.Item className="custom-list-group-item" key={key._id} disabled={!key.isOpen}>
              <div className="key-column">
                <strong>{key.key}</strong>
              </div>
              <div className="team-column">{renderKey(key.keyType, key.clientId)}</div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
    </Form.Group>
  );
};

KeysComponent.propTypes = {
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      clientId: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      dateUpdated: PropTypes.string,
      dateCreated: PropTypes.string,
    }).isRequired,
  ).isRequired,
  regenerate: PropTypes.func.isRequired,
  sessionKey: PropTypes.string.isRequired,
  btnDisabled: PropTypes.bool.isRequired,
};

export default KeysComponent;
