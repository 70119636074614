// eslint-disable-next-line import/no-cycle
import { logout } from 'components/Login/loginActions';

let options = {
  authHeader: 'Authorization',
  authPrefix: 'Bearer ',
  authStorageKey: 'jwtToken',
  storage: localStorage,
  authRejectStatus: 401,
};

const getJwtConfig = () => options;

export const requestInterceptor = function (config, request) {
  const token = this.getToken();
  if (token) {
    if (request.headers[request.method]) {
      request.headers[request.method][config.authHeader] = config.authPrefix + token;
    } else {
      request.headers[config.authHeader] = config.authPrefix + token;
    }
  }
  return request;
};

export const responseInterceptor = function (config, error) {
  if (error.response && config.authRejectStatus === error.response.status) {
    if (this.store) {
      const logoutFunc = logout(() => {
        window.location.replace('/');
      });
      logoutFunc(() => {});
    }
  }
  return Promise.reject(error);
};

class JwtHelper {
  configure(store, config) {
    if (store) {
      this.store = store;
    }
    if (config) {
      options = config;
    }
  }

  urlBase64Decode(str) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw new Error('Illegal base64url string!');
    }
    return decodeURIComponent(escape(window.atob(output)));
  }

  decodeToken(token) {
    let parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }

    let decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    return JSON.parse(decoded);
  }

  getTokenExpirationDate(token) {
    let decoded;
    decoded = this.decodeToken(token);

    if (typeof decoded.exp === 'undefined') {
      return null;
    }

    let d = new Date(0); // The 0 here is the key, which sets the date to the epoch
    d.setUTCSeconds(decoded.exp);

    return d;
  }

  isTokenExpired(token, offsetSeconds) {
    try {
      let d = this.getTokenExpirationDate(token);
      offsetSeconds = offsetSeconds || 0;
      if (d === null) {
        return false;
      }

      // Token expired?
      return !(d.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
    } catch (e) {
      return true;
    }
  }

  getRequestInterceptor(config) {
    config = config || getJwtConfig();
    return requestInterceptor.bind(this, config);
  }

  getErrorResponseInterceptor(config) {
    config = config || getJwtConfig();
    return responseInterceptor.bind(this, config);
  }

  setToken(token, config) {
    config = config || getJwtConfig();
    config.storage.setItem(config.authStorageKey, token);
  }

  getToken(config) {
    config = config || getJwtConfig();
    return config.storage.getItem(config.authStorageKey);
  }

  deleteToken(config) {
    config = config || getJwtConfig();
    config.storage.removeItem(config.authStorageKey);
  }
}

const jwtHelper = new JwtHelper();

export default jwtHelper;
