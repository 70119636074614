import React from 'react';
import PropTypes from 'prop-types';
import Achievements from 'components/Mission/lib/Nexus/Achievements';
import Nexus from 'components/Mission/lib/Nexus/Nexus';
import Resources from 'components/Mission/lib/Nexus/Resources';
import Map from 'components/Mission/lib/Nexus/Map';

const NexusTab = ({ t, connectedTeams }) => (
  <div className="observe-simulation-nexus">
    <div className="observe-simulation-nexus-block block-first">
      <Achievements t={t} />
      <Nexus t={t} />
    </div>
    <div className="observe-simulation-nexus-block block-second">
      <Resources />
    </div>
    <div className="observe-simulation-nexus-block block-third">
      <Map connectedTeams={connectedTeams} />
    </div>
  </div>
);

NexusTab.propTypes = {
  t: PropTypes.func.isRequired,
  connectedTeams: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default NexusTab;
