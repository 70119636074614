import React from 'react';
import PropTypes from 'prop-types';

const COLORS = {
  GREY: '#b2b2b2',
};

const getColor = ({ started }) => {
  let color = 'transparent';
  if (started) {
    color = COLORS.GREY;
  }
  return color;
};

const LogEventRow = ({ data, duration }) => (
  <div className="time-line-view-body-row-content-row">
    {data.map((item) => (
      <div
        style={{
          width: `${(((item.end - item.start + 1) / duration) * 100).toFixed(2)}%`,
          backgroundColor: getColor(item),
        }}
        className="filled"
        key={`time-line-view-body-row-content-number-alarm-${item.start}-${item.end}`}
      />
    ))}
  </div>
);

LogEventRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  duration: PropTypes.number.isRequired,
};
export default LogEventRow;
