import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Backup from 'components/Mission/lib/Lift/Backup';
import Shaft from 'components/Mission/lib/Lift/Shaft';

const Lift = ({ t, teamIndex }) => {
  const lift = useSelector(({ teams }) => teams[teamIndex].lift);
  return (
    <div className="observe-simulation-lift">
      {lift.mainBackups ? <Backup backups={lift.mainBackups} t={t} isMain /> : null}
      {lift.shafts ? <Shaft shafts={lift.shafts} /> : null}
      {lift.supportBackups ? <Backup backups={lift.supportBackups} t={t} isMain={false} /> : null}
    </div>
  );
};

Lift.propTypes = {
  teamIndex: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};
export default Lift;
