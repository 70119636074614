import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';

import { resourcePT } from 'components/Resource/PropTypes';
import InfoBar from 'common/UploadFile/InfoBar';
import CoffeScript from 'common/CoffeeScript/CoffeeScript';

const Editor = React.memo(CoffeScript);

const EditScript = ({ resource, t, changeInfo, changeScript }) => (
  <>
    <InfoBar
      name={resource.name}
      info={resource.info}
      t={t}
      changeInfo={changeInfo}
      infoText="_enter_resource_info_"
    />
    <Form.Group as={Row} className="my-4">
      <Form.Label column xs={{ span: 2, offset: 2 }}>
        <strong>{t('_script_')}</strong>
      </Form.Label>
      <Col xs={6}>
        <Editor script={resource.script} changeScript={changeScript} />
      </Col>
    </Form.Group>
  </>
);

EditScript.propTypes = {
  resource: resourcePT.isRequired,
  t: PropTypes.func.isRequired,
  changeInfo: PropTypes.func.isRequired,
  changeScript: PropTypes.func.isRequired,
};

export default EditScript;
