import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';

const InfoBar = ({ name, info, changeInfo, t, infoText }) => (
  <>
    <Form.Group as={Row} controlId="forOrganization" className="my-2">
      <Form.Label column xs={{ span: 2, offset: 2 }}>
        <strong>{t('_name_')}</strong>
      </Form.Label>
      <Col xs={6}>
        <Form.Label>
          <strong>{name}</strong>
        </Form.Label>
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="forInfo" className="my-2">
      <Form.Label column xs={{ span: 2, offset: 2 }}>
        <strong>{t('_info_')}</strong>
      </Form.Label>
      <Col xs={6}>
        <Form.Control
          value={info}
          as="textarea"
          rows="2"
          placeholder={t(infoText)}
          type="text"
          name="info"
          onChange={(e) => changeInfo(e.target.value)}
        />
      </Col>
    </Form.Group>
  </>
);

InfoBar.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired,
  changeInfo: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default InfoBar;
