import {
  INIT_USER_LIST,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
} from 'components/User/userActionTypes';

export const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case INIT_USER_LIST:
      return action.data;
    case ADD_USER:
      return [...state, action.user];
    case DELETE_USER:
      return state.filter((user) => user._id !== action.userId);
    case UPDATE_USER:
      return state.map((user) => {
        if (user._id == action.user._id) {
          return {
            ...user,
            ...action.user,
          };
        }
        return user;
      });
    default:
      return state;
  }
}
