import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faHistory, faCheck } from '@fortawesome/free-solid-svg-icons';

const ButtonBar = ({
  sessionId,
  isFormFilled,
  saveActionType,
  showHistory,
  historyExist,
  missionDelete,
  showReadMeta,
  readMeta,
}) => {
  const { t } = useTranslation();
  return (
    <Row className="form-footer-button-bar">
      <Col xs={6}>
        {sessionId ? (
          <Link to={`/sessions/delete/${sessionId}`}>
            <Button variant="danger" className="col-4">
              <FontAwesomeIcon icon={faTrashAlt} />
              {`  ${t('_delete_')}`}
            </Button>
          </Link>
        ) : null}
        {missionDelete ? (
          <Button variant="danger" className="col-4" onClick={missionDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
            {`  ${t('_delete_')}`}
          </Button>
        ) : null}
      </Col>
      <Col xs={6} className="right-bar">
        <Row>
          <Col>
            {showHistory ? (
              <Link to={`/sessions/${sessionId}/missions/history`}>
                <Button variant="outline-secondary" disabled={!historyExist}>
                  <FontAwesomeIcon icon={faHistory} />
                  {` ${t('_view_history_')}`}
                </Button>
              </Link>
            ) : null}
            {showReadMeta ? (
              <Button variant="secondary" onClick={readMeta}>
                {` ${t('_read_meta_data_')}`}
              </Button>
            ) : null}
          </Col>
          <Col>
            <Button variant="success" disabled={!isFormFilled} type={saveActionType}>
              <FontAwesomeIcon icon={faCheck} />
              {` ${t('_save_changes_')}`}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ButtonBar.defaultProps = {
  isFormFilled: false,
  sessionId: '',
  showHistory: true,
  historyExist: true,
  missionDelete: null,
  showReadMeta: false,
  readMeta: null,
};

ButtonBar.propTypes = {
  isFormFilled: PropTypes.bool,
  sessionId: PropTypes.string,
  saveActionType: PropTypes.string.isRequired,
  showHistory: PropTypes.bool,
  showReadMeta: PropTypes.bool,
  historyExist: PropTypes.bool,
  missionDelete: PropTypes.func,
  readMeta: PropTypes.func,
};

export default ButtonBar;
