import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Container, Overlay, Popover, ListGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SESSION_KEY_TYPE } from 'config/constants';
import { moveAllToWaiting } from 'components/Mission/missionActions';
import {
  faEye,
  faTimesCircle,
  faClock,
  faUserFriends,
  faCommentDots,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

const AssignTeamsFooter = ({
  t,
  sessionId,
  teams,
  onAssignTeamsSubmit,
  isDisconnectActive,
  disconnect,
  sendMessage,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const [showSendMessage, setShowSendMessage] = useState(false);
  const [message, setMessage] = useState('');
  const popoverRef = useRef(null);
  const sendMessageBtn = useRef(null);
  const handleClick = (event) => {
    setShowPopover(!showPopover);
    setTarget(event.target);
  };

  const handleClose = useCallback(() => {
    setShowPopover(false);
  }, []);
  const waitingLounge = teams.find((x) => x.roomType === SESSION_KEY_TYPE.WAITING_ROOM);
  useEffect(() => {
    const handleClick = ({ target }) => {
      if (showPopover && popoverRef.current && !popoverRef.current.contains(target)) {
        handleClose();
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [handleClose, showPopover]);
  return (
    <Container>
      <Row className="assign-teams-rows">
        <Col>
          <Row className="border-collapse">
            <Col sm={2} className="pr-0 mission-control-members">
              <span>{`${t('_waiting_lounge_')}`}</span>
            </Col>
          </Row>
          <Row className="assign-teams-footer">
            <Col className="d-flex">
              <Row>
                <Col xs="auto">
                  <Button
                    variant="danger"
                    className="mb-3 mb-md-0 mission-control-row-number waiting-lounge-btn"
                    block
                    onClick={disconnect}
                    disabled={!isDisconnectActive}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="assign-teams-footer-icons" />
                    {` ${t('_disconnect_')}`}
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="outline-secondary"
                    className="mission-control-row-number waiting-lounge-btn"
                    block
                    onClick={() => moveAllToWaiting(sessionId)}
                  >
                    <FontAwesomeIcon icon={faClock} className="assign-teams-footer-icons" />
                    {` ${t('_all_teams_to_waiting_lounge_')}`}
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col className="d-flex justify-content-end">
              <Row>
                <Col xs="auto">
                  <>
                    <Button
                      variant="outline-secondary"
                      className="mb-3 mb-md-0 mission-control-row-number waiting-lounge-btn"
                      block
                      onClick={handleClick}
                    >
                      <FontAwesomeIcon icon={faUserFriends} className="assign-teams-footer-icons" />
                      <span className="text-left pl-1">
                        {` ${t('_waiting_')}`} {waitingLounge ? waitingLounge.clients.length : 0}
                      </span>
                      <FontAwesomeIcon icon={faEye} className="assign-teams-footer-icons ml-auto" />
                    </Button>
                    <Overlay
                      show={showPopover}
                      target={target}
                      placement="right"
                      containerPadding={20}
                    >
                      <Popover id="popover-positioned-right" className="waiting-popover">
                        <Popover.Content ref={popoverRef}>
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="popover-close-icons float-right"
                            onClick={handleClose}
                          />
                          <strong>{t('_in_waiting_lounge_')}</strong>
                          <ListGroup className="mb-3">
                            {waitingLounge &&
                              waitingLounge.clients
                                .sort((elem1, elem2) =>
                                  elem1.name.toLowerCase() > elem2.name.toLowerCase() ? 1 : -1,
                                )
                                .map((item) => (
                                  <ListGroup.Item
                                    className="list-group-item-overlay"
                                    key={item.name}
                                  >
                                    {item.name}
                                  </ListGroup.Item>
                                ))}
                          </ListGroup>
                        </Popover.Content>
                      </Popover>
                    </Overlay>
                  </>
                </Col>
                <Col xs="auto">
                  <Overlay
                    show={showSendMessage}
                    target={sendMessageBtn.current}
                    placement="top"
                    containerPadding={20}
                  >
                    <Popover className="waiting-message-popover">
                      <Popover.Content>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="popover-close-icons float-right"
                          onClick={() => setShowSendMessage(false)}
                        />
                        <strong>{t('_message_text_')}</strong>
                        <Form.Control
                          className="mt-3"
                          value={message}
                          onChange={({ target }) => setMessage(target.value)}
                        />{' '}
                        <br />
                        <Button
                          variant="success"
                          className="float-right"
                          onClick={() => {
                            sendMessage({
                              room: waitingLounge.key,
                              message,
                            });
                            setShowSendMessage(false);
                            setMessage('');
                          }}
                          disabled={message.length < 1}
                        >
                          {t('_send_message_')}
                        </Button>
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                  <Button
                    variant="outline-secondary"
                    className="mission-control-row-number waiting-lounge-btn float-right"
                    block
                    onClick={() => setShowSendMessage(true)}
                    ref={sendMessageBtn}
                    disabled={!waitingLounge || waitingLounge.clients.length == 0}
                  >
                    <FontAwesomeIcon icon={faCommentDots} className="assign-teams-footer-icons" />
                    {` ${t('_send_message_')}`}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="assign-teams-rows justify-content-end">
        <Col xs="auto" className="pr-0">
          <Button
            variant="success"
            block
            type="submit"
            className="mission-control-row-number waiting-lounge-btn"
            onClick={onAssignTeamsSubmit}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span className="assign-teams-span">{`${t('_ok_')}`}</span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

AssignTeamsFooter.propTypes = {
  t: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  onAssignTeamsSubmit: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
  isDisconnectActive: PropTypes.bool.isRequired,
  disconnect: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
};
export default AssignTeamsFooter;
