import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import List from 'components/User/List';
import User from 'components/User/User';
import { matchPT, locationPT } from 'routes/PropTypes';
import { ROUTES } from 'config/constants';

const UserComponent = ({ match, location }) => (
  <Switch>
    <Route path={`${match.path}/new`} exact component={User} />
    <Route path={`${match.path}/edit/:userId`} exact component={User} />
    <Route path={match.path} exact component={List} />
    <Redirect to={{ pathname: ROUTES.USERS, state: { from: location } }} />;
  </Switch>
);

UserComponent.propTypes = {
  match: matchPT.isRequired,
  location: locationPT.isRequired,
};

export default UserComponent;
