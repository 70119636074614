import axios from 'axios';
import { API_URL } from 'config/constants';
import {
  INIT_SESSION_LIST,
  ADD_SESSION,
  UPDATE_SESSION,
  DELETE_SESSION,
} from './sessionActionTypes';

export const list = () => (dispatch) => {
  axios.get(`${API_URL}sessions`).then(({ data }) => {
    dispatch({
      type: INIT_SESSION_LIST,
      data,
    });
  });
};

export const get = (sessionId, getCallback) => {
  axios.get(`${API_URL}sessions/${sessionId}`).then(({ data }) => getCallback(data));
};

export const create = (state, createCallback) => (dispatch) => {
  axios.post(`${API_URL}sessions`, state).then(({ data }) => {
    dispatch({
      type: ADD_SESSION,
      session: data,
    });
    createCallback();
  });
};

export const update = (sessionId, state, updateCallback) => (dispatch) => {
  axios.put(`${API_URL}sessions/${sessionId}`, { ...state }).then(({ data }) => {
    dispatch({
      type: UPDATE_SESSION,
      session: data,
    });
    updateCallback();
  });
};

export const remove = (sessionId, deleteCallback) => (dispatch) => {
  axios.delete(`${API_URL}sessions/${sessionId}`).then(() => {
    dispatch({
      type: DELETE_SESSION,
      sessionId,
    });
    deleteCallback();
  });
};

export const keys = (sessionId, keysCallback) => {
  axios.put(`${API_URL}sessions/${sessionId}/keys`).then(({ data }) => {
    keysCallback(data);
  });
};

export const getMeta = (sessionId, getMetaCallback) => {
  axios.get(`${API_URL}sessions/${sessionId}/meta`).then(({ data }) => getMetaCallback(data));
};

export const removeData = (sessionId, removeDataCallback) => {
  axios.delete(`${API_URL}sessions/${sessionId}/data`).then(removeDataCallback);
};

export const getMissions = (sessionId, getMissionsCallback) => {
  axios
    .get(`${API_URL}sessions/${sessionId}/missions`)
    .then(({ data }) =>
      getMissionsCallback(data.sort((a, b) => new Date(b.date) - new Date(a.date))),
    );
};

export const exportSession = (sessionId, exportMissionCallback) => {
  axios
    .get(`${API_URL}sessions/${sessionId}/export?isArray=true`)
    .then(({ data }) => exportMissionCallback(data));
};

export default {
  list,
  create,
  get,
  update,
  keys,
  getMeta,
  remove,
  removeData,
  getMissions,
  exportSession,
};
