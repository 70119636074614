export default [
  {
    clientId: 1,
    availableRoles: [
      {
        role: 0,
        position: 0,
      },
      {
        role: 0,
        position: 1,
      },
      {
        role: 1,
        position: 0,
      },
      {
        role: 1,
        position: 1,
      },
      {
        role: 2,
        position: 0,
      },
      {
        role: 2,
        position: 1,
      },
    ],
  },
  {
    clientId: 2,
    availableRoles: [
      {
        role: 0,
        position: 0,
      },
      {
        role: 0,
        position: 1,
      },
      {
        role: 1,
        position: 0,
      },
      {
        role: 1,
        position: 1,
      },
      {
        role: 2,
        position: 0,
      },
      {
        role: 2,
        position: 1,
      },
    ],
  },
  {
    clientId: 3,
    availableRoles: [
      {
        role: 0,
        position: 0,
      },
      {
        role: 0,
        position: 1,
      },
      {
        role: 1,
        position: 0,
      },
      {
        role: 1,
        position: 1,
      },
      {
        role: 2,
        position: 0,
      },
      {
        role: 2,
        position: 1,
      },
    ],
  },
  {
    clientId: 4,
    availableRoles: [
      {
        role: 0,
        position: 0,
      },
      {
        role: 0,
        position: 1,
      },
      {
        role: 1,
        position: 0,
      },
      {
        role: 1,
        position: 1,
      },
      {
        role: 2,
        position: 0,
      },
      {
        role: 2,
        position: 1,
      },
    ],
  },
  {
    clientId: 5,
    availableRoles: [
      {
        role: 0,
        position: 0,
      },
      {
        role: 0,
        position: 1,
      },
      {
        role: 0,
        position: 2,
      },
    ],
  },
  {
    clientId: 6,
    availableRoles: [],
  },
];
