import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'react-bootstrap';

const TABS = {
  overall: {
    title: '_overall_performance_',
    key: 'overall_performance',
  },
  human: {
    title: '_human_error_',
    key: 'human_error',
  },
};

const ScoreTabTools = ({ t, activeTab, changeTab, clientIdStart }) => (
  <div className="scores-page-tools mb-2 d-print-none">
    <div className="scores-page-tools-team">
      <Button block>
        {t('_team_')} {`${clientIdStart} - ${clientIdStart + 3}`}
      </Button>
    </div>
    <div className="scores-page-tools-tabs d-print-none">
      <ButtonGroup className="scores-page-tools-tabs-group">
        <Button onClick={() => changeTab(TABS.overall.key)} active={TABS.overall.key == activeTab}>
          {t(TABS.overall.title)}
        </Button>
        <Button onClick={() => changeTab(TABS.human.key)} active={TABS.human.key == activeTab}>
          {t(TABS.human.title)}
        </Button>
      </ButtonGroup>
    </div>
  </div>
);

ScoreTabTools.propTypes = {
  t: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  clientIdStart: PropTypes.number.isRequired,
};
export default ScoreTabTools;
