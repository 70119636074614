import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import SortableTable from 'components/SortableTable/SortableTable';

export const CustomCollapse = ({ correctRows, isReady, playCallback, foldersWithMissions }) => {
  const { t } = useTranslation();
  const [openFolderName, setOpenFolderName] = useState([]);

  const closeOpenFolder = (folderName) => {
    setOpenFolderName(openFolderName.filter((item) => item !== folderName));
  };

  const clickHandler = (missionName) => {
    openFolderName.includes(missionName)
      ? closeOpenFolder(missionName)
      : setOpenFolderName([...openFolderName, missionName]);
  };

  return Object.keys(foldersWithMissions).map((missionName) => (
    <div key={missionName}>
      <div
        role="button"
        tabIndex="-1"
        name="collapse"
        className="arrow-button collapse-button collapse-background"
        variant="light"
        onClick={() => clickHandler(missionName)}
        onKeyDown={() => clickHandler(missionName)}
        aria-controls="example-collapse-text"
        aria-expanded={openFolderName.includes(missionName)}
      >
        <FontAwesomeIcon icon={openFolderName.includes(missionName) ? faCaretDown : faCaretRight} />
        <span>
          <strong>{missionName}</strong>
        </span>
      </div>
      <Collapse in={openFolderName.includes(missionName)}>
        <div>
          <SortableTable
            columns={correctRows(t, isReady, playCallback)}
            data={foldersWithMissions[missionName]}
            additionalClass="mission-page-table"
            excludeHeader
          />
        </div>
      </Collapse>
    </div>
  ));
};

CustomCollapse.propTypes = {
  playCallback: PropTypes.func.isRequired,
  isReady: PropTypes.bool,
  foldersWithMissions: PropTypes.shape().isRequired,
};
export default CustomCollapse;
