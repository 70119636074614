import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import LoadingImage from 'assets/images/loading.gif';

export const Loading = ({ loading }) => (
  <Modal
    show={loading.show}
    onHide={() => void 0}
    backdrop="static"
    keyboard={false}
    centered
    className="app-loading"
  >
    <Image src={LoadingImage} onMouseDown={(e) => e.preventDefault()} />
  </Modal>
);

Loading.propTypes = {
  loading: PropTypes.shape({
    show: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({ loading: state.loading });

export default connect(mapStateToProps, null)(Loading);
