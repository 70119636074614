import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Overlay, Popover, ListGroup } from 'react-bootstrap';
import useBreakpoints from 'components/hooks/useBreakpoints';
import { DECKS, BREAKPOINTS } from 'config/constants';
import availablePositions from 'components/Mission/lib/AvailablePositions/availablePositionsData';
import teamsPT, { teamPT } from 'components/Mission/lib/PropTypes';

const AvailablePositions = ({
  rooms,
  waitingLounge,
  missionControl,
  showPopoverSend,
  targetSend,
  handleCloseSend,
  sendClientToRoom,
  t,
  id,
  placement,
}) => {
  const breakPoint = useBreakpoints();
  const popoverRef = useRef(null);
  useEffect(() => {
    const handleClick = ({ target }) => {
      if (showPopoverSend && popoverRef.current && !popoverRef.current.contains(target)) {
        handleCloseSend();
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [showPopoverSend, popoverRef, handleCloseSend]);
  return (
    showPopoverSend &&
    showPopoverSend == id && (
      <Overlay
        show
        target={targetSend}
        placement={
          breakPoint === BREAKPOINTS.XS || breakPoint === BREAKPOINTS.SM ? 'bottom' : placement
        }
        containerPadding={20}
      >
        <Popover id={`popover-positioned-right-${id}`} className="team-popover team-popover-assign">
          <Popover.Content ref={popoverRef}>
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="popover-close-icons float-right"
              onClick={handleCloseSend}
            />
            <Row>
              <Col xs={8}>
                <Row>
                  {availablePositions.slice(0, 4).map((item, itemIndex) => {
                    const room = rooms.find((room) => room.clientId == item.clientId);
                    return (
                      <Col xs={6} className="mt-2 mb-3" key={`assign-team-${item.clientId}`}>
                        <ListGroup>
                          <>
                            <strong>
                              {t('_to_team_')} {itemIndex + 1}
                            </strong>
                            {item.availableRoles.map((roles) => (
                              <ListGroup.Item
                                disabled={!room}
                                key={`${item.key}_${roles.role}_${roles.position}`}
                                className="list-group-item-overlay"
                                onClick={() =>
                                  room && sendClientToRoom(id, room.key, roles.role, roles.position)
                                }
                              >
                                {DECKS[roles.role]} ({roles.position + 1})
                              </ListGroup.Item>
                            ))}
                          </>
                        </ListGroup>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs={4} className="mt-2 mb-3 d-flex justify-content-between flex-column">
                <ListGroup>
                  <strong>{t('_to_mission_control_')}</strong>
                  {availablePositions[4].availableRoles.map((roles) => (
                    <ListGroup.Item
                      key={`${missionControl.key}-${roles.position}`}
                      className="list-group-item-overlay"
                      onClick={() => sendClientToRoom(id, missionControl.key, 3, roles.position)}
                    >
                      {t('_position_')} {roles.position + 1}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <ListGroup>
                  <strong>{t('_remove_')}</strong>
                  <ListGroup.Item
                    key="remove"
                    className="list-group-item-overlay"
                    onClick={() => sendClientToRoom(id, waitingLounge.key, -1, 1)}
                  >
                    {t('_send_to_waiting_lounge_')}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Popover.Content>
        </Popover>
      </Overlay>
    )
  );
};
AvailablePositions.defaultProps = {
  showPopoverSend: null,
  targetSend: null,
  placement: 'right',
};
AvailablePositions.propTypes = {
  t: PropTypes.func.isRequired,
  rooms: teamsPT.isRequired,
  waitingLounge: teamPT.isRequired,
  missionControl: teamPT.isRequired,
  showPopoverSend: PropTypes.string,
  targetSend: PropTypes.shape({}),
  handleCloseSend: PropTypes.func.isRequired,
  sendClientToRoom: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  placement: PropTypes.string,
};
export default AvailablePositions;
