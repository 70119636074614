import { SHOW_MESSAGE, HIDE_MESSAGE } from 'components/Message/messageActionTypes';
import { MESSAGE } from 'config/constants';

export const initialState = {
  show: false,
  text: '',
  title: '',
  variant: MESSAGE.ERROR,
  showCloseButton: false,
  timeout: 0,
  translate: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        show: true,
        variant: action.variant,
        text: action.text,
        title: action.title,
        showCloseButton: action.showCloseButton,
        timeout: action.timeout || 0,
        translate: action.translate,
      };
    case HIDE_MESSAGE:
      return {
        show: false,
        text: '',
        title: '',
        variant: MESSAGE.ERROR,
        showCloseButton: false,
        timeout: 0,
        translate: true,
      };
    default:
      return state;
  }
}
