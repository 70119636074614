import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import validator from 'utils/validator';
import security from 'common/security/security';
import { LANGUAGES, ROLES } from 'config/constants';
import { toTime, timeToSeconds, goBack } from 'utils/common';
import DeleteComponent from 'common/DeleteComponent/DeleteComponent';
import { useNavbar } from 'common/components';
import { getVideo, uploadFile, updateData, create, remove } from 'components/Video/videoAction';
import UploadBtn from 'common/UploadBtn/UploadBtn';
import Notifier from 'components/Message/Notifier';

const Video = () => {
  let { videoId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState('');
  const [duration, setDuration] = useState('');
  const [description, setInfo] = useState('');
  const [file, setFile] = useState('');
  const [language, setLanguage] = useState('_english_');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const video = {
    name,
    duration: timeToSeconds(duration),
    file,
    language,
    description: description.trim(),
  };

  const changeFile = useCallback(
    (files) => {
      const formData = new FormData();
      formData.append('files[0]', files[0], files[0].name);
      Notifier.loading(true);
      uploadFile(videoId, formData, (data) => {
        if (security.isAuthorized() && security.getUser().role == ROLES.admin.role) {
          setFile(data.file);
          Notifier.success(t('_video_successfully_updated_'));
          Notifier.loading(false);
        }
      });
    },
    [t, videoId],
  );
  const isVideoFormValid = validator.isVideoFormValid(video) && file.length > 0;

  const handleCreate = useCallback(
    (event) => {
      event.preventDefault();
      if (isVideoFormValid) {
        dispatch(
          !videoId
            ? create(video, () => goBack(history))
            : updateData(videoId, video, () => goBack(history)),
        );
      }
    },
    [isVideoFormValid, dispatch, videoId, video, history],
  );
  const handleDeleteVideo = useCallback(() => {
    dispatch(remove(videoId, () => goBack(history)));
  }, [dispatch, history, videoId]);
  useEffect(() => {
    if (videoId) {
      dispatch(
        getVideo(videoId, (video) => {
          setName(video.name);
          setDuration(toTime(video.duration));
          setInfo(video.description);
          setFile(video.file);
          setLanguage(video.language);
        }),
      );
    }
    return () => Notifier.loading(false);
  }, [dispatch, videoId]);
  const navBarText = !videoId ? '_create_update_video_' : '_edit_delete_video_';
  return (
    <Container>
      {useNavbar(navBarText)}
      <Form method="POST" className="mt-5" onSubmit={handleCreate}>
        <Form.Group as={Row} controlId="formName">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_name_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              placeholder={t('_enter_video_name_')}
              name="name"
              value={name}
              {...(name.length ? validator.validate('name', name) : {})}
              onChange={(e) => setName(e.target.value.trimLeft())}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t('_video_name_is_short_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formDuration">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_duration_minutes_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              className="form-control w-100"
              mask="99:99:99"
              name="duration"
              placeholder={t('_enter_video_duration_')}
              value={duration}
              {...(duration.length ? validator.validate('duration', video.duration) : {})}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t('_video_duration_is_incorrect_')}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formInfo" className="my-2">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_info_')}</strong>
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              value={description}
              as="textarea"
              rows="2"
              placeholder={t('_enter_video_info_')}
              type="text"
              name="info"
              onChange={(e) => setInfo(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formFile" className="mt-5">
          <Form.Label column xs={{ span: 2, offset: 2 }}>
            <strong>{t('_video_file_')}</strong>
          </Form.Label>
          <Col xs={2}>
            <UploadBtn t={t} accept={['ogv', 'ogg']} onDrop={changeFile} />
          </Col>
        </Form.Group>
        <fieldset>
          <Form.Group as={Row}>
            <Form.Label as="legend" column xs={{ span: 2, offset: 2 }}>
              <strong>{t('_language_')}</strong>
            </Form.Label>
            <Col xs={{ span: 3, offset: 0 }}>
              {LANGUAGES.map((item) => (
                <Form.Check
                  type="radio"
                  label={t(item.label)}
                  value={item.label}
                  key={item.value}
                  name="language"
                  onChange={(e) => setLanguage(e.target.value)}
                  checked={language == item.label}
                  required
                />
              ))}
            </Col>
          </Form.Group>
        </fieldset>
        <Row>
          <Col>
            {videoId ? (
              <Button
                variant="danger"
                type="button"
                className="col-3"
                id="delete-button"
                onClick={handleShow}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
                {`  ${t('_delete_')}`}
              </Button>
            ) : null}
          </Col>
          <DeleteComponent
            handleClose={handleClose}
            show={show}
            handleDelete={handleDeleteVideo}
            title="_delete_user_message_"
            keepText="_keep_video_"
            deleteText="_delete_video_"
          />
          <Col className="text-right">
            <Button variant="success" type="submit" disabled={!isVideoFormValid}>
              <FontAwesomeIcon icon={faCheck} />
              {` ${t('_save_changes_')}`}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Video;
