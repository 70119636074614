import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes/Routes';
import initializeTranslation from 'config/translation';
import 'config/middlewares';
import ImagePreloader from 'utils/ImagePreLoader';

export const App = () => {
  initializeTranslation();

  return (
    <BrowserRouter>
      <div className="app">
        <Routes />
      </div>
      <ImagePreloader />
    </BrowserRouter>
  );
};

export default App;
