import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import TimeLineTable from 'components/TimeLine/TimeLineTable';
import TimeLineTools from 'components/TimeLine/Components/TimeLineTools';
import { TIME_LINE_TABS } from 'config/constants';
import { getActiveTeam } from 'utils/resultHelper';

const TimeLine = ({ timeLine, fullTeams, duration, formattedTimeLine }) => {
  const { t } = useTranslation();
  const [activeTeam, setActiveTeam] = useState(getActiveTeam(timeLine));
  const [activeTab, setActiveTab] = useState(TIME_LINE_TABS.system_status.key);
  return activeTeam ? (
    <Container className="d-print-none">
      <TimeLineTools
        activeTeam={activeTeam}
        changeTeam={setActiveTeam}
        activeTab={activeTab}
        changeTab={setActiveTab}
        timeLine={timeLine}
        fullTeams={fullTeams}
        t={t}
      />
      <TimeLineTable
        t={t}
        duration={duration}
        data={timeLine[activeTeam]}
        formattedData={formattedTimeLine[activeTeam]}
        isSystemActive={activeTab == TIME_LINE_TABS.system_status.key}
      />
    </Container>
  ) : null;
};

TimeLine.defaultProps = {
  timeLine: {},
  duration: 0,
  formattedTimeLine: {},
};

TimeLine.propTypes = {
  timeLine: PropTypes.shape({}),
  formattedTimeLine: PropTypes.shape({}),
  fullTeams: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  duration: PropTypes.number,
};
export default TimeLine;
