import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import security from 'common/security/security';
import axios from 'axios';
import translationResources from 'config/translationResources';

export const getCurrentLanguage = () => {
  let language = 'en';
  const user = security.getUser();
  if (user && user.language) {
    language = user.language.split('_')[0];
  }
  return language;
};

export const initDynamicResources = () => {
  const i18n = i18next.use(initReactI18next);

  axios.get('/assets/translations/admin_portal_en_US.json').then(({ data }) => {
    i18n.addResources('en', 'translation', data);
  });

  axios.get('/assets/translations/admin_portal_fr_FR.json').then(({ data }) => {
    i18n.addResources('fr', 'translation', data);
  });

  axios.get('/assets/translations/admin_portal_de_DE.json').then(({ data }) => {
    i18n.addResources('de', 'translation', data);
  });

  axios.get('/assets/translations/admin_portal_it_IT.json').then(({ data }) => {
    i18n.addResources('it', 'translation', data);
  });
};

const initialize = () => {
  const i18n = i18next.use(initReactI18next);

  const i18nInti = i18n.init({
    resources: translationResources,
    lng: getCurrentLanguage(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

  initDynamicResources();

  return i18nInti;
};

export default initialize;
