import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { sessionPT } from 'components/Mission/PropTypes';
import { Collapse, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faCheck, faTimes, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Disconnect from 'components/Mission/lib/Disconnect';
import { SESSION_KEY_TYPE, GAME_DECKS } from 'config/constants';
import { abortSession } from './missionActions';

export const teamStatusWasChanged = (current, previous) =>
  !current.find(
    ({ clientId, roles, clients, isGameIsStarted, isReady, gameState }) =>
      !previous.find(
        (team) =>
          team.clientId === clientId &&
          team.isGameIsStarted === isGameIsStarted &&
          team.isReady === isReady &&
          team.gameState === gameState &&
          team.roles[GAME_DECKS.MAIN.id] === roles[GAME_DECKS.MAIN.id] &&
          team.roles[GAME_DECKS.SUPPORT.id] === roles[GAME_DECKS.SUPPORT.id] &&
          team.roles[GAME_DECKS.COMMANDER.id] === roles[GAME_DECKS.COMMANDER.id] &&
          JSON.stringify(team.clients) === JSON.stringify(clients),
      ),
  );

export const ConnectionStatusPanel = ({ fullTeams, session, isOpen, onCloseEvent }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const teams = useSelector(
    ({ teams }) =>
      teams.map(({ clientId, isGameIsStarted, isReady, gameState, roles, clients }) => ({
        clientId,
        isGameIsStarted,
        isReady,
        gameState,
        roles,
        clients,
      })),
    teamStatusWasChanged,
  );
  const missionControl = fullTeams.find(
    (item) => item.roomType == SESSION_KEY_TYPE.MISSION_CONTROL,
  );
  const getTeamData = (clientId) => {
    const teamData = {
      opacity: 1,
      problem: false,
    };
    const team = teams.find((element) => element.clientId == clientId);
    /* istanbul ignore else */
    if (
      (!team.isReady &&
        !team.roles[0] &&
        !team.roles[1] &&
        !team.roles[2] &&
        team.clients &&
        !team.clients.length) ||
      !team.isGameIsStarted
    ) {
      teamData.opacity = 0;
    }
    teamData.problem = team.clients && !!team.clients.filter((client) => !client.connected).length;
    return teamData;
  };
  const getMissionControlData = () => {
    const teamData = {
      opacity: 0,
      problem: false,
    };

    if (missionControl) {
      const isGameNotFinished = teams.some((team) => team.gameState === 1 && team.isGameIsStarted);
      if (!!missionControl.clients.length && missionControl.gameState == 1 && isGameNotFinished) {
        teamData.opacity = 1;
      }
      teamData.problem =
        missionControl.clients &&
        !!missionControl.clients.filter((client) => !client.connected).length;
    }
    return teamData;
  };
  const disconnect = (roomId, canDisconnect) => {
    canDisconnect && abortSession(session._id, roomId, () => {});
  };
  return session ? (
    <>
      <Collapse in={isOpen} className="d-print-none">
        <div className="collapse-style">
          <div>
            <Row>
              <div className="col-4 connection-status-text">
                <strong>{t('_connection_status_')}</strong>
              </div>
              <div className="col-7 d-flex justify-content-between connection-status-container">
                {session.clientKeys
                  .filter((item) => item.keyType == SESSION_KEY_TYPE.MISSION_CONTROL)
                  .map((item) => {
                    const status = getMissionControlData();
                    return (
                      <div key={`disconnect-button-${item.clientId}`}>
                        <Button
                          variant={status.problem ? 'danger' : 'light'}
                          className="connection-status"
                          style={{ opacity: status.opacity }}
                          onClick={() => status.problem && setShow(item.clientId)}
                          disabled={status.opacity == 0}
                        >
                          <FontAwesomeIcon icon={status.problem ? faTimes : faCheck} />
                        </Button>
                        <Disconnect
                          show={show == item.clientId}
                          disconnectText="_abort_team_"
                          onHide={() => setShow(0)}
                          onDisconnectClick={() => {
                            setShow(0);
                            disconnect(item.key, status.problem);
                          }}
                          t={t}
                        />
                      </div>
                    );
                  })}
                {session.clientKeys
                  .filter((item) => item.keyType == SESSION_KEY_TYPE.ROOM)
                  .map((item) => {
                    const status = getTeamData(item.clientId);
                    return (
                      <div key={`disconnect-button-${item.clientId}`}>
                        <Button
                          variant={status.problem ? 'danger' : 'light'}
                          className="connection-status"
                          style={{ opacity: status.opacity }}
                          onClick={() => status.problem && setShow(item.clientId)}
                          disabled={status.opacity == 0}
                        >
                          <FontAwesomeIcon icon={status.problem ? faTimes : faCheck} />
                        </Button>
                        <Disconnect
                          show={show == item.clientId}
                          disconnectText="_abort_team_"
                          onHide={() => setShow(0)}
                          onDisconnectClick={() => {
                            setShow(0);
                            disconnect(item.key, status.problem);
                          }}
                          t={t}
                        />
                      </div>
                    );
                  })}
              </div>
            </Row>
            <div>
              <Row className="line">
                <Col xs={{ span: 12 }} md={4}>
                  <Row>
                    <Col md={5} sm={5} xs={5}>
                      <strong>{t('_session_keys_')}</strong>
                    </Col>
                    <Col md={4} sm={4} xs={4} className="session-key-col">
                      <span>{t('_session_key_')}</span>
                    </Col>
                    <Col md={3} sm={3} xs={3}>
                      <strong>{session.key}</strong>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 9, offset: 3 }}
                  md={{ span: 8, offset: 0 }}
                  className="d-flex justify-content-between connection-status-container connection-status-container-keys"
                >
                  {session.clientKeys
                    .filter((item) => item.keyType == SESSION_KEY_TYPE.MISSION_CONTROL)
                    .map((item) => (
                      <div key={item.clientId} className="col key-container">
                        <div className="key-items">
                          <strong>{item.key}</strong>
                        </div>
                      </div>
                    ))}
                  {session.clientKeys
                    .filter((item) => item.keyType == SESSION_KEY_TYPE.ROOM)
                    .map((item) => (
                      <div key={item.clientId} className="col key-container">
                        <div className="key-items">
                          <strong>{item.key}</strong>
                        </div>
                      </div>
                    ))}
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <Row>
                    <Col xs={5} />
                    <Col xs={4} className="session-key-col">
                      <span>{t('_waiting_lounge_')}</span>
                    </Col>
                    <Col xs={3}>
                      {session.clientKeys
                        .filter((item) => item.keyType == SESSION_KEY_TYPE.WAITING_ROOM)
                        .map((item) => (
                          <strong key={item.key}>{item.key}</strong>
                        ))}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mr-1 mb-2 d-flex justify-content-between">
                <Col xs={9} />
                <Col xs={2} className="chevron-up-icon" onClick={onCloseEvent}>
                  <FontAwesomeIcon icon={faChevronUp} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Collapse>
    </>
  ) : null;
};

ConnectionStatusPanel.defaultProps = {
  isOpen: false,
  session: null,
};
ConnectionStatusPanel.propTypes = {
  session: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
    }),
    sessionPT,
  ]),
  isOpen: PropTypes.bool,
  onCloseEvent: PropTypes.func.isRequired,
};

export default ConnectionStatusPanel;
