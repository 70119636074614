import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import axios from 'axios';

class Downloader {
  options = {};
  base64Prefix = 'data:text/plain;base64,';
  encodeBase64 = (text) => `${this.base64Prefix}${encodeBase64(text)}`;

  download = (name, text, options) => {
    text = this.encodeBase64(text);
    saveAs(text, name, options || this.options);
  };

  downloadFromUrl = (url, name, method = 'GET') =>
    axios({
      url,
      method,
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

const downloader = new Downloader();

export default downloader;
