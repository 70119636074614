import React from 'react';
import ReactDOM from 'react-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reduxStore } from 'config/reduxStore';
import allReducers from 'config/allReducers';

import App from './App';
import './styles/less/all.less';

const enableLogger = process.env.NODE_ENV !== 'production' && process.env.IS_BROWSER;
const storeWithMiddleware = applyMiddleware(thunk);
reduxStore.store = createStore(
  allReducers,
  enableLogger ? composeWithDevTools(storeWithMiddleware) : storeWithMiddleware,
);

const rootElement = document.getElementById('root');

rootElement
  ? ReactDOM.render(
      <Provider store={reduxStore.store}>
        <App />
      </Provider>,
      rootElement,
    )
  : false;
