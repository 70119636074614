export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  SESSIONS: '/sessions',
  USERS: '/users',
  ACCOUNT: '/account',
  UNITS: '/units',
  RESOURCES: '/resource',
  ADMIN_MISSION: '/mission',
  LOG: '/log',
  CLIENT: '/client',
  VIDEO: '/video',
  SERVER: '/server',
  SCENARIO: '/scenario',
};
export const ROLES = {
  licensee: {
    role: 'licensee',
    home: '/sessions',
  },
  admin: {
    role: 'admin',
    home: '/users',
  },
};
export const ERRORS = {
  network_error: {
    title: '_server_disconnected_',
    message: '_refresh_page_',
    error: 'Network Error',
    transport: 'transport close',
    status504: 'Request failed with status code 504',
    status401: 401,
  },
  forced_close: {
    reason: ['forced close', 'io server disconnect'],
  },
};
export const API_URL = '/api/';
export const BASE_SOCKET_SERVER = '/';

export const LANGUAGES = [
  {
    value: 'en_US',
    label: '_english_',
  },
  {
    value: 'de_DE',
    label: '_german_',
  },
  {
    value: 'fr_FR',
    label: '_french_',
  },
  {
    value: 'it_IT',
    label: '_italian_',
  },
];

export const TEAMS = [
  {
    clientId: 1,
    initialized: false,
    isGameIsStarted: null,
    roles: {
      0: false,
      1: false,
      2: false,
    },
    tags: [],
    clients: [],
  },
  {
    clientId: 2,
    initialized: false,
    isGameIsStarted: null,
    roles: {
      0: false,
      1: false,
      2: false,
    },
    tags: [],
    clients: [],
  },
  {
    clientId: 3,
    initialized: false,
    isGameIsStarted: null,
    roles: {
      0: false,
      1: false,
      2: false,
    },
    tags: [],
    clients: [],
  },
  {
    clientId: 4,
    initialized: false,
    isGameIsStarted: null,
    roles: {
      0: false,
      1: false,
      2: false,
    },
    tags: [],
    clients: [],
  },
];

export const GAME_STEPS = [
  { id: 1, label: '_select_mission_' },
  { id: 2, label: '_confirm_options_' },
  { id: 3, label: '_observe_simulation_' },
  { id: 4, label: '_see_results_' },
];

export const UNIT_STEPS = [
  { id: 1, label: '_login_on_master_server_' },
  { id: 2, label: '_transfer_units_' },
  { id: 3, label: '_see_results_' },
];

export const GAME_DECKS = {
  MAIN: {
    name: '_main_',
    id: 0,
  },
  SUPPORT: {
    name: '_support_',
    id: 1,
  },
  COMMANDER: {
    name: '_commander_',
    id: 2,
  },
};

export const DECKS = {
  0: 'Main',
  1: 'Support',
  2: 'Commander',
};

export const SESSION_KEYS = {
  4: '_4_characters_for_local_server_',
  8: '_8_characters_for_online_server_',
};

export const STATUS = {
  OBSERVE: 1,
  COMPLETE: 2,
  ABORT: 3,
  NOT_STARTED: -1,
  GAME_SAVED: 5,
  isGameExist: (state) => [1, 2, 4, 5].includes(state),
};
export const INFO_MAX_LENGTH = 325;
export const MAX_SECONDS = 59940;
export const FILE_MAX_SIZE = 1073741824;
export const MESSAGE = {
  INFO: 'info',
  ERROR: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
};
export const SESSION = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  LICENSEE_DISCONNECT: 'licensee:disconnect',
  JOINED: 'licensee:updated',
  TOTAL: 'totals:updated',
  COMPLETE: 'mission:complete',
  TIME: 'remainingTime:updated',
  GAME_ERROR: 'game:error',
  GAME_COMPLETE: 'game:complete',
  GAME_SAVED: 'game:saved',
  GAME_START: 'game:start',
  GAME_ABORT: 'game:aborted',
  OBSERVE: 'observe:updated',
  SEND_MESSAGE: 'send:message',
  ADD_TAG: 'add:tag',
  TAG_ADDED: 'tag:added',
  TAG_REMOVED: 'tag:removed',
};

export const COLORS = {
  team_1: '#FB4A3F',
  team_2: '#FDFF33',
  team_3: '#8ACC3C',
  team_4: '#5762FF',
  team_mc: '#00FFFF',
};

export const NUMBER_EXCLUDES = [69, 187, 189, 107, 109];
export const GROUPS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const PING_INTERVAL = 300000;

export const SESSION_KEY_TYPE = {
  ROOM: 1,
  MISSION_CONTROL: 2,
  WAITING_ROOM: 3,
};

export const MESSAGE_STATE = {
  OFF: 0,
  TRAINER: 1,
  TRAINER_AND_GAME: 2,
};

export const START_NUMBERING = {
  STANDARD: 1,
  START_AT: 2,
};

export const SESSION_NUMBERING_TYPE = {
  STANDARD: 1,
  DYNAMIC: 2,
};

export const SESSION_TYPE = {
  FIXED: 0,
  DYNAMIC: 1,
};

export const MISSION_TYPES = {
  mission: [
    {
      id: 0,
      name: '_all_',
      type: '_all_',
    },
    {
      id: 1,
      name: '_missions_',
      type: '_mission_',
    },
    {
      id: 2,
      name: '_videos_',
      type: '_video_',
    },
  ],
  getFilterIdByType: (type) => MISSION_TYPES.mission.find((item) => item.type == type).id,
};

export const INITIAL_FILTER_ID = 0;
export const VIDEO_TAB_ID = 2;
export const BREAKPOINTS = { XS: 'xs', SM: 'sm', MD: 'md', LG: 'lg', XL: 'xl' };

export const TAGS = {
  TRIANGLE: 1,
  SQUARE: 2,
  CIRCLE: 3,
  DISABLE_TIME: 5000,
};
export const RESULT_SWITCH = {
  behaviour: {
    title: '_behaviour_',
    id: 1,
  },
  timeLine: {
    title: '_timeline_',
    id: 2,
  },
  trainer: {
    title: '_trainer_screen_',
    id: 3,
  },
  nexus: {
    title: '_discover_nexus_',
    id: 4,
  },
  getById(id) {
    let active = null;
    switch (id) {
      case this.behaviour.id:
        active = this.behaviour;
        break;
      case this.timeLine.id:
        active = this.timeLine;
        break;
      case this.trainer.id:
        active = this.trainer;
        break;
      case this.nexus.id:
        active = this.nexus;
        break;
    }
    return active;
  },
  next(id) {
    let next = this.behaviour;
    switch (id) {
      case this.nexus.id:
        next = this.behaviour;
        break;
      case this.timeLine.id:
        next = this.trainer;
        break;
      case this.trainer.id:
        next = this.nexus;
        break;
      case this.behaviour.id:
        next = this.timeLine;
        break;
    }
    return next;
  },
  prev(id) {
    let prev = this.behaviour;
    switch (id) {
      case this.nexus.id:
        prev = this.trainer;
        break;
      case this.trainer.id:
        prev = this.timeLine;
        break;
      case this.timeLine.id:
        prev = this.behaviour;
        break;
      case this.behaviour.id:
        prev = this.nexus;
        break;
    }
    return prev;
  },
};

export const TRAINER_STEP = 2;
export const TRAINER_STEP_COUNT = 10;
export const TRAINER_STEP_WIDTH = 5;

export const TIME_LINE_TABS = {
  system_status: {
    title: '_system_status_',
    key: 'system_status',
  },
  infos_jobs: {
    title: '_infos_jobs_',
    key: 'infos_jobs',
  },
};

export const MAX_VALUES = {
  SPEED: 2400,
  SOLAR_POSITION: 100,
  GENERATOR_ENERGY: 16000,
  SOLAR_ENERGY: 8000,
};

export const REPORT_TIMEOUT = 10000;

export const NEXUS_CLIENT_ID = 'nexus';

export const BASE_BUILDING_ID = 41;

export default {
  API_URL,
  ROUTES,
  ERRORS,
  ROLES,
  BASE_SOCKET_SERVER,
  LANGUAGES,
  TEAMS,
  GAME_STEPS,
  GAME_DECKS,
  STATUS,
  MAX_SECONDS,
  MESSAGE,
  SESSION,
  NUMBER_EXCLUDES,
  PING_INTERVAL,
  MESSAGE_STATE,
  MISSION_TYPES,
  INITIAL_FILTER_ID,
  VIDEO_TAB_ID,
  BREAKPOINTS,
  TAGS,
  RESULT_SWITCH,
  TRAINER_STEP,
  TIME_LINE_TABS,
  MAX_VALUES,
  REPORT_TIMEOUT,
  NEXUS_CLIENT_ID,
  BASE_BUILDING_ID,
};
