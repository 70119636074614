import en_US from 'resources/languages/en_US';
import de_DE from 'resources/languages/de_DE';
import fr_FR from 'resources/languages/fr_FR';
import it_IT from 'resources/languages/it_IT';

export default {
  en: {
    translation: en_US,
    label: '_english_',
  },
  de: {
    translation: de_DE,
    label: '_german_',
  },
  fr: {
    translation: fr_FR,
    label: '_french_',
  },
  it: {
    translation: it_IT,
    label: '_italian_',
  },
};
