/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Button, Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faPlayCircle, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import SortableTable from 'components/SortableTable/SortableTable';
import { list } from 'components/Session/sessionActions';
import { getUnitsAvailable } from 'components/Unit/unitActions';
import { useNavbar } from 'common/components';
import sessionManager from 'common/session/session.manager';
import LogoutModal from 'components/Logout/Logout';
import { userPT } from 'components/User/PropTypes';
import { SESSION_KEY_TYPE } from 'config/constants';

export const renderPlay = (disabled) => (
  <Button variant="outline-secondary" size="sm" className="play-session" disabled={disabled}>
    <FontAwesomeIcon icon={faPlayCircle} />
  </Button>
);

export const correctRows = (t, languages) => [
  {
    Header: '#',
    sortable: false,
    center: true,
    size: 0,
    accessor: (_, index) => index + 1,
  },
  {
    Header: t('_session_'),
    accessor: (session) => session.name,
    size: 5,
    sortable: true,
  },
  {
    Header: t('_language_'),
    accessor: (session) => {
      const currentLanguage = languages.find((language) => language.key == session.language);
      return currentLanguage && t(currentLanguage.name);
    },
    size: 1,
    sortable: true,
  },
  {
    Header: t('_last_time_used_'),
    sortable: true,
    size: 2,
    accessor: (session) => moment(session.dateUpdated).format('YYYY-MM-DD HH:mm'),
  },
  {
    Header: t('_open_'),
    sortable: false,
    center: true,
    size: 1,
    accessor: (session) => (
      <div className="circle-point-container">
        {session.open &&
          session.clientKeys.map(
            (key) =>
              key.isOpen &&
              key.keyType == SESSION_KEY_TYPE.ROOM && (
                <p key={key._id} className="circle-point" title={t('_open_')} />
              ),
          )}
      </div>
    ),
  },
  {
    Header: t('_edit_'),
    sortable: false,
    center: true,
    size: 1,
    accessor: (session) => (
      <Link to={`/sessions/edit/${session._id}`}>
        <Button variant="outline-secondary" size="sm" className="edit-session">
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Link>
    ),
  },
  {
    Header: t('_play_'),
    sortable: false,
    center: true,
    size: 1,
    accessor: (session) => {
      const enabled =
        session.open &&
        session.clientKeys.filter((key) => key.isOpen && key.keyType == SESSION_KEY_TYPE.ROOM)
          .length > 0;
      return enabled ? (
        <Link to={`/sessions/${session._id}/missions`}>{renderPlay(false)}</Link>
      ) : (
        renderPlay(true)
      );
    },
  },
];
export const List = ({ sessions, settings, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUnitAvailable, setIsUnitAvailable] = useState(false);
  const [isLogoutModalOpen, openLogoutModal] = useState(false);
  useEffect(() => {
    sessionManager.deleteAllSessions();
    getUnitsAvailable((isUnitAvailable) => {
      setIsUnitAvailable(isUnitAvailable);
      dispatch(list());
    });
  }, [dispatch]);
  return (
    <Container>
      <LogoutModal
        show={isLogoutModalOpen}
        showPowerOff
        handleClose={() => openLogoutModal(false)}
      />
      {useNavbar('_play_edit_create_session_', true)}
      <div className="session-page mt-3 mb-3">
        <Nav id="session-table" className="navbar navbar-light bg-light">
          <Nav.Item className="navbar-brand">{t('_your_simulated_sessions_')}</Nav.Item>
          <Nav.Item className="nav nav-pills" as="ul">
            <Nav.Item as="li">
              <Link to="/sessions/new">
                <Button variant="success">
                  <FontAwesomeIcon icon={faPlus} />
                  {` ${t('_create_new_')}`}
                </Button>
              </Link>
            </Nav.Item>
          </Nav.Item>
        </Nav>
        <div data-spy="scroll" data-target="#session-table" data-offset="0">
          <SortableTable
            columns={correctRows(t, settings.languages)}
            data={sessions}
            additionalClass="session-page-table"
          />
        </div>
      </div>
      <div className="session-page-footer">
        <Row>
          <Col className="d-flex justify-content-end footer-list-items" xs={12}>
            <Button
              className="logout-button-link"
              variant="danger"
              onClick={() => openLogoutModal(true)}
            >
              <FontAwesomeIcon icon={faPowerOff} />
              <span>{t('_sign_out_')}</span>
            </Button>
            <Link to="/account">
              <Button variant="outline-secondary">{`${t('_change_settings_')}`}</Button>
            </Link>
            {isUnitAvailable ? (
              <Link to="/units">
                <Button variant="outline-secondary" className="session-page-footer-units">
                  {`${t('_units_')}`} <b>{user.units}</b>
                </Button>
              </Link>
            ) : (
              <Button variant="outline-secondary" disabled className="session-page-footer-units">
                {`${t('_units_')}`} <b>{user.units}</b>
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

List.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      open: PropTypes.bool.isRequired,
      dateUpdated: PropTypes.string.isRequired,
    }),
  ).isRequired,
  settings: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  user: userPT.isRequired,
};

const mapStateToProps = (state) => ({
  sessions: state.sessions,
  settings: state.settings,
  user: state.user,
});

export default connect(mapStateToProps, null)(List);
