import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { infoPT } from 'components/Mission/lib/JobInfo/PropTypes';

const JobInfo = ({ data: { isSelectedFirst, isSelected, isRead, message } }) => {
  const isSolved = message && typeof message.isSolved === 'boolean';
  const className = useMemo(() => {
    let className = '';
    if (message) {
      if (isSelectedFirst && !isSelected) {
        className += ' yellow';
      }

      if (isRead) {
        className += ' is-read';
      }

      if (message.isSolved === false) {
        className += ' wrong';
      } else if (message.isSolved === true) {
        className += ' correct';
      }
    } else {
      className += ' empty';
    }
    return className;
  }, [isRead, isSelected, isSelectedFirst, message]);

  return (
    <div
      className={`item info-jobs-item d-flex align-items-center justify-content-center ${className}`}
    >
      {isSolved && !message.isSolved && <FontAwesomeIcon icon={faTimes} />}
      {isSolved && message.isSolved && <FontAwesomeIcon icon={faCheck} />}
    </div>
  );
};

JobInfo.propTypes = {
  data: infoPT.isRequired,
};

export default JobInfo;
