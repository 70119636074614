import React from 'react';
import PropTypes from 'prop-types';
import Events from 'components/TimeLine/Events';
import TimeLineTableSystem from 'components/TimeLine/TimeLineTableSystem';
import TimeLineTableInfo from 'components/TimeLine/TimeLineTableInfo';

const TimeLineTable = ({ t, data, isSystemActive, duration, formattedData }) => {
  const step = (duration || formattedData.length - 1) / 600;
  return (
    <div className="scores-page row mb-2">
      <div className="time-line-view">
        <div className="time-line-view-header">
          <div>
            <span>{t('_mission_time_')}</span>
            <span>0</span>
          </div>
          {Array(10)
            .fill(1)
            .map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`time-line-view-header-${index}`}>
                {Math.round((item + index) * step * 100) / 100}
              </div>
            ))}
        </div>
        <Events t={t} data={data} formattedData={formattedData} />
        {isSystemActive && <TimeLineTableSystem t={t} data={data} formattedData={formattedData} />}
        {!isSystemActive && <TimeLineTableInfo t={t} data={data} formattedData={formattedData} />}
      </div>
    </div>
  );
};

TimeLineTable.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  formattedData: PropTypes.shape({
    length: PropTypes.number.isRequired,
  }).isRequired,
  isSystemActive: PropTypes.bool.isRequired,
  duration: PropTypes.number.isRequired,
};
export default TimeLineTable;
