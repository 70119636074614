import {
  INIT_SESSION_LIST,
  ADD_SESSION,
  UPDATE_SESSION,
  DELETE_SESSION,
} from './sessionActionTypes';

export const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case INIT_SESSION_LIST:
      return action.data;
    case ADD_SESSION:
      return [...state, action.session];
    case UPDATE_SESSION:
      return state.map((session) => {
        if (session._id == action.session._id) {
          session = { ...session, ...action.session };
        }
        return session;
      });
    case DELETE_SESSION:
      return state.filter((session) => session._id !== action.sessionId);
    default:
      return state;
  }
}
