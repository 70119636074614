import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Figure, Row } from 'react-bootstrap';

const Achievements = ({ t }) => {
  const achievements = useSelector(({ nexus }) => nexus.achievements);
  const data = useMemo(() => achievements, [achievements]);
  const totalPrimary = Math.min(data.accomodations, data.food);
  const totalSecondary = Number(data.ciranaguyit) + Number(data.kapuyt) + Number(data.karmirium);
  return (
    <Row className="observe-simulation-nexus-achievements">
      <Col>
        <Figure>
          <div>
            <strong>{t('_achievements_')}</strong>
          </div>
          <div>
            <div className="observe-simulation-nexus-achievements-board">
              <div className="observe-simulation-nexus-achievements-board-selected">
                <div className="observe-simulation-nexus-achievements-row title bold">
                  <div>{t('_total_primary_')}</div>
                  <div>{totalPrimary}</div>
                </div>
                <div className="observe-simulation-nexus-achievements-row">
                  <div>{t('_accomodations_')}</div>
                  <div>{data.accomodations}</div>
                </div>
                <div className="observe-simulation-nexus-achievements-row">
                  <div>{t('_food_')}</div>
                  <div>{data.food}</div>
                </div>
              </div>
              <div className="observe-simulation-nexus-achievements-row bold">
                <div>{t('_total_secondary_')}</div>
                <div>{totalSecondary}</div>
              </div>
              <div className="observe-simulation-nexus-achievements-row">
                <div className="karmirium">{t('_karmirium_')}</div>
                <div>{data.karmirium}</div>
              </div>
              <div className="observe-simulation-nexus-achievements-row">
                <div className="kapuyt">{t('_kapuyt_')}</div>
                <div>{data.kapuyt}</div>
              </div>
              <div className="observe-simulation-nexus-achievements-row">
                <div className="ciranaguyit">{t('_ciranaguyit_')}</div>
                <div>{data.ciranaguyit}</div>
              </div>
            </div>
          </div>
        </Figure>
      </Col>
    </Row>
  );
};

Achievements.propTypes = {
  t: PropTypes.func.isRequired,
};
export default Achievements;
