import React from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavbar } from 'common/components';
import { UNIT_STEPS } from 'config/constants';
import CustomBreadCrumb from 'common/CustomBreadCrumb/CustomBreadCrumb';

const UnitContent = ({ t, currentStep, children }) => (
  <Container className="unit-page">
    {useNavbar('_manage_your_units_')}
    <div className="box-bar" />
    <CustomBreadCrumb t={t} steps={UNIT_STEPS} currentStep={currentStep} />
    {children}
  </Container>
);

UnitContent.propTypes = {
  currentStep: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default UnitContent;
